import { AxiosError } from 'axios';

import { HttpRequestDelete, HttpRequestGet, HttpRequestPost, HttpRequestPut } from './genericService';

interface NewUserRequestModel {
  Email: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  Role: UserRole;
  MerchantId: GUID;
}

export function InviteUserRequest(token: string, body: NewUserRequestModel, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<unknown, NewUserRequestModel>(
    '/user/invite',
    body,
    () => success(),
    (error) => failure(error),
    token
  );
}

export function CreateUserRequest(token: string, body: NewUserRequestModel, success: (response: string) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, NewUserRequestModel>(
    '/user',
    body,
    (response) => success(response.data.Result),
    (error) => failure(error),
    token
  );
}

type UserTokenResponse = string;

interface LoginRequestModel {
  Email: string;
  Password: string;
}

export function SignInUserRequest(body: LoginRequestModel, success: (token: ApiResponse<UserTokenResponse>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<UserTokenResponse, LoginRequestModel>(
    '/login',
    body,
    (response) => success(response.data),
    (error: AxiosError) => failure(error),
    undefined
  );
}

type UserInfoResponseModel = UserProperties;

export function GetUserInfoRequest(token: string, success: (responseData: ApiResponse<UserInfoResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, noSuperData?: boolean): void {
  HttpRequestGet<UserInfoResponseModel>(
    '/profile',
    (response) => success(response.data),
    (error) => failure(error),
    token,
    undefined,
    noSuperData
  );
}

interface AuthUserTokenRequestModel {
  Email: string;
}

export function AuthUserTokenRequest(body: AuthUserTokenRequestModel, success: (token: ApiResponse<UserTokenResponse>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<UserTokenResponse, AuthUserTokenRequestModel>(
    '/auth-token',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    undefined
  );
}

interface SetTutorialOptionRequestModel {
  DontShowTutorial: boolean;
}

export function SetTutorialOptionRequest(token: string, body: SetTutorialOptionRequestModel, success: (token: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, SetTutorialOptionRequestModel>(
    '/set-tutorial-option',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function ValidateTokenRequest(token: string, success: (responseData: ApiResponse<boolean>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<boolean>(
    `/validate-token/${token}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export type PasswordResetResponseModel = {
  Id: GUID;
  IsSetUp: boolean;
};

type PasswordResetRequestModel = {
  Token: string;
  Password: string;
  Marketing?: boolean;
};

export function PasswordResetUserTokenRequest(body: PasswordResetRequestModel, success: (response: ApiResponse<PasswordResetResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<PasswordResetResponseModel, PasswordResetRequestModel>(
    '/reset-password',
    body,
    (response) => success(response.data),
    (error: AxiosError) => failure(error),
    undefined
  );
}

type PasswordResetMailRequestModel = {
  Email: string;
};

export function PasswordResetUserMailRequest(body: PasswordResetMailRequestModel, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<unknown, PasswordResetMailRequestModel>(
    '/reset-password-mail',
    body,
    () => success(),
    (error: AxiosError) => failure(error),
    undefined
  );
}

type PasswordChangeRequestModel = {
  Password: string;
};

export function PasswordChangeRequest(token: string, body: PasswordChangeRequestModel, userId: string, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<unknown, PasswordChangeRequestModel>(
    `/reset-password/${userId}`,
    body,
    () => success(),
    (error: AxiosError) => failure(error),
    token
  );
}

type UserEditRequestModel = {
  Id: GUID;
  FirstName: string;
  LastName: string;
  Phone: string;
  Role: string;
  Marketing: boolean;
};

export function UserEditRequest(token: string, body: UserEditRequestModel, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPut<unknown, UserEditRequestModel>(
    '/user/edit',
    body,
    () => success(),
    (error: AxiosError) => failure(error),
    token
  );
}

export function DeleteUserRequest(userGuid: GUID, token: string, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete<string>(
    `/user/${userGuid}`,
    () => success(),
    (error) => failure(error),
    token
  );
}

