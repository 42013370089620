/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
import { Box, IconButton, Stack, useMediaQuery } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ButtonContainedPrimary } from '../../components-atoms/ButtonComponents';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AssetDialogImage, getFileNameFromUrl } from '../../constants/digitalAssets';
import { BodyOnePrimary, BodyTwoPrimary } from '../../components-atoms/TypographyComponents';
import { LIGHT_THEME } from '../../constants/theme';
import { useKeyPress } from '../../hooks/useKeyPress';
import { PdfViewer } from '../../components-organisms/PdfViewer';
import { VideoComponent } from '../VideoComponent';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DownloadIcon from '@mui/icons-material/Download';
import otherIcon from '../../assets/icons/Otherfile.svg';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { SingleFileDownloadLogRequest } from '../../service/assetService';
import { LogAction, LogActionType } from '../../service/digitalToolkitService';

const PRIMARY_COLOR_V2 = '#384f9a';


export enum FullScreenDialogServiceType {
  DigitalAsset = 'DigitalAsset',
  IndustryInsight = 'IndustryInsight',
  DigitalToolkit = 'DigitalToolkit',
  AdminHelp = 'AdminHelp'
}

interface FullScreenAssetDialogProps {
  open: boolean;
  index: number;
  assets: any[];
  onClose: () => void;
  noDownloadButton?: boolean;
  serviceType?: FullScreenDialogServiceType;
}

export function FullScreenAssetDialog(props: FullScreenAssetDialogProps): JSX.Element {
  const { open, assets, noDownloadButton } = props;
  console.log('noDownloadButton', noDownloadButton);
  const [index, setIndex] = useState(props.index > -1 ? props.index : 0);
  const smDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const xsDown = useMediaQuery('(max-width:480px)');

  const currentAsset = useMemo(() => assets[index], [props.index, index]);
  const imgRef: any = useRef();

  const isFirst = index === 0;
  const isLast = index === assets.length - 1;

  // Key handlers
  const escPress = useKeyPress('Escape');
  const leftPress = useKeyPress('ArrowLeft');
  const rightPress = useKeyPress('ArrowRight');

  const [token] = useTypedSelector((state) => [
    state.userReducer.token
  ]);

  // Sync props index with local
  useEffect(() => {
    if (props.index !== index && props.index > -1) {
      setIndex(props.index);
    }
  }, [props.index]);


  useEffect(() => {
    if (leftPress && !isFirst) {
      onLeft();
    }
  }, [leftPress]);

  useEffect(() => {
    if (rightPress && !isLast) {
      onRight();
    }
  }, [rightPress]);

  useEffect(() => {
    if (escPress) {
      props.onClose();
    }
  }, [escPress]);

  useEffect(() => {
    setTimeout(() => {
      if (imgRef && imgRef.current) {
        imgRef.current.style.transition = 'initial';
        imgRef.current.style.marginLeft = 0;
        imgRef.current.style.opacity = 1;
      }
    }, 300);
  }, [index]);

  const onLeft = () => {
    if (imgRef && imgRef.current) {
      imgRef.current.style.transition = 'all 0.4s';
      imgRef.current.style.marginLeft = '100vw';
      imgRef.current.style.opacity = 0;
    }
    setTimeout(() => {
      setIndex(prev => prev - 1);
    }, 100);
  };

  const onRight = () => {
    if (imgRef && imgRef.current) {
      imgRef.current.style.transition = 'all 0.4s';
      imgRef.current.style.marginLeft = '-100vw';
      imgRef.current.style.opacity = 0;
    }
    setTimeout(() => {
      setIndex(prev => prev + 1);
    }, 100);
  };

  const onDownload = () => {
    if (currentAsset && currentAsset.FileUrl) {
      if (token) {
        switch (props.serviceType) {
          case FullScreenDialogServiceType.DigitalAsset:
            SingleFileDownloadLogRequest(token, { id: currentAsset.Id });
            break;
          case FullScreenDialogServiceType.DigitalToolkit:
            LogAction(token, currentAsset.Id, LogActionType.download, currentAsset.FileUrl);
            break;
        }
      }

      const a = document.createElement('a');
      a.href = currentAsset.FileUrl;
      a.download = currentAsset.FileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const onClose = () => {
    props.onClose();
  };

  function leftButton() {
    return (
      <Stack pl={{ xs: 0, sm: 4 }} pr={{ xs: 0, sm: 2 }}>
        <IconButton
          disabled={isFirst}
          onClick={onLeft}
          sx={{
            width: 48,
            height: 48,
            opacity: isFirst ? 0.4 : 1,
          }}>
          <ChevronLeftIcon sx={{ color: PRIMARY_COLOR_V2 }} />
        </IconButton>
      </Stack>
    );
  }

  function rightButton() {
    return (
      <Stack pr={{ xs: 0, sm: 4 }} pl={{ xs: 0, sm: 2 }}>
        <IconButton
          disabled={isLast}
          onClick={onRight}
          sx={{
            width: 48,
            height: 48,
            opacity: isLast ? 0.4 : 1,
          }}>
          <ChevronRightIcon sx={{ color: PRIMARY_COLOR_V2 }} />
        </IconButton>
      </Stack>
    );
  }

  function closeButton() {
    return (
      <IconButton
        onClick={onClose}
        sx={{
          padding: '12px',
        }}>
        <CloseIcon sx={{ width: '24px', height: '24px', color: PRIMARY_COLOR_V2 }} />
      </IconButton>
    );
  }

  function renderHeader() {
    return (
      <Box
        position="absolute"
        width="100%"
        height="40px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        top={8}
        left={0}
        pl={smDown ? 0 : 6}
        pr={smDown ? 0 : 4}
      >
        <Box bgcolor="white" ml={smDown ? 0 : 5} px={1.25} py={0.5}>
          <BodyTwoPrimary sx={{ maxWidth: smDown ? '235px' : '70vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {currentAsset?.FileName ? currentAsset?.FileName : getFileNameFromUrl(currentAsset?.FileUrl || currentAsset?.PreviewUrl || '')}
          </BodyTwoPrimary>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}>
          {noDownloadButton
            ? null
            : xsDown
              ? <Box
                sx={{
                  padding: '4px',
                  background: 'white',
                }}>
                <IconButton sx={{ width: '32px', height: '32px' }} onClick={onDownload}>
                  <DownloadIcon sx={{ color: PRIMARY_COLOR_V2 }} />
                </IconButton>
              </Box>
              : <ButtonContainedPrimary
                onClick={onDownload}
                startIcon={<DownloadIcon />}
                sx={{ width: '151px', height: '42px', mr: 1 }}>
                Download
              </ButtonContainedPrimary>
          }

          {!smDown
            && closeButton()
          }
        </Box>
      </Box>
    );
  }

  function renderContent() {
    if (smDown) {
      return (
        <Stack flex={1}>
          {renderPreview()}
        </Stack>
      );
    }

    return (
      <>
        {leftButton()}
        <Stack flex={1}>
          {renderPreview()}
        </Stack>
        {rightButton()}
      </>
    );
  }

  // TODO: Implement img error state
  function renderPreview() {
    const isImg = currentAsset?.FileType === 'Image';
    const isVideo = currentAsset?.FileType === 'Video';
    const isPdf = currentAsset?.FileType === 'Other' && currentAsset?.Extension === 'pdf' || currentAsset?.FileUrl?.endsWith('.pdf');
    const isPresentation = currentAsset?.FileType === 'Presentation' || currentAsset?.FileUrl?.endsWith('pptx');
    const isOther = currentAsset?.FileType === 'Other' && currentAsset?.Extension !== 'pdf';
    const pdfUrl = currentAsset?.PreviewUrl || currentAsset?.FileUrl;

    console.debug({
      isImg
      , isVideo
      , isPdf
      , isPresentation
      , isOther
      , pdfUrl

    });

    return (
      <Box
        display="flex"
        flex={1}
        justifyContent={'center'}
        alignItems={isOther ? 'center' : 'initial'}
        flexDirection="column"
        minHeight={smDown ? '90vh' : '100vh'}
        minWidth={smDown ? '100vw' : 'initial'}
        bgcolor="rgba(0, 0, 0, 0.04)">
        {renderHeader()}
        {isImg && <AssetDialogImage
          ref={imgRef}
          src={currentAsset?.ThumbnailUrl}
          height="100%"
          width="100%"
          draggable="false"
        />
        }

        {isVideo
          ? <VideoComponent
            controlsEnable
            autoplay
            noDownload={noDownloadButton}
            thumbnailUrl={currentAsset.ThumbnailUrl}
            previewUrl={currentAsset.PreviewUrl}
          />
          : <></>
        }

        {isPdf || isPresentation
          ? <Box sx={{ height: '100vh', width: smDown ? '100vw' : 'initial' }}>
            <PdfViewer url={pdfUrl + '#toolbar=0'} />
          </Box>
          : <></>
        }

        {isOther
          && <img src={otherIcon} width="180px" height="180px" />
        }
      </Box>
    );
  }

  function mobileNavbar() {
    if (!smDown) { return <></>; }
    return (
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        position="fixed"
        bottom={0}
        width="100%"
        height="64px"
        px="20px"
        bgcolor="white">
        <Box flex={1}>
          <BodyOnePrimary>
            {index + 1} of {assets.length}
          </BodyOnePrimary>
        </Box>
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={1}>
          {leftButton()}
          {rightButton()}
          {closeButton()}
        </Stack>
      </Stack>
    );
  }

  return (
    <Modal open={open} onClose={close} disableAutoFocus={true}>
      <Stack
        display="flex"
        width="100vw"
        height="100vh"
        flexDirection={smDown ? 'column' : 'row'}
        alignItems="center"
        justifyContent="space-between"
        bgcolor="white"
        position="relative"
        sx={{ userSelect: 'none', outline: 'none' }}
      >
        {renderContent()}
        {mobileNavbar()}
      </Stack>
    </Modal>
  );
}
