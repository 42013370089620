import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { EmployeeFormTypes, EMPLOYEE_FORM } from '../../forms/employee';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';

import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormControlInput, FormControlSelect, FormControlCheckbox } from '../../components-molecules/FormControl';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { SectionRow } from '../../components-molecules/section/SectionRow';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { ListDialog } from '../../components-molecules/dialogs/MiniDialog';
import { ButtonContainedPrimary } from '../../components-atoms/ButtonComponents';
import { CreateUserRequest } from '../../service/userService';
import { AssignToStoreRequest } from '../../service/storeService';
import { Alert } from '@mui/material';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useLocation, useNavigate } from 'react-router-dom';

export function AddEmployee(): JSX.Element {
  const { control, formState: { isValid }, reset, handleSubmit } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const dispatch = useTypedDispatch();
  const [error, setError] = useState('');
  const token = useTypedSelector((state) => state.userReducer.token);
  const merchant: Merchant = useTypedSelector(state => state.merchantReducer.currentData);
  const storesList = merchant.Stores;
  const [dialog, setDialog] = useState(false);
  const [stores, setStores] = useState<Store[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    reset();
    setStores([]);
  }, [reset]);

  const submit: SubmitHandler<EmployeeFormTypes> = (data): void => {
    setError('');
    if (token) {
      setLoading(true);
      CreateUserRequest(
        token,
        {
          FirstName: data.firstName,
          LastName: data.lastName,
          Email: data.email,
          Phone: data.phone || '',
          Role: data.role,
          MerchantId: merchant.Id
        },
        (response) => {
          if (stores.length > 0) {
            stores.map(store => {
              AssignToStoreRequest(
                token,
                {
                  UserId: response as GUID,
                  StoreId: store.Id
                },
                () => setLoading(false),
                (error) => {
                  setError(error.response?.data.Error || '');
                  setLoading(false);
                }
              );
            });
          } else {
            setLoading(false);
          }
          setStores([]);
          dispatch({ type: 'MERCHANT_REFRESH' });
          navigate(state.navigateBackTo, { state: { latestTab: 'employees' } });
        },
        (error) => {
          setLoading(false);
          setError(error.response?.data.Error || '');
        }
      );
    }
  };

  const addStore = (list: Set<string>): void => {
    const array = storesList.filter(v => list.has(v.Id));
    if (array.length > 0) setStores([...array]);
    setDialog(false);
  };

  return (
    <FormPageComponent
      hasError={!!error}
      title="Add New Employee"
      buttonTitle="Add Employee"
      buttonDisabled={!isValid || loading}
      close={() => navigate(state.navigateBackTo, { state: { latestTab: 'employees' } })}
      submit={handleSubmit(submit)}
      buttonLoading={loading}
    >
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!error
            && <Alert severity="error">{error}</Alert>
          }
        </Grid>
        {EMPLOYEE_FORM.map((section, index) => {
          return (
            <ModalCardComponent key={index}>
              <SectionHeader title={section.title} />

              <Fragment>
                {section.fields.map((field, index) =>
                  <Grid key={index} item sx={{ flexGrow: 1 }}>
                    {field.inputType === 'select'
                      ? <FormControlSelect control={control} field={field} />
                      : field.inputType === 'checkbox'
                        ? <FormControlCheckbox control={control} field={field} />
                        : <FormControlInput control={control} field={field} />
                    }
                  </Grid>
                )}
              </Fragment>
            </ModalCardComponent>
          );
        })}
      </Fragment>

      <ModalCardComponent>
        <SectionHeader
          title="Stores"
          button={
            <ButtonContainedPrimary disabled={storesList.length === 0} startIcon={<AddIcon />} onClick={() => setDialog(true)}>Link Stores</ButtonContainedPrimary>
          }
        />

        {stores === undefined || stores.length === 0
          ? <Grid item sx={{ flexGrow: 1, margin: '16px 0' }}>
            <InfoComponent
              title="Adding Store"
              description={storesList.length > 0 ? 'Add the employee to stores they work at or manage. You can also add employees to the stores at a later point.' : 'You haven\'t added any stores to this merchant yet.'}
            />
          </Grid>
          : <Fragment>
            {stores.map((store, index) =>
              <Fragment key={index}>
                <SectionRow
                  title="Store"
                  lines={[
                    { data: store.Name, type: 'body1' },
                    { data: `${store.CustomerCode}`, type: 'body2', color: 'text.secondary' }
                  ]}
                />
                {stores.length - 1 > index ? <Box><Divider /></Box> : null}
              </Fragment>
            )}
          </Fragment>
        }
      </ModalCardComponent>

      <ListDialog
        items={storesList}
        title="Add Store"
        caption={{ singular: 'Store', plural: 'Stores' }}
        open={dialog}
        multiple
        close={() => setDialog(false)}
        submit={addStore}
      />
    </FormPageComponent>
  );
}
