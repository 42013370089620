import { Box, Button, Menu, CircularProgress, Divider } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Notification } from '../../service/notificationService';
import { BodyTwoPrimary, BodyTwoSecondary, CaptionSecondary, HeaderSix } from '../../components-atoms/TypographyComponents';
import { ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { RoundedImageComponent } from '../../components-atoms/ImageComponents';

interface NotificationsPopupProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  notifications: Notification[];
  unreadCount: number;
  onMarkAllAsRead: () => void;
  onNotificationClick: (notification: Notification) => void;
  loading: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
}

export function NotificationsPopup({
  anchorEl,
  open,
  onClose,
  notifications,
  unreadCount,
  onMarkAllAsRead,
  onNotificationClick,
  loading,
  hasMore,
  onLoadMore,
}: NotificationsPopupProps): JSX.Element {
  // Loading indicator component to reuse
  const loadingIndicator = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
      <CircularProgress size={24} />
    </Box>
  );

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          width: '400px',
          maxHeight: '600px',
          overflow: 'hidden'
        }
      }}
    >
      <Box sx={{ 
        p: 2, 
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <HeaderSix>Notifications</HeaderSix>
        {notifications.length > 0 && unreadCount > 0 && (
          <ButtonTextPrimary
            onClick={onMarkAllAsRead}
            sx={{
              fontSize: '14px',
              padding: '4px 8px',
              minWidth: 'auto',
              color: '#415AA9'
            }}
          >
            Mark all as read
          </ButtonTextPrimary>
        )}
      </Box>
      <Box 
        id="notificationsScrollContainer" 
        sx={{ 
          height: 'calc(600px - 64px)',
          overflow: 'auto',
          paddingTop: '8px',
          paddingBottom: '16px',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          }
        }}
      >
        {loading && notifications.length === 0 ? (
          loadingIndicator()
        ) : notifications.length === 0 ? (
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <BodyTwoSecondary>No notifications</BodyTwoSecondary>
          </Box>
        ) : (
          <InfiniteScroll
            dataLength={notifications.length}
            next={onLoadMore}
            hasMore={hasMore}
            loader={hasMore ? loadingIndicator() : null}
            scrollableTarget="notificationsScrollContainer"
            style={{ overflow: 'visible', paddingTop: '8px', paddingBottom: '16px' }}
          >
            <Box sx={{ paddingBottom: '8px' }}>
              {notifications.map((notification, index) => (
                <Box key={notification.Id} sx={{ marginBottom: '8px' }}>
                  <Button
                    onClick={() => onNotificationClick(notification)}
                    sx={{
                      width: '100%',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      p: 2,
                      backgroundColor: notification.Read ? 'transparent' : 'rgba(1, 33, 105, 0.04)',
                      '&:hover': {
                        backgroundColor: 'rgba(1, 33, 105, 0.08)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                      <Box sx={{ position: 'relative' }}>
                        <RoundedImageComponent
                          src={require(`../../assets/img/category/${notification.ServiceImageUrl}@3x.png`)}
                          sx={{
                            width: '48px',
                            height: '48px',
                            flexShrink: 0,
                            objectFit: 'cover'
                          }}
                        />
                      </Box>
                      <Box sx={{ flex: 1, minWidth: 0 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                          <BodyTwoPrimary sx={{ 
                            color: notification.Read ? 'text.primary' : '#415AA9',
                            fontWeight: notification.Read ? 400 : 500
                          }}>
                            {notification.ServiceName}
                          </BodyTwoPrimary>
                          <CaptionSecondary>
                            {new Date(notification.Created).toLocaleDateString('en-GB', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            })}
                          </CaptionSecondary>
                        </Box>
                        <BodyTwoPrimary sx={{ mb: 0.5, fontWeight: notification.Read ? 400 : 500 }}>
                          {notification.Title}
                        </BodyTwoPrimary>
                        <BodyTwoSecondary sx={{ 
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          mb: notification.ImageUrl ? 1 : 0
                        }}>
                          {notification.Body}
                        </BodyTwoSecondary>
                        {notification.ImageUrl && (
                          <RoundedImageComponent
                            src={notification.ImageUrl}
                            sx={{
                              width: '100%',
                              height: '160px',
                              objectFit: 'cover',
                              borderRadius: '4px'
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Button>
                  {index < notifications.length - 1 && (
                    <Box>
                      <Divider sx={{ margin: '8px 16px' }} />
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </InfiniteScroll>
        )}
      </Box>
    </Menu>
  );
} 
