import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgress, Divider, FilledInput, Grid, IconButton, TablePagination } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { BodyOneSecondary, BodyTwoSecondary, CaptionPrimary, CaptionSecondary, HeaderFourSecondary, HeaderSix, SubtitleOnePrimary, SubtitleTwoPrimary } from '../components-atoms/TypographyComponents';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { Dashboard } from '../components-organisms/Dashboard';
import { Footer } from '../components-organisms/Footer';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { AddRecentSearchRequest, GetKnowledgeBaseSearchRequest, SearchHomepageRequest } from '../service/serviceService';
import { LIGHT_THEME } from '../constants/theme';
import '../scss/components/_search-dropdown.scss';
import '../scss/components/_recent-items.scss';
import '../scss/components/_thumbnail.scss';
import { FormatDate } from '../utils/dateUtils';
import { SupportTooltip } from '../components-molecules/SupportTooltip';
import { gaService } from '../service/gaService';
import imageIcon from '../assets/icons/Placeholder.svg';

export function SearchResults(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const dispatch = useTypedDispatch();
  const token = useTypedSelector(state => state.userReducer.token);
  const searchQuery = useTypedSelector(state => state.searchReducer.currentData);
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState<number>(0);
  const lastPromise = useRef<Promise<any>>();
  const [summaryResult, setSummaryResult] = useState<IResult>({
    Articles: { List: [], Total: 0 },
    Colours: { List: [], Total: 0 },
    Products: { List: [], Total: 0 },
    services: [],
    serviceContents: []
  });
  const [query, setQuery] = useState<string>(searchQuery);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const timeoutDuration = 500;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setQuery(event.target.value);
    debounce((e) => dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: e.target.value } }), timeoutDuration)(event);
  };

  const onClear = () => {
    setQuery('');
    dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: '' } });
  };

  const addRecentSearch = useCallback(() => {
    if (token) {
      AddRecentSearchRequest(
        token,
        {
          Query: query
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [query, token]);

  const searchResults = useCallback((token: string): Promise<any> => {
    return new Promise(resolve => {
      if (searchQuery.length > 2) {
        if (state && state.from && state.from === 'home') {
          setLoading(true);
          SearchHomepageRequest(
            token,
            { Size: rowsPerPage, Page: page, Query: searchQuery },
            (response) => {
              if (response) {
                resolve(response);
              }
            },
            (error: any) => { if (error.response) { console.error(error.response.data.Error); setLoading(false); } }
          );
        } else {
          setLoading(true);
          GetKnowledgeBaseSearchRequest(
            token,
            { Size: rowsPerPage, Page: page, Query: searchQuery },
            (response) => {
              if (response) {
                resolve(response);
              }
            },
            (error: any) => { if (error.response) { console.error(error.response.data.Error); setLoading(false); } }
          );
        }
      }
    });
  }, [searchQuery, page, rowsPerPage, state]);

  useEffect(() => {
    gaService.pageView('Search Results');
  }, []);

  useEffect(() => {
    if (token && searchQuery) {
      const p = searchResults(token);
      lastPromise.current = p;
      p.then(response => {
        if (p === lastPromise.current) {
          if (state && state.from && state.from === 'home') {
            if (response.Result.KnowledgeBase) {
              const result = response.Result.KnowledgeBase;
              setSummaryResult({
                ...result,
                services: response.Result.Services,
                serviceContents: response.Result.ServiceContents
              });
              setTotalResults(result.Articles.Total + result.Colours.Total + result.Products.Total + response.Result.ServiceContents.length + response.Result.Services.length);
            } else {
              setSummaryResult({
                Articles: { List: [], Total: 0 },
                Colours: { List: [], Total: 0 },
                Products: { List: [], Total: 0 },
                services: response.Result.Services,
                serviceContents: response.Result.ServiceContents
              });
              setTotalResults(response.Result.ServiceContents.length + response.Result.Services.length);
            }
            setLoading(false);
          }
          else {
            const result = response.Result;
            setSummaryResult({
              ...result,
              services: [],
              serviceContents: []
            });
            setTotalResults(result.Articles.Total + result.Colours.Total + result.Products.Total);
            setLoading(false);
          }
        }
      });
    }
  }, [token, searchQuery, state, searchResults]);

  return (
    <Dashboard noDrawer noMargin noPadding fullWidth merchantPage>
      <Grid container direction="column" sx={{ width: '100%' }}>
        <Grid container direction="row" justifyContent="center">
          <Grid direction="column" container item alignItems="flex-start" justifyContent="flex-start" sx={{ maxWidth: '1408px', width: '100%', position: 'relative', padding: '0 24px' }}>
            <Grid sx={{ margin: '12px 0px 28px' }}>
              <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate('/')}>Home</CaptionPrimary>
              <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
              {state.from !== 'home' && (
                <>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate(`/category/${location.state.categoryId}`)}>Training</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate(`/service/${location.state.serviceId}`)}>Knowledge Base</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                </>
              )}
              <CaptionPrimary style={{ marginBottom: '4px' }}>Search Results</CaptionPrimary>
            </Grid>
            <Grid container item justifyContent="flex-start" sx={{ marginBottom: '40px' }}>
              <Grid item md={2} className="banner-image-wrapper">
                <img src={require('../assets/img/search/searchIcon@2x.png')} style={{ width: '72px', height: '72px' }} />
              </Grid>
              <Grid item md={10} sx={{ paddingRight: '16px', paddingBottom: '12px', paddingLeft: { md: '20px' }, width: '100%' }}>
                <HeaderFourSecondary>Search Results</HeaderFourSecondary>
                <Grid container>
                  <BodyTwoSecondary style={{ maxWidth: 783, paddingTop: '4px' }}>
                    {`${totalResults} Results Found`}
                  </BodyTwoSecondary>
                  <SupportTooltip absolute />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ maxWidth: '1408px', width: '100%', padding: '0 24px', margin: '0 auto 32px' }}>
          <FilledInput
            value={query}
            size="medium"
            fullWidth
            data-testid="search-input"
            placeholder="Search"
            onChange={e => onSearch(e)}
            endAdornment={query && (
              <IconButton data-testid="search-clear-button" onClick={onClear}>
                <ClearIcon fontSize='small' />
              </IconButton>
            )} />
        </Grid>
        {loading
          ? <Grid container sx={{ width: '100%', paddingTop: '24px' }} justifyContent="center">
            <CircularProgress color="primary" />
          </Grid>
          : totalResults === 0 ? (
            <section className="recent-items" style={{ maxWidth: '1408px', width: '100%', margin: '48px auto 32px', padding: '0 32px' }}>
              <SubtitleOnePrimary style={{ marginBottom: '8px' }}>
                Not finding what you’re looking for?
              </SubtitleOnePrimary>
              <BodyOneSecondary>
                <a role="link" style={{ color: LIGHT_THEME.palette.primary.main, cursor: 'pointer' }}>Contact us</a>, we’ll help you find it.
              </BodyOneSecondary>
            </section>
          ) : (
            <>
              {summaryResult.services.length > 0 && (
                <div style={{ backgroundImage: 'linear-gradient(180deg, #ffffff 0%, rgba(247, 247, 247, 0.97) 100%)' }}>
                  <section
                    className="recentt-items recentt-items--compact recentt-items--benefits recentt-items--search-section"
                    style={{
                      maxWidth: '1408px',
                      width: '100%',
                      margin: '0 auto',
                      padding: '0 24px'
                    }}>
                    <Divider sx={{
                      '&.MuiDivider-root': { margin: '0 0 10px' }
                    }} />
                    <div className="recentt-items__title-showing " style={{ margin: 0, justifyContent: 'space-between' }}>
                      <HeaderSix>Services</HeaderSix>
                      <CaptionSecondary className="recentt-items__title-showing-number">
                        Showing {summaryResult.services.length}/{summaryResult.services.length}
                      </CaptionSecondary>
                    </div>
                    <Grid container item sx={{ marginBottom: '60px' }}>
                      {summaryResult.services.map((benefit, idx) => (
                        <Grid key={idx} item xs={3} className="recentt-item__benefit">
                          <a
                            onClick={() => {
                              navigate(`/service/${benefit.ServiceId}`);
                              addRecentSearch();
                            }
                            }
                            className="recentt-item recentt-item--link recentt-item--benefit"
                            style={{ alignItems: 'flex-start' }}
                          >
                            <img
                              srcSet={`
                                ${require(`../assets/img/category/${benefit.ImageUrl}.png`)} 1x, 
                                ${require(`../assets/img/category/${benefit.ImageUrl}@2x.png`)} 2x,
                                ${require(`../assets/img/category/${benefit.ImageUrl}@3x.png`)} 3x
                              `}
                              src={require(`../assets/img/category/${benefit.ImageUrl}@2x.png`)}
                              alt={`${benefit.CategoryName} logo`}
                              className="recentt-item__media thumb thumb--2xsmall"
                            />
                            <div className="recentt-item__content benefit__content" style={{ padding: 0 }}>
                              <CaptionSecondary className="recentt-item__caption u-text-truncate">
                                {benefit.CategoryName}
                              </CaptionSecondary>
                              <SubtitleTwoPrimary className="recentt-item__subtitle-2 u-text-truncate">
                                {benefit.ServiceName}
                              </SubtitleTwoPrimary>
                            </div>
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  </section>
                </div>
              )
              }
              {summaryResult.Articles.List.length > 0 && (
                <section className="recent-items" style={{ maxWidth: '1408px', width: '100%', margin: '0 auto', padding: '0 24px' }}>
                  <Divider sx={{
                    '&.MuiDivider-root': { margin: '0 0 10px' }
                  }} />
                  <div className="recent-items__title">
                    <HeaderSix>Articles</HeaderSix>
                    <CaptionSecondary>
                      Showing {summaryResult.Articles.List.length}/{summaryResult.Articles.Total}
                    </CaptionSecondary>
                  </div>
                  <Grid container item columnSpacing={20 / 8} alignItems="center">
                    {summaryResult.Articles.List.map((article: ArticleType) => (
                      <Grid key={article.Id} className="recent-items__content" item lg={4} xl={4} xs={12} md={6} sm={6}>
                        <a
                          onClick={() => {
                            navigate(`/detail/article/${article.Id}`, {
                              state: {
                                categoryId: location.state.categoryId,
                                serviceId: location.state.serviceId
                              }
                            });
                            addRecentSearch();
                          }}
                          className="recent-item recent-item--link"
                        >
                          <img
                            srcSet={`
                          ${require('../assets/img/article.png')} 1x, 
                          ${require('../assets/img/article@2x.png')} 2x,
                          ${require('../assets/img/article@3x.png')} 3x
                        `}
                            src={require('../assets/img/article@2x.png')}
                            alt="article"
                            className="recent-item__media thumb thumb--large thumb--picture-icon"
                          />
                          <div className="recent-item__content">
                            <SubtitleOnePrimary className="u-text-truncate-multi">
                              {article.Title}
                            </SubtitleOnePrimary>
                            <BodyTwoSecondary>
                              {FormatDate(new Date(article.PublishFromDate), { day: 'numeric', month: 'short', format: 'dd MM YYYY' })}
                            </BodyTwoSecondary>
                          </div>
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </section>
              )}
              {summaryResult.Products.List.length > 0 && (
                <section className="recent-items" style={{ maxWidth: '1408px', width: '100%', margin: '0 auto', padding: '0 24px' }}>
                  <Divider sx={{
                    '&.MuiDivider-root': { margin: '0 0 10px' }
                  }} />
                  <div className="recent-items__title">
                    <HeaderSix>Products</HeaderSix>
                    <CaptionSecondary>
                      Showing {summaryResult.Products.List.length}/{summaryResult.Products.Total}
                    </CaptionSecondary>
                  </div>
                  <Grid container item columnSpacing={20 / 8} alignItems="center">
                    {summaryResult.Products.List.map((product: ProductType) => (
                      <Grid key={product.RemoteId} className="recent-items__content" item lg={4} xl={4} xs={12} md={6} sm={6}>
                        <a
                          onClick={() => {
                            navigate(`/detail/product/${product.RemoteId}`, {
                              state: {
                                categoryId: location.state.categoryId,
                                serviceId: location.state.serviceId
                              }
                            });
                            addRecentSearch();
                          }}
                          className="recent-item recent-item--link"
                        >
                          <img
                            src={product.Packshot}
                            className="
                              recent-item__media
                              thumb
                              thumb--large
                              thumb--picture-icon
                            "
                            style={{ padding: 8 }}
                          />
                          <div className="recent-item__content">
                            <BodyTwoSecondary>
                              {product.Brand}
                            </BodyTwoSecondary>
                            <SubtitleOnePrimary className="u-text-truncate-multi">
                              {product.Name}
                            </SubtitleOnePrimary>
                          </div>
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </section>
              )}
              {summaryResult.Colours.List.length > 0 && (
                <section className="recent-items" style={{ maxWidth: '1408px', width: '100%', margin: '0 auto', padding: '0 24px' }}>
                  <Divider sx={{
                    '&.MuiDivider-root': { margin: '0 0 10px' }
                  }} />
                  <div className="recent-items__title">
                    <HeaderSix>Colours</HeaderSix>
                    <CaptionSecondary>
                      Showing {summaryResult.Colours.List.length}/{summaryResult.Colours.Total}
                    </CaptionSecondary>
                  </div>
                  <Grid container item columnSpacing={20 / 8} alignItems="center">
                    {summaryResult.Colours.List.map((colour: ColourType) => (
                      <Grid key={colour.Id} className="recent-items__content" item lg={4} xl={4} xs={12} md={6} sm={6}>
                        <a
                          onClick={() => {
                            navigate(`/detail/colour/${colour.Id}`, {
                              state: {
                                categoryId: location.state.categoryId,
                                serviceId: location.state.serviceId
                              }
                            });
                            addRecentSearch();
                          }}
                          className="recent-item recent-item--link"
                        >
                          <span
                            className="
                              recent-item__media
                              thumb thumb--large
                            "
                            style={{
                              backgroundColor: colour.Rgb ? '#' + colour.Rgb : '',
                              borderRadius: '0px 17px',
                              boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                              aspectRatio: '1/1'
                            }}
                          ></span>
                          <div className="recent-item__content">
                            <SubtitleOnePrimary className="u-text-truncate-multi">
                              {colour.Name}
                            </SubtitleOnePrimary>
                          </div>
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </section>
              )}
              {summaryResult.serviceContents.length > 0 && summaryResult.serviceContents.map((serviceContent: any) => {
                const openIndustryInsigtsDetailPage = serviceContent.GroupName === 'Industry Insights';
                const openIndustryEventsDetailPage = serviceContent.GroupName === 'Industry Events';
                const openDetailPage = serviceContent.GroupName === 'Digital Toolkit';
                const openBrandAssetDetailPage = serviceContent.GroupName === 'Brand Assets';  
                const brandAssetsSeeMore = serviceContent.GroupName === 'Brand Assets';
                const showEventDate = serviceContent.GroupName === 'Industry Events';
                return (
                  <section key={serviceContent.GroupName} className="recent-items" style={{ maxWidth: '1408px', width: '100%', margin: '0 auto', padding: '0 24px' }}>
                    <Divider sx={{
                      '&.MuiDivider-root': { margin: '0 0 10px' }
                    }} />
                    <div className="recent-items__title">
                      <HeaderSix>{serviceContent.GroupName}</HeaderSix>
                      <CaptionSecondary>
                          Showing {serviceContent.Service.length}/{serviceContent.Service.length}
                      </CaptionSecondary>                    
                    </div>                                       
                    {brandAssetsSeeMore && (
                      <div className="recent-items__title" style={{ paddingRight: '0'}}>
                        <a
                          className="search-dropdown-subheader__action link link--small"
                          role="link"
                          style={{ marginLeft: 'auto'}}
                          onClick={() => navigate(`/service/${serviceContent.Service[0].ServiceId}?query=${query}`)}
                        >
                            See More
                        </a>
                      </div>
                    )}
                    <Grid container item columnSpacing={20 / 8} alignItems="center">
                      {serviceContent.Service.map((service: any) => (
                        <Grid key={service.Id} className="recent-items__content" item lg={4} xl={4} xs={12} md={6} sm={6}>
                          <a
                            className="recent-item recent-item--link"
                            onClick={() => {
                              if (openIndustryInsigtsDetailPage) {
                                navigate(`/detail/industryinsight/${service.Id}`);
                              } else if (openDetailPage) {
                                navigate(`/detail/toolkit/${service.Id}`);
                              } else if (openBrandAssetDetailPage) {
                                navigate(`/asset-detail/${service.Id}`);
                              } else if (openIndustryEventsDetailPage) {
                                navigate(`/detail/industry-event/${service.Id}`);
                              } else {
                                navigate(`/service/${service.ServiceId}`);
                              }
                            }}
                          >
                            <img
                              src={service.ImageUrl}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imageIcon;
                              }}
                              className="
                                recent-item__media
                                thumb
                                thumb--large
                                thumb--picture-icon
                              "
                              style={{ padding: 0, objectFit: 'cover' }}
                              alt="Service Content icon"
                            />
                            <div className="recent-item__content">
                              <SubtitleOnePrimary className="u-text-truncate-multi">
                                {service.Title}
                              </SubtitleOnePrimary>
                              {showEventDate && (
                                <BodyTwoSecondary>
                                  {FormatDate(new Date(service.Published), { day: 'numeric', month: 'short', format: 'dd MM YYYY' })}
                                </BodyTwoSecondary>
                              )}
                            </div>
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  </section>
                );
              })}
              <Grid sx={{ maxWidth: '1408px', width: '100%', padding: '0 24px', margin: '0 auto' }}>
                <TablePagination
                  component="div"
                  count={totalResults}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Items Per Section:"
                  rowsPerPageOptions={[6,12,18,24,32]}
                />
              </Grid>
            </>
          )}
      </Grid>
      <Grid container sx={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
          <Footer />
        </Grid>
      </Grid>
    </Dashboard>
  );
}
