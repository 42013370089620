import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import parse from 'html-react-parser';
import { CircularProgress, Grid } from '@mui/material';
import { BodyTwoSecondary, CaptionPrimary, CaptionSecondary, HeaderFourSecondary } from '../components-atoms/TypographyComponents';
import { Dashboard } from '../components-organisms/Dashboard';
import { Footer } from '../components-organisms/Footer';
import { GetArticleDetailRequest, UpdateRecentArticleRequest, GetArticleDetailResponseModel } from '../service/serviceService';
import '../scss/components/_thumbnail.scss';
import '../scss/components/_search-dropdown.scss';
import { FormatDate } from '../utils/dateUtils';
import { SupportTooltip } from '../components-molecules/SupportTooltip';
import { gaService } from '../service/gaService';
import { LogKnowledgeBaseArticleView } from '../service/logService';

export function ArticleDetailsPage(): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [article, setArticle] = useState<GetArticleDetailResponseModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    gaService.pageView('Article Detail');
  }, []);

  useEffect(() => {
    if (token) {
      setLoading(true);
      GetArticleDetailRequest(
        token,
        id as GUID,
        (response) => {
          if (response) {
            const result = response.Result;
            setArticle(result);

            // Log article view
            LogKnowledgeBaseArticleView(
              token,
              {
                Id: result.Id,
                Title: result.Title
              },
              () => { return; },
              (error) => console.error('Failed to log article view:', error)
            );

            UpdateRecentArticleRequest(
              token,
              {
                RemoteId: `${result.Id}`,
                Title: result.Title,
                PublishedDate: new Date(result.PublishFromDate),
              },
              (response) => { if (response) setLoading(false); },
              (error: any) => { if (error.response) { console.error(error.response.data.Error); setLoading(false); } }
            );
          }
        },
        (error: any) => { if (error.response) { console.error(error.response.data.Error); setLoading(false); setNotFound(true); } }
      );
    }
  }, [token, id]);

  useEffect(() => {
    if (notFound) {
      navigate('/not-found');
    }
  }, [notFound, navigate]);

  return (
    <Dashboard noDrawer noMargin noPadding fullWidth merchantPage>

      {article ? (
        <>
          <Grid container direction="column" sx={{ width: '100%' }}>
            <Grid container justifyContent="center">
              <Grid direction="column" container item alignItems="center">
                <Grid sx={{ margin: '12px 0px 28px', maxWidth: '1408px', width: '100%', padding: '0 24px' }}>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate('/')}>Home</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate(`/category/${location.state.categoryId}`)}>Training</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate(`/service/${location.state.serviceId}`)}>Knowledge Base</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                  <CaptionPrimary style={{ marginBottom: '4px' }}>{article.Title}</CaptionPrimary>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  sx={{
                    margin: '90px 0 32px',
                    background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)'
                  }}>
                  <Grid
                    container
                    sx={{
                      maxWidth: '1408px',
                      width: '100%',
                      padding: '0 24px'
                    }}>
                    <div>
                      <HeaderFourSecondary style={{ maxWidth: '783px', width: '100%', marginBottom: '4px' }}>{article.Title}</HeaderFourSecondary>
                      {(article.PublishFromDate && article.PublishFromDate !== '') && (
                        <BodyTwoSecondary style={{ color: '#545850', maxWidth: '783px', width: '100%', marginBottom: '20px' }}>
                          {FormatDate(new Date(article.PublishFromDate), { day: 'numeric', month: 'short', format: 'MM dd, YYYY' })}
                        </BodyTwoSecondary>
                      )}
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                      <SupportTooltip />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ color: 'rgba(0, 0, 0, 0.54)', maxWidth: { sm: '672px' }, width: '100%', padding: '0 24px'  }}>
                {parse(article.Body || '')}
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{
            background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
              <Footer />
            </Grid>
          </Grid>
        </>
      ) : loading ? (
        <div className="progress-spinner-wrapper">
          <CircularProgress size={32} />
        </div>
      ) : (
        <></>
      )}
    </Dashboard>
  );
}
