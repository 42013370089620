import { useState, useCallback, useEffect, useRef } from 'react';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { ListProductsRequest, ProductsListModel } from '../service/serviceService';
import { NPDServiceList } from './NPDServiceList';

interface IProps {
  id: string;
}

export function NPDService({ id }: IProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [products, setProducts] = useState<ProductsListModel[]>([]);
  const pageSize = 10;
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [sortOption, setSortOption] = useState<string>('date');
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const initialRenderRef = useRef(true);
  
  // Function to fetch products with current filter values
  const fetchProducts = useCallback((
    currentToken: string, 
    currentPage = 0, 
    currentPageSize: number = pageSize, 
    prevData: ProductsListModel[] = [],
    currentBrand: string = selectedBrand,
    currentProduct: string = selectedProduct,
    currentSort: string = sortOption,
    currentQuery: string = query
  ) => {
    if (currentToken) {
      setLoading(true);
      ListProductsRequest(
        currentToken,
        {
          Size: currentPageSize,
          Page: currentPage,
          ServiceId: id,
          Query: currentQuery || undefined,
          Brand: currentBrand || undefined,
          Product: currentProduct || undefined,
          SortBy: currentSort || undefined
        },
        (response) => {
          if (response) {
            setTotal(response.Result.Count);
            
            // Transform NPDItem[] to ProductsListModel[]
            const transformedProducts: ProductsListModel[] = [];
            
            // Flatten the grouped products into a single array
            response.Result.Result.forEach(product => {
              transformedProducts.push({
                Id: product.Id,
                Order: 0, // Default order
                Title: product.Title,
                Description: product.Description,
                ImageUrl: product.ImageUrl,
                ThumbnailUrl: product.ThumbnailUrl,
                Brand: product.Brand,
                Product: product.Product
              });
            });
            
            if (currentPage === 0) {
              // If it's a new search/filter, replace the products
              setProducts(transformedProducts);
            } else {
              // If it's loading more, append to existing products
              setProducts(prevData.concat(transformedProducts));
            }
            setLoading(false);
          }
        },
        (error) => {
          console.error('Error fetching products:', error);
          setLoading(false);
        }
      );
    }
  }, [pageSize, id, query, selectedBrand, selectedProduct, sortOption]);

  const loadMore = useCallback(() => {
    if (token && !loading) {
      fetchProducts(token, page + 1, pageSize, products);
      setPage(prev => prev + 1);
    }
  }, [token, loading, page, pageSize, products, fetchProducts]);

  // Initial load
  useEffect(() => {
    if (token && initialRenderRef.current) {
      initialRenderRef.current = false;
      fetchProducts(token);
    }
  }, [token, fetchProducts]);

  // Handle filter changes
  const handleFilterChange = useCallback((brand: string, product: string, sort: string, searchQuery: string) => {
    // Update state with new filter values
    setSelectedBrand(brand);
    setSelectedProduct(product);
    setSortOption(sort);
    setQuery(searchQuery);
    setPage(0); // Reset to first page when filters change
    setProducts([]); // Clear existing products
    
    // Fetch with new filters
    if (token) {
      fetchProducts(token, 0, pageSize, [], brand, product, sort, searchQuery);
    }
  }, [token, pageSize, fetchProducts]);

  return (
    <NPDServiceList
      total={total}
      products={products}
      loadMore={loadMore}
      onFilterChange={handleFilterChange}
    />
  );
}
