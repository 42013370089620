/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { AssetFormTypes } from '../../forms/digitalAssets';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';

import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { FormControlDate, FormControlImage, FormControlInput, FormControlSelect } from '../../components-molecules/FormControl';
import { AddDigitalAssetRequest, GetBrands, GetProducts, GetColours, GetOrientations, GetTags, GetTypes, GetColourIds } from '../../service/serviceService';
import { FILE_TYPE_VALIDATION_MULTIPLE, REQUIRED_VALIDATION, validateWhiteSpace } from '../../forms/_predefinedValidations';
import { Autocomplete, Checkbox, Chip, createFilterOptions, FormControlLabel, LinearProgress, TextField } from '@mui/material';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { environment } from '../../environment';
import { v4 as uuidv4 } from 'uuid';
import { isNonRenderable } from '../../utils/isNonRenderable';
import BrandAssetFileTypes from '../../utils/BrandAssetsSupportedFileTypes';
import { IStyles, LIGHT_THEME } from '../../constants/theme';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { GetGlobalEmailNotificationServicesRequest } from '../../service/emailNotificationService';
import RestrictionUsers, { IRestrictionUserSource } from './RestrictionUsers';
import SaveChanges from './SaveChanges';

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface FileSize {
  [key: string]: {
    loaded: number;
  };
}

const filter = createFilterOptions<any>();

export function AddAsset(): JSX.Element {
  const assetStatusOptions: IOption[] = [
    { title: 'Published', color: 'success', value: true },
    { title: 'Unpublished', color: 'error', value: false }
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const { control, handleSubmit, formState: { isValid, isDirty }, getValues, setValue } = useForm({ mode: 'all', reValidateMode: 'onChange' },);
  const token = useTypedSelector((state) => state.userReducer.token);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [tagOptions, setTagOptions] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [brandOptions, setBrandOptions] = useState<any[]>([]);
  const [productOptions, setProductOptions] = useState<any[]>([]);
  const [colourOptions, setColourOptions] = useState<any[]>([]);
  const [akzoColourIdOptions, setAkzoColourIdOptions] = useState<any[]>([]);
  const [orientationOptions, setOrientationOptions] = useState<any[]>([]);
  const [typeOptions, setTypeOptions] = useState<any[]>([]);
  const [isDateValid, setIsDateValid] = useState<boolean>(false);
  const [fileSizes, setFileSizes] = useState<FileSize>({});
  const [totalSize, setTotalSize] = useState(0);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [emailNotificationSettings, setEmailNotificationSettings] = useState<boolean>();
  const [sendEmailNotification, setSendEmailNotification] = useState<boolean>(false);

  const [isNewAssetAdded, setIsNewAssetAdded] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const blobServiceClient = BlobServiceClient.fromConnectionString(environment.azureConnectionString);
  const uploadToAzure = (containerClient: ContainerClient, file: File) => {
    const uuid = uuidv4();
    const fileName = `${uuid}_${file.name}`.replaceAll(' ', '_');
    const blobName = `${environment.assetFolder}/${fileName}`;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    return blockBlobClient.upload(file, file.size, {
      onProgress: (ev) => {
        setFileSizes(prev => ({ ...prev, [file.name]: { loaded: ev.loadedBytes } }));
      }
    }).then(() => {
      return fileName;
    });
  };
  useEffect(() => {
    if (token) {
      setValue('published', true, { shouldValidate: true });
      GetTypes(token, (response) => {
        setTypeOptions(response.Result.map(m => ({ value: m.Id, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { });
      GetOrientations(token, (response) => {
        setOrientationOptions(response.Result.map(m => ({ value: m.Id, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { });
      GetBrands(token, (response) => {
        setBrandOptions(response.Result.map(m => ({ value: m.Name, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { });
      GetProducts(token, response => {
        setProductOptions(response.Result.map(m => ({ value: m.Name, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { });
      GetColours(token, response => {
        setColourOptions(response.Result.map(m => ({ value: m.Name, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { });
      GetColourIds(token, response => {
        setAkzoColourIdOptions(response.Result.map(m => ({ value: m.Name, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { });
      GetTags(token, (response) => {
        setTagOptions(response.Result.map(m => ({ value: m.Name, name: m.Name })));
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { });
    }
  }, [token, setValue]);

  const getGlobalEmailNotification = useCallback(() => {
    if (token) GetGlobalEmailNotificationServicesRequest(token, (response) => {
      const result = [...response];
      const isEnabledForTMandSM = result.find(r => r.ServiceName === 'Brand Assets — Territory & Sales Managers')?.IsEnabled;
      const isEnabledForMerchant = result.find(r => r.ServiceName === 'Brand Assets — Merchants')?.IsEnabled;
      setEmailNotificationSettings(isEnabledForTMandSM || isEnabledForMerchant);
    }, (error) => console.log(error));
  }, [token]);

  useEffect(() => {
    getGlobalEmailNotification();
  }, [getGlobalEmailNotification]);

  const handleRestrictionChange = ({ selectedAudiences, selectedMerchants }: any) => {
    setValue('AudienceUserRole', selectedAudiences, { shouldDirty: true });
    setValue('MerchantUserRole', selectedMerchants, { shouldDirty: true });
  };

  const getPercentage = (obj: FileSize, totalSize: number) => {
    return Object.values(obj).length > 0 ? Object.values(obj).map(m => m.loaded).reduce((a, b) => a + b) / totalSize * 100 : 0;
  };

  const readAsDataURL = (files: FileList, blobClient: BlobServiceClient): Promise<Promise<any>[]> => {
    const containerClient = blobClient.getContainerClient(environment.containerName);
    const filesArray = Array.prototype.slice.call(files);
    let tempSizes = {};
    filesArray.map((m: File) => { tempSizes = { ...tempSizes, [m.name]: { loaded: 0 } }; });
    setFileSizes(tempSizes);
    setTotalSize(filesArray.map(m => m.size).reduce((a, b) => a + b));
    return new Promise(r => {
      Promise.all(filesArray.map((file: File) => uploadToAzure(containerClient, file))).then(urls => {
        r(filesArray.map((file: File, idx) => {
          return new Promise(resolve => {
            if (file.type.includes('image') && !isNonRenderable(file.type)) {
              const reader = new FileReader();
              reader.onload = e => {
                const img = new Image();
                img.src = e.target?.result as string;
                img.onload = () => {
                  resolve({
                    DigitalAssetFileTypeId: file.type,
                    Width: img.width,
                    Height: img.height,
                    FileUrl: urls[idx],
                    FileName: file.name,
                    SizeUnit: 'px',
                    Size: file.size,
                    Extension: file.name.split('.').pop(),
                  });
                };
              };
              reader.readAsDataURL(file);
            } else {
              resolve({
                DigitalAssetFileTypeId: file.type,
                Width: 0,
                Height: 0,
                FileUrl: urls[idx],
                FileName: file.name,
                SizeUnit: 'px',
                Size: file.size,
                Extension: file.name.split('.').pop(),
              });
            }
          });
        }));
      });
    });
  };

  const submit: SubmitHandler<AssetFormTypes> = (data): void => {
    setLoading(true);
    setScrollToTop(true);
    if (token) {
      if (data.logo) {
        const filesArray: File[] = Array.prototype.slice.call(data.logo);
        if (!filesArray.every(f => f.type.split('/')[0] === filesArray[0].type.split('/')[0])) {
          setErrorMessage('All files need to be the same type.');
          setLoading(false);
          setScrollToTop(false);
          return;
        } else {
          setErrorMessage('');
        }
        readAsDataURL(data.logo, blobServiceClient).then((promises) => {
          Promise.all(promises).then(values => {
            AddDigitalAssetRequest(token,
              {
                Name: data.name,
                Barcode: data.barcode,
                Fini: data.fini,
                PublicationDate: new Date(data.publicationDate).toJSON(),
                Published: data.published,
                Brand: data.brand,
                Product: data.product,
                Colour: data.colour || '',
                AkzoColourId: data.akzoColourId || undefined,
                Tags: data.tags || [],
                DigitalAssetOrientationId: data.digitalAssetOrientationId,
                DigitalAssetTypeId: data.digitalAssetTypeId,
                DigitalAssetFiles: values as any[],
                SendEmailNotification: data.sendEmailNotification,
                VisibilityRestrictions: {
                  AudienceUserRole: data.AudienceUserRole,
                  MerchantUserRole: data.MerchantUserRole
                },
                DisableDownloads: data.disableDownloads || false
              },
              () => {
                setLoading(false);
                setIsNewAssetAdded(true);
                navigate(state.navigateBackTo, { state: { isNewAssetAdded: true } });
              },
              (e) => {
                setErrorMessage(e.response?.data.Error || '');
                setLoading(false);
                setScrollToTop(false);
              }
            );
          });
        }
        );
      }
    }
  };

  const handleClose = () => {
    if (isDirty) {
      setShowSaveModal(true);
      return;
    }
  
    navigate(state.navigateBackTo, { state: { isNewAssetAdded: isNewAssetAdded } });
  };
  
  const onSaveChangesSubmit = () => {
    setShowSaveModal(false);
    navigate(state.navigateBackTo, { state: { isNewAssetAdded: isNewAssetAdded } });
  };  

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormPageComponent
        buttonLoading={loading}
        hasError={!!errorMessage}
        title="Add Asset"
        buttonTitle="Add Asset"
        buttonDisabled={!isValid || !isDateValid || loading}
        close={handleClose}
        submit={handleSubmit(submit)}
        scrollToTop={scrollToTop}
      >
        {loading ? (
          <LinearProgress variant="determinate" color="secondary" value={getPercentage(fileSizes, totalSize)} sx={{ zIndex: 2 }} />
        ) : (
          <></>
        )}
        <Fragment>
          <Grid item sx={{ flexGrow: 1 }}>
            {!!errorMessage
              && <Alert severity="error">{errorMessage}</Alert>
            }
          </Grid>
          <ModalCardComponent>
            <SectionHeader title="Basic information" />
            <Fragment>
              <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                <Grid item xs={12}>
                  <FormControlImage
                    accept={BrandAssetFileTypes}
                    data-testid="add-asset-image"
                    control={control}
                    field={{
                      label: 'Brand Asset',
                      name: 'logo',
                      inputType: 'logo',
                      validation: {
                        required: REQUIRED_VALIDATION,
                        validate: FILE_TYPE_VALIDATION_MULTIPLE(BrandAssetFileTypes)
                      }
                    }}
                    title='Brand Asset *'
                    multiple
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlInput
                    data-testid="add-asset-name"
                    control={control}
                    field={{
                      label: 'Brand asset name *',
                      name: 'name',
                      inputType: 'text',
                      validation: {
                        required: REQUIRED_VALIDATION,
                        validate: { validateWhiteSpace }
                      }
                    }} />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <Autocomplete
                    sx={{ '.MuiInputBase-input': { height: '31px', paddingTop: '3px', paddingBottom: '8px' } }}
                    data-testid="add-asset-tags"
                    multiple
                    autoSelect
                    value={selectedTags}
                    onChange={(e, newValue) => {
                      setValue('tags', newValue, { shouldDirty: true, shouldValidate: true });
                      setSelectedTags(newValue);
                    }}
                    id="tags-filled"
                    options={tagOptions.map((option) => option.name)}
                    freeSolo
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="tags"
                        value={getValues('tags') ? getValues('tags')[0] : ''}
                        variant="filled"
                        label="Tags"
                        placeholder="Tags"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <AssetStatusButtonGroup
                    subtitle="Published assets are visible to customers and unpublished assets are hidden."
                    title="Asset Status"
                    data-testid="add-asset-published"
                    onChange={(value: boolean) => setValue('published', value, { shouldDirty: true, shouldValidate: true })}
                    options={assetStatusOptions}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <FormControlLabel
                    sx={{ mt: 1, margin: '0px 0px' }}
                    componentsProps={{
                      typography: {
                        ml: 1
                      }
                    }}
                    control={
                      <Checkbox
                        sx={{ padding: '0', margin: '9px 0' }}
                        onChange={(e, checked) => {
                          setValue('disableDownloads', checked, { shouldDirty: true, shouldValidate: true });
                        }}
                        name="disableDownloads"
                      />
                    }
                    label="Disable downloads for this asset"
                  />
                </Grid>
              </Grid>
            </Fragment>
          </ModalCardComponent>
          <ModalCardComponent key="Detailed information">
            <SectionHeader title="Detailed information" />
            <Fragment>
              <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                <Grid item xs={12}>
                  <Autocomplete
                    data-testid="add-asset-brand"
                    {...control.register('brand', { required: true })}
                    value={getValues('brand')}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setValue('brand', newValue, { shouldDirty: true, shouldValidate: true });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue('brand', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                      } else {
                        setValue('brand', newValue, { shouldDirty: true, shouldValidate: true });
                      }
                    }}
                    id="brands-filled"
                    options={brandOptions.map((option) => option.name)}
                    freeSolo
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="brand"
                        variant="filled"
                        label="Brand"
                        placeholder="Brand"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <Autocomplete
                    data-testid="add-asset-product"
                    {...control.register('product', { required: true })}
                    value={getValues('product')}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setValue('product', newValue, { shouldDirty: true, shouldValidate: true });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue('product', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                      } else {
                        setValue('product', newValue, { shouldDirty: true, shouldValidate: true });
                      }
                    }}
                    id="products-filled"
                    options={productOptions.map((option) => option.name)}
                    freeSolo
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="product"
                        variant="filled"
                        label="Product"
                        placeholder="Product"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <Autocomplete
                    data-testid="add-asset-colour"
                    {...control.register('colour')}
                    value={getValues('colour')}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setValue('colour', newValue, { shouldDirty: true, shouldValidate: true });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue('colour', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                      } else {
                        setValue('colour', newValue, { shouldDirty: true, shouldValidate: true });
                      }
                    }}
                    id="colours-filled"
                    options={colourOptions.map((option) => option.name)}
                    freeSolo
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="colour"
                        variant="filled"
                        label="Colour"
                        placeholder="Colour"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                  <Autocomplete
                    data-testid="add-asset-colour-id"
                    {...control.register('akzoColourId')}
                    value={getValues('akzoColourId')}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setValue('akzoColourId', newValue, { shouldDirty: true, shouldValidate: true });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue('akzoColourId', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                      } else {
                        setValue('akzoColourId', newValue, { shouldDirty: true, shouldValidate: true });
                      }
                    }}
                    id="colour-ids-filled"
                    options={akzoColourIdOptions.map((option) => option.name)}
                    freeSolo
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="akzoColourId"
                        variant="filled"
                        label="Colour Id"
                        placeholder="Colour Id"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlSelect
                    data-testid="add-asset-asset-type"
                    control={control}
                    field={{
                      label: 'Type of product *',
                      name: 'digitalAssetTypeId',
                      inputType: 'select',
                      options: typeOptions,
                      validation: {
                        required: REQUIRED_VALIDATION
                      }
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlInput
                    data-testid="add-asset-barcode"
                    control={control}
                    field={{
                      label: 'Barcode',
                      name: 'barcode',
                      inputType: 'text',
                      validation: {}
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlInput
                    data-testid="add-asset-fini"
                    control={control}
                    field={{
                      label: 'FINI',
                      name: 'fini',
                      inputType: 'text',
                      validation: {}
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlSelect
                    data-testid="add-asset-orientation"
                    control={control}
                    field={{
                      label: 'Orientation *',
                      name: 'digitalAssetOrientationId',
                      inputType: 'select',
                      options: orientationOptions,
                      validation: {
                        required: REQUIRED_VALIDATION
                      }
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlDate
                    testId="publicationDate"
                    control={control}
                    field={{
                      label: 'Date of Creation *',
                      name: 'publicationDate',
                      inputType: 'date',
                      validation: {
                        required: REQUIRED_VALIDATION
                      }
                    }}
                    setIsDateValid={setIsDateValid} />
                </Grid>
              </Grid>
            </Fragment>
          </ModalCardComponent>
          <ModalCardComponent key="Email Notifications">
            <SectionHeader title="Email Notifications" />
            <BodyOneSecondary>
              Check the box below to inform users about the newly created asset.
            </BodyOneSecondary>
            <Fragment>
              <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
                <Grid
                  xs={12}
                  container
                  flexWrap="nowrap"
                  alignItems="center"
                  sx={{
                    cursor: 'pointer',
                    margin: '24px -16px 24px 16px',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    width: 'unset',
                    background: sendEmailNotification ? LIGHT_THEME.palette.action.selected : 'none',
                    ':hover': { background: LIGHT_THEME.palette.action.hover }
                  }}>
                  <FormControlLabel
                    disabled={!emailNotificationSettings}
                    sx={{ mt: 1, margin: '0px 0px' }}
                    componentsProps={{
                      typography: {
                        ml: 1
                      }
                    }}
                    control={
                      <Checkbox
                        sx={{ padding: '0', margin: '9px 0' }}
                        onChange={(e, checked) => {
                          setValue('sendEmailNotification', checked, { shouldDirty: true, shouldValidate: true });
                          setSendEmailNotification(checked);
                        }}
                        name="sendEmailNotification"
                      />
                    }
                    label="I would like to send out email notifications."
                  />
                </Grid>
              </Grid>
            </Fragment>
          </ModalCardComponent>
          
          <RestrictionUsers
            service={IRestrictionUserSource.BrandAssets}
            onChange={handleRestrictionChange} />

          <SaveChanges
            visible={showSaveModal}
            onCancel={() => setShowSaveModal(false)}
            onSubmit={onSaveChangesSubmit}
          />

          {loading ? (
            <div style={styles.overlay}>
            </div>
          ) : (
            <></>
          )}
        </Fragment>
      </FormPageComponent>
    </LocalizationProvider>
  );
}

const styles: IStyles = {
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
