import { Checkbox, Divider, FormHelperText, Grid } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { FormControlInput } from '../components-molecules/FormControl';
import { Section } from '../components-molecules/section/Section';
import { SectionHeader } from '../components-molecules/section/SectionHeader';
import { SectionRow } from '../components-molecules/section/SectionRow';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { Dashboard } from '../components-organisms/Dashboard';
import { EDIT_PHONE_FORM } from '../forms/editPhone';
import { USER_NAME_FORM } from '../forms/username';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import camelCaseKeys from 'camelcase-keys';
import { GetUserInfoRequest, UserEditRequest } from '../service/userService';
import { EditAdminRequest } from '../service/adminService';
import { BodyOnePrimary, BodyTwoSecondary } from '../components-atoms/TypographyComponents';
import { LIGHT_THEME } from '../constants/theme';
import { GetUserMarketing, SetUserMarketing } from '../service/serviceService';
import { useNavigate } from 'react-router-dom';

type DialogDataType = {
  fields: [FormInputField, FormInputField] | [FormInputField] | [];
  title: string;
};

type CamelizedType = {
  [key: string]: string;
};

export function Profile(): JSX.Element {
  const userData = useTypedSelector((state) => state.userReducer.data);
  const token = useTypedSelector((state) => state.userReducer.token);
  const dispatch = useTypedDispatch();
  let camelizedUserData: CamelizedType = {};
  if (userData) {
    camelizedUserData = camelCaseKeys<UserProperties>(userData, { deep: true, });
  }

  const { control, handleSubmit } = useForm<CamelizedType>({ mode: 'all', reValidateMode: 'onBlur', defaultValues: camelizedUserData });
  const [dialog, setDialog] = useState(false);
  const [dialogData, setDialogData] = useState<DialogDataType>({ fields: [], title: '' });
  const [optInMarketing, setOptInMarketing] = useState<boolean>(userData?.Marketing || false);
  const navigate = useNavigate();

  const onRowClick = (title: string, fields: ([FormInputField, FormInputField] | [FormInputField])): void => {
    setDialogData({ fields, title });
    setDialog(true);
  };

  const getUserInfo = (token: string) => {
    GetUserInfoRequest(
      token,
      (response) => {
        dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: { token: token, data: response.Result } });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  };

  const getUserMarketing = useCallback((token : string) => {
    GetUserMarketing(
      token,
      (response) => {
        setOptInMarketing(response.Result);
        dispatch({ type: 'USER_SET_MARKETING', payload: { marketing: response.Result } });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }, [dispatch]);

  useEffect(() => {
    token && getUserMarketing(token);
  }, [token, getUserMarketing]);

  const handleMarketingChange = () => {
    if (token && optInMarketing !== undefined) {
      SetUserMarketing(
        token,
        {Marketing: !optInMarketing},
        () => setOptInMarketing(prev=> !prev),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const editUser: SubmitHandler<CamelizedType> = (data): void => {
    if (token && userData) {
      if (userData.IsAdmin) {
        EditAdminRequest(
          token,
          { Id: userData.Id, FirstName: data.firstName, LastName: data.lastName, Phone: data.phone },
          () => {
            getUserInfo(token);
            setDialog(false);
          },
          (error) => {
            if (error.response) dispatch({ type: 'USER_ANY_FAILURE_NO_SIGNOUT', payload: { error: error.response.data.Error } });
          },
          true
        );
      } else {
        UserEditRequest(
          token,
          { Id: userData.Id, FirstName: data.firstName, LastName: data.lastName, Phone: data.phone, Role: data.role, Marketing: userData.Marketing || false },
          () => {
            getUserInfo(token);
            setDialog(false);
          },
          (error) => {
            if (error.response) dispatch({ type: 'USER_ANY_FAILURE_NO_SIGNOUT', payload: { error: error.response.data.Error } });
          }
        );
      }
    }
  };

  return (
    (userData)
      ? <Dashboard noDrawer merchantPage justifyContent="flex-start">
        <PageHeader
          title={'Account Settings'}
        />
        <Section>
          <SectionHeader title="Basic information" />
          <SectionRow title='NAME' lines={[{ data: `${userData.FirstName} ${userData.LastName}`, type: 'body1' }]} hasChevron onChevronClick={() => onRowClick('Edit User Name', USER_NAME_FORM)} />
          <Divider />
          <SectionRow title='EMAIL' lines={[{ data: userData.Email, type: 'body1', color: 'text.secondary' }]} />
          <Divider />
          <SectionRow title='PHONE' lines={[{ data: userData.Phone, type: 'body1' }]} hasChevron onChevronClick={() => onRowClick('Edit Phone Number', EDIT_PHONE_FORM)} />
          <Divider />

          <SectionRow title='PASSWORD' lines={
            userData.PasswordUpdated
              ? [
                { data: 'Reset your password', type: 'body1', color: 'text.primary' },
                { data: `Last updated ${new Date(userData.PasswordUpdated).toDateString()}`, type: 'body2', color: 'text.secondary' }]
              : [{ data: 'Reset your password', type: 'body1', color: 'text.primary' }]} hasChevron onChevronClick={() => navigate('/reset-password')} />
        </Section>
        {!userData.IsAdmin
          ? <Section>
            <SectionHeader title="Marketing preferences" />
            <BodyTwoSecondary>
              By checking the checkbox below you confirm you want to receive news, special offers and promotions from Connect for Merchants relating to its services to your registered email.
            </BodyTwoSecondary>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              <Grid
                xs={12}
                container
                flexWrap="nowrap"
                alignItems="center"
                onClick={handleMarketingChange}
                sx={{
                  cursor: 'pointer',
                  margin: '24px -16px 24px 16px',
                  padding: '8px 16px',
                  borderRadius: '8px',
                  width: 'unset',
                  background: optInMarketing ? LIGHT_THEME.palette.action.selected : 'none',
                  ':hover': { background: LIGHT_THEME.palette.action.hover }
                }}
              >
                <Grid item sx={{ height: '42px', width: '42px' }}>
                  <Checkbox checked={optInMarketing} sx={{ padding: '0', margin: '9px 0' }} />
                </Grid>
                <Grid item>
                  <BodyOnePrimary>
                    I would like to receive marketing emails.
                  </BodyOnePrimary>
                </Grid>
              </Grid>
            </Grid>
          </Section>
          : null}
        <MiniDialog title={dialogData.title} open={dialog} close={() => setDialog(false)} disabled={false} submit={handleSubmit(editUser)}>
          <Fragment>
            {dialogData.fields.map((field, index) => {
              return (
                <Grid key={index} item sx={{ flexGrow: 1, paddingRight: '16px', paddingLeft: '16px', }}>
                  <FormControlInput control={control} field={field} />
                  {field.helperText 
                    && <FormHelperText sx={{ padding: '0 14px' }}>
                      {field.helperText}
                    </FormHelperText>
                  }
                </Grid>
              );
            })
            }
          </Fragment>
        </MiniDialog>
      </Dashboard>
      : <Fragment></Fragment>
  );
}
