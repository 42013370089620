import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../hooks/TypedReduxHooks';

import { CircularProgress } from '@mui/material';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import { Add } from '@mui/icons-material';

import { Dashboard } from '../components-organisms/Dashboard';
import { MerchantAssignModal } from '../components-organisms/MerchantAssignModal';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { MerchantsList } from '../components-organisms/MerchantsList';
import { NoItems } from '../components-molecules/NoItems';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';

import { GetMerchantsRequest } from '../service/merchantsService';

import { LIGHT_THEME } from '../constants/theme';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

export function Merchants(): JSX.Element {
  const [token, isSalesManager] = useTypedSelector((state) => [state.userReducer.token, state.userReducer.data?.IsSalesManager]);
  const [loading, setLoading] = useState(true);
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState(0);
  const pageSize = 20;
  const [searchOpen, setSearchOpen] = useState(false);
  const [query, setQuery] = useState('');

  const navigate = useNavigate();

  const getMerchants = useCallback((t: string, page = 0, size = pageSize, prevData: Merchant[] = []): void => {
    GetMerchantsRequest(
      t,
      {
        Query: query,
        Size: size,
        Page: page
      },
      (response) => {
        if (response) {
          setLoading(false);
          setMerchants(page === 0 ? response.Result.Result : [...prevData, ...response.Result.Result]);
          setCount(response.Result.Count);
        }
      },
      (error) => { if (error.response) console.error(error.response.data.Error); }
    );
  }, [query]);

  useEffect(() => {
    setPage(0);
  }, [query]);

  const loadMore = () => {
    if (token) {
      getMerchants(token, page + 1, pageSize, merchants);
      setPage(page + 1);
    }
  };
  useEffect(() => {
    if (token) getMerchants(token);
  }, [token, getMerchants, query]);

  const onSubmitMerchantsModal = () => {
    if (token) getMerchants(token);
  };

  function headerRight (): JSX.Element {
    return(
      <Fragment>
        {isSalesManager
        && <MerchantAssignModal onSubmit={onSubmitMerchantsModal} />
        } 
        <ButtonContainedPrimary
          data-testid={'New Merchant'}
          startIcon={<Add />}
          onClick={() => navigate('/admin/add-merchant')}
          sx={{ margin: '0 0 0 8px', width: 'max-content' }}
        >
          New Merchant
        </ButtonContainedPrimary>
      </Fragment>
    );
  }

  const searchOnChange = (text:string) =>{
    setQuery(text);
  };

  return (
    <Dashboard justifyContent="flex-start">
      <PageHeader
        title={'Merchants'}
        subtitle={
          (count > 0)
            ? `${count} ${(count > 1) ? 'Merchants' : 'Merchant'}`
            : loading
              ? ''
              : 'No Merchants Yet'
        }
        
        activeButtons={merchants.length > 0}
        onClickSearch={() => setSearchOpen(!searchOpen)}
        noSort
        headerRight={headerRight}
      />
      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={query}
        placeholder="Search Merchants"
        onCancel={() => { setSearchOpen(false); setQuery(''); }}
        onChange={searchOnChange}
      />
      {loading
        ? <CircularProgress
          color='primary'
          size={50}
          style={{ zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }}
        />
        : ((merchants.length > 0)
          ? <MerchantsList
            merchants={merchants}
            loadMore={loadMore}
            total={count}
          />
          : <NoItems
            icon={<DomainDisabledIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
            title="No merchants yet"
            description="You don’t have any merchants yet. Start by adding the first merchant."
          />
        )}
    </Dashboard>
  );
}
