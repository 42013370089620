/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useMemo, useState } from 'react';
import { BodyOnePrimary, BodyOneSecondary, BodyTwoSecondary, CaptionPrimary, CaptionSecondary } from '../../components-atoms/TypographyComponents';
import { Box, Checkbox, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { LIGHT_THEME } from '../../constants/theme';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CardContainer, getTargetSrc } from '../../constants/digitalAssets';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormatBytes } from '../../utils/formatBytes';

const isDownloadFeatureEnabled = false;
interface IProps {
  digitalAsset?: DigitalAsset;
  currentDigitalAsset?: EditDigitalAssetFile;
  onDelete?: () => void;
  onEdit?: () => void;
  onClick: () => void;
  isAdmin?: boolean;
  isTMorSM?: boolean;
  oneCard?: boolean;
  listPage?: boolean;
  canTouch?: boolean;
  isSelectable?: boolean;
  selectedAssets?: DigitalAsset[];
  setSelectedAssets?: React.Dispatch<React.SetStateAction<DigitalAsset[]>>;
  source?: string;
  handleCheck: (asset: DigitalAsset | EditDigitalAssetFile | undefined)=> void;
  isSelected: boolean;
}

export default function DigitalAssetCard({ digitalAsset, currentDigitalAsset, isSelected, onDelete, onEdit, onClick, isAdmin, isTMorSM, oneCard, source, handleCheck }: IProps): JSX.Element {
  const asset: any = useMemo(() => digitalAsset || currentDigitalAsset, [ digitalAsset, currentDigitalAsset]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [imageError, setImageError] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<string>('');
  const open = Boolean(anchorEl);
  const mdDown = useMediaQuery(LIGHT_THEME.breakpoints.down('md'));
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const title = asset.DigitalAssetName || asset.ProductName;

  const isIcon: boolean = imageError;
  const targetSrc = useMemo(() => getTargetSrc(currentDigitalAsset, digitalAsset), [currentDigitalAsset, digitalAsset]);
  const renderOverlay = true;
  const isDetail = source === 'detail';

  const getAssetUrl = () => {
    if (currentDigitalAsset !== undefined && digitalAsset === undefined ) {
      return currentDigitalAsset?.ThumbnailUrl; 
    } else {
      return digitalAsset?.ThumbnailUrl; 
    }
  };

  const renderDialogs = () => {
    if (modalOpen === 'delete') {
      return (
        <MiniDialog
          title="Delete Asset"
          open={modalOpen === 'delete'}
          close={() => setModalOpen('')}
          remove={() => {
            if (onDelete) {
              onDelete();
              setModalOpen('');
            }
          }}>
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              {'Are you sure you want to delete this Asset?'}
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
      );
    }

    if (modalOpen === 'error') {
      return (
        <MiniDialog
          title="Multiple asset download"
          open={modalOpen === 'error'}
          close={() => setModalOpen('')}
          confirm
        >
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              <p>
                You can only download 25 assets at a time.
              </p>
              <p>
                To select and download other assets you will have to first deselect some of the assets in your current selection, or download current selection first and continue with other assets.
              </p>
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
      );
    }
  };

  // TODO: ??
  const PreviewVideo = () => {
    return <img
      data-testid="digital-asset-thumbnail"
      style={{borderRadius: '4px', objectFit: 'contain', maxWidth: !isIcon ? '100%' : oneCard ? '128px' : '48px', maxHeight: !isIcon ? '100%' : oneCard ? '128px' : '48px' }}
      alt="preview"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = targetSrc;
        setImageError(true);
      }}
      src={currentDigitalAsset?.ThumbnailUrl} 
    />;
  };
  
  const AssetPreview =  useCallback(() => {
    if (currentDigitalAsset?.FileType === 'Video') {
      return <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <PreviewVideo />
      </Box>;
    } else {
      let url:string|undefined;
      if (getAssetUrl( ) === '' || getAssetUrl() === null) {
        setImageError(true);
        url= targetSrc;
      } else {
        url=getAssetUrl();
      }
      return <>
        <img
          data-testid="digital-asset-thumbnail"
          style={{
            objectFit: 'contain',
            alignSelf: 'center',
            maxWidth: !isIcon ? '100%' : oneCard ? '128px' : '100%',
            maxHeight: !isIcon ? '100%' : oneCard ? '128px' : '100%' }}
          alt="preview"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src =targetSrc;
            setImageError(true);
          }}
          src={url}
        />
      </>;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDigitalAsset]);

  function renderMenuContent() {
    if (!asset || !isAdmin || isTMorSM) { return null; }
    return (
      <Box
        data-testid="digital-asset-text"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ padding: '0px 1px 0px 8px' }}
      >
        {(isAdmin)
          ? <IconButton data-testid="digital-asset-three-dot" onClick={(event) => { event.stopPropagation(); handleClick(event); }} sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <MoreVertIcon sx={{ height: '20px', width: '20px' }} />
          </IconButton>
          : null
        }
      </Box>
    );
  }

  function renderMenu() {
    return (
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {onEdit
          && <MenuItem data-testid="digital-asset-three-dot-edit" onClick={() => { onEdit(); handleClose(); }}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        }
        {onDelete
          && <MenuItem data-testid="digital-asset-three-dot-delete" onClick={() => { setModalOpen('delete'); handleClose(); }}>
            <ListItemIcon>
              <Delete fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
          </MenuItem>
        }
      </Menu>
    );
  }

  function renderImage() {
    return (
      <Grid
        item
        container
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        data-testid="digital-asset-item"
        style={{
          aspectRatio: '1/1',
          cursor: 'pointer',
          position: 'relative',
          borderRadius: '4px',
          overflow: 'hidden',
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }}>
        {renderAssetType()}
        <AssetPreview  />
      </Grid>
    );
  }

  function renderInfo() {
    if(isDetail && currentDigitalAsset) {
      return(
        <Stack mt={1} gap={0.5}>
          <Typography
            style={{
              fontSize: '14px',
              color: 'black', 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: oneCard ? '220px' : isDetail ? '256px' : mdDown ? '74%' : '256px',
            }}>
            {currentDigitalAsset?.FileName}
          </Typography>

          <BodyTwoSecondary>
            {FormatBytes(currentDigitalAsset.Size, 'MB').toFixed(2)} MB
          </BodyTwoSecondary>
        </Stack>
      );
    }
    return (
      <Stack mt={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <BodyTwoSecondary sx={{ mb: 0.5 }} fontSize={smallDown ? 12 : 14}>
            {asset.BrandName}
          </BodyTwoSecondary>
          {renderMenuContent()}
        </Stack>
        
        <BodyOnePrimary mb={0.5} fontSize={smallDown ? 12 : 14}>
          {title}
        </BodyOnePrimary>
        {isDownloadFeatureEnabled
          &&  <Box display="flex" alignItems="center">
            <FileDownloadIcon sx={{ fontSize: 20, mr: 0.5, color: 'rgba(0, 0, 0, 0.6)' }}/>
            <CaptionSecondary component="span" color="rgba(0, 0, 0, 0.6)">
              {asset?.DownloadCount || 0}
            </CaptionSecondary>
          </Box>
        }
      </Stack>
    );
  }

  function renderDownloadCheckbox() {
    if (!isAdmin && !isTMorSM && asset?.DisableDownloads) {
      return <></>;
    }

    if(renderOverlay) {
      return(
        <Box
          mt={2}
          sx={{
            zIndex: 9,
            position: 'absolute',
            right: 8,
            padding: 0.1 ,
            background:'white',
          }}>
          <Checkbox
            className='checkbox'
            data-testid='brand-asset-multi-select'
            checked={isSelected}
            onChange={()=> handleCheck(digitalAsset||currentDigitalAsset)}
          />
        </Box>
      );
    }

    return <></>;
  }

  function getAssetTypeLabel(ft: any, isDetail: boolean) {
    const typeText = ft.Count > 1 ? `${ft.Type}s` : ft.Type;
    const countText = ft.Count || 0;

    if (isDetail) {
      return typeText;
    }
    return <><strong>{countText}</strong> {typeText}</>;
  }

  function renderAssetType() {
    const asset: any = digitalAsset ? digitalAsset : currentDigitalAsset;
    if(!asset){ return <></>; }
    const assets = currentDigitalAsset ? [{Type: [currentDigitalAsset?.FileType], Count: 1}] : digitalAsset?.DigitalAssetFileTypes?.filter((ft: any) => ft.Count !== 0);
    
    return(
      <Stack sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        position: 'absolute',
        left: 0,
        bottom: 8,
      }}>
        {(assets || []).map((ft: any, idx: number)=>
          <CaptionPrimary  
            key={idx}
            sx={{
              background: 'white',
              padding: '2px 4px',
              lineHeight: '16px'
            }}>
            {getAssetTypeLabel(ft, isDetail)}
          </CaptionPrimary>
        )}

        {((isAdmin || isTMorSM) && asset?.IsRestricted)
          ? <CaptionPrimary
            sx={{
              background: LIGHT_THEME.palette.warning.light,
              padding: '2px 4px',
              lineHeight: '16px'
            }}>
            Restricted
          </CaptionPrimary>
          : null}
      </Stack>
    );
  }

  const oneCardSize = smallDown ? 6 : 4;
  return (
    <Grid 
      item
      container
      xs={oneCard ? oneCardSize : 6}
      sm={oneCard ? oneCardSize : isDetail ? 4 : isAdmin ? 6 : 4}
      md={oneCard ? oneCardSize : isDetail ? 4 : isAdmin ? 6 : 3}
      lg={oneCard ? oneCardSize : isDetail ? 3 : isAdmin ? 4 : 3}
      xl={oneCard ? oneCardSize : isDetail ? 3 : isAdmin ? 4 : 2}
      style={{ position: 'relative', maxHeight: '80vh' }}>
      <CardContainer
        container
        item
        isSelected={isSelected}
        onClick={onClick}
      >
        {renderImage()} 
        {renderInfo()}
      </CardContainer>

      {renderDownloadCheckbox()}
      {renderMenu()}
      {renderDialogs()}
    </Grid >
  );
}
