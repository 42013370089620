/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Alert, LinearProgress, Autocomplete, TextField, Chip, createFilterOptions } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormControlImage, FormControlInput } from '../../components-molecules/FormControl';
import { ModalCardComponent } from '../../components-molecules/Modal';
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { REQUIRED_VALIDATION, LOGO_VALIDATION_WITH_TYPE } from '../../forms/_predefinedValidations';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { CreateProductRequest, GetBrands, GetProducts } from '../../service/serviceService';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useNavigate } from 'react-router-dom';
import { IStyles } from '../../constants/theme';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { environment } from '../../environment';

const TOOLBAR_CONFIG: ToolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS',],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Paragraph', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-five' },
    { label: 'Heading Small', style: 'header-six' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Bullet List', style: 'unordered-list-item', className: 'rte-ordered-list' },
    { label: 'Numbered List', style: 'ordered-list-item' }
  ]
};

interface AddProductModel {
  title: string;
  body: string;
  packshot: File;
  published: boolean;
  brand: string;
  product: string;
}

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
}

export function AddProduct(): JSX.Element {
  const buttonGroupOptions: IOption[] = [
    { title: 'Published', color: 'success', value: true },
    { title: 'Unpublished', color: 'error', value: false }
  ];
  const token = useTypedSelector(state => state.userReducer.token);
  const [html, setHtml] = useState<EditorValue>(RichTextEditor.createEmptyValue());
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { control, handleSubmit, formState: { isValid }, setValue, getValues } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [brands, setBrands] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const navigate = useNavigate();
  const filter = createFilterOptions<any>();

  useEffect(() => {
    setValue('published', true, { shouldDirty: true, shouldValidate: true });
  }, [setValue]);

  // Load brands and products
  useEffect(() => {
    if (token) {
      GetBrands(
        token,
        (response: any) => {
          if (response && response.Result) {
            setBrands(response.Result.map((brand: any) => ({
              name: brand.Name,
              value: brand.Id
            })));
            setValue('brand', '', { shouldDirty: false, shouldValidate: true });
          }
        },
        (error) => {
          console.error('Error fetching brands:', error);
        }
      );

      GetProducts(
        token,
        (response: any) => {
          if (response && response.Result) {
            setProducts(response.Result.map((product: any) => ({
              name: product.Name,
              value: product.Id
            })));
            setValue('product', '', { shouldDirty: false, shouldValidate: true });
          }
        },
        (error) => {
          console.error('Error fetching products:', error);
        }
      );
    }
  }, [token, setValue]);

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);

  const submit: SubmitHandler<AddProductModel> = async (data) => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);

      const productImageFileName = await UploadToAzure({
        data: [data.packshot],
        onProgress: onProgress,
        folderName: environment.productFolder
      });

      const imageUrl = productImageFileName[0].Url;

      CreateProductRequest(
        token,
        {
          Title: data.title,
          Description: data.body,
          ImageUrl: imageUrl,
          Published: data.published,
          Brand: data.brand,
          Product: data.product
        },
        () => {
          setLoading(false);
          navigate(-1);
        },
        (error) => {
          setErrorMessage(error.response?.data.Error || '');
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  const onProgress = (data:onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses= tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return {...x, percentage: data.percentage};
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    loadingProgress.forEach(x=>{
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };

  return (
    <FormPageComponent
      hasError={!!errorMessage}
      title="Add Product"
      buttonTitle="Submit"
      buttonDisabled={!isValid || loading}
      buttonLoading={loading}
      close={() => navigate(-1)}
      submit={handleSubmit(submit)}
      scrollToTop={scrollToTop}
    >
      {loading
        ? <LinearProgress variant="determinate" color="secondary" value={percentage}  sx={{ zIndex: 2 }} />
        : <></>
      }
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>
        <ModalCardComponent>
          <SectionHeader title="Product details" />
          <Fragment>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlImage
                  accept="image/*"
                  data-testid="add-product-packshot"
                  control={control}
                  field={{
                    label: 'Packshot',
                    name: 'packshot',
                    inputType: 'logo',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate : LOGO_VALIDATION_WITH_TYPE('image/*')
                    }
                  }}
                  title="Packshot"
                  subtitle="Square image with 640px x 640px resolution works best."
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <FormControlInput
                  data-testid="add-product-title"
                  control={control}
                  field={{
                    label: 'Title',
                    name: 'title',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <Autocomplete
                  data-testid="add-product-brand"
                  {...control.register('brand')}
                  value={getValues('brand') || ''}
                  onChange={(e, newValue) => {
                    if (typeof newValue === 'string') {
                      setValue('brand', newValue, { shouldDirty: true, shouldValidate: true });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue('brand', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                    } else {
                      setValue('brand', newValue || '', { shouldDirty: true, shouldValidate: true });
                    }
                  }}
                  id="brands-filled"
                  options={brands.map((option) => option.name)}
                  freeSolo
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title || '';
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="brand"
                      variant="filled"
                      label="Brand"
                      placeholder="Brand"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <Autocomplete
                  data-testid="add-product-product"
                  {...control.register('product')}
                  value={getValues('product') || ''}
                  onChange={(e, newValue) => {
                    if (typeof newValue === 'string') {
                      setValue('product', newValue, { shouldDirty: true, shouldValidate: true });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue('product', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                    } else {
                      setValue('product', newValue || '', { shouldDirty: true, shouldValidate: true });
                    }
                  }}
                  id="products-filled"
                  options={products.map((option) => option.name)}
                  freeSolo
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title || '';
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="product"
                      variant="filled"
                      label="Product"
                      placeholder="Product"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <RichTextEditor
                  {...control.register('body', { required: REQUIRED_VALIDATION })}
                  value={html}
                  toolbarConfig={TOOLBAR_CONFIG}
                  placeholder="Body"
                  onChange={(val: EditorValue) => {
                    setHtml(val);
                    setValue('body', val.toString('html'), { shouldDirty: true, shouldValidate: true });
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                <AssetStatusButtonGroup
                  subtitle="Published products are visible to customers and unpublished products are hidden."
                  title="Product Status"
                  data-testid="add-product-published"
                  onChange={(value: boolean) => setValue('published', value, { shouldDirty: true, shouldValidate: true })}
                  options={buttonGroupOptions}
                />
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>
        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    </FormPageComponent>
  );
}

const styles: IStyles={
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
