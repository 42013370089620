/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  useTypedDispatch,
  useTypedSelector,
} from '../../hooks/TypedReduxHooks';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Toolbar,
  useMediaQuery,
  Stack,
  IconButton,
} from '@mui/material';
import { BodyOneSecondary } from '../../components-atoms/TypographyComponents';
import { MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import {
  GetAssetsFromDetailRequest,
  SingleFileDownloadLogRequest,
} from '../../service/assetService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ChangePublishedStateRequest,
  DeleteDigitalAssetRequest,
  GetDigitalAssetRequest,
} from '../../service/serviceService';
import { AddRequest, DeleteRequest } from '../../service/focusedContentService';
import { gaService } from '../../service/gaService';
import { HeaderDownloadButtons } from '../../components-molecules/header-components/HeaderDownloadButtons';
import { FullScreenAssetDialog, FullScreenDialogServiceType } from '../../components-molecules/dialogs/FullScreenAssetDialog';
import { LIGHT_THEME } from '../../constants/theme';
import { AssetDetailSidebar } from '../AssetDetailSidebar';
import { DownloadButtonSource } from '../../utils/enums';
import {
  ButtonContainedError,
  ButtonContainedPrimary,
  ButtonTextPrimary,
  ButtonTextPrimaryTooltip,
} from '../../components-atoms/ButtonComponents';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DigitalAssetCard from '../cards/DigitalAssetCard';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { LogBrandAssetDetailView } from '../../service/logService';
const NO_FOCUSED_TOOLTIP_TITLE =
  'You can only add 4 assets to Focused Content.';

export function AssetDetail(): JSX.Element {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { assetId } = useParams();
  const [digitalAsset] = useTypedSelector((state) => [
    state.digitalAssetReducer.currentData,
  ]);
  const [noFocusedContents] = useTypedSelector((state) => [
    state.digitalAssetReducer.noFocusedContents,
  ]);

  const [user, token, services, userServices] = useTypedSelector((state) => [
    state.userReducer.data,
    state.userReducer.token,
    state.drawerReducer.services,
    state.userReducer.services,
  ]);

  const isMdDown = useMediaQuery(LIGHT_THEME.breakpoints.down('md'));
  const [selectedDetailAssets, setSelectedDetailAssets] = useState<any>([]);

  const [fullScreenModalOpen, setFullScreenModalOpen] = useState<number>(-1);
  const [modalOpen, setModalOpen] = useState<string>('');
  const merchantToken = localStorage.getItem('merchantToken');
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const isTMorSM = user?.IsTerritoryManager || user?.IsSalesManager;
  const isAdmin = user?.IsAdmin && !isTMorSM && !merchantToken;

  const getAsset = useCallback(
    (id: string): void => {
      if (token) {
        setLoading(true);
        GetDigitalAssetRequest(
          token,
          id,
          (response) => {
            dispatch({
              type: 'SET_CURRENT_DIGITAL_ASSET',
              payload: { currentData: response.Result },
            });
            setLoading(false);

            LogBrandAssetDetailView(
              token,
              { Id: id as GUID, Title: response.Result.DigitalAsset.DigitalAssetName },
              () => { return; },
              (error) => console.error('Error logging brand asset detail view:', error)
            );
          },
          () => {
            navigate('/unauthorized');
            setLoading(false);
          }
        );
      }
    },
    [token, dispatch, navigate]
  );

  useEffect(() => {
    gaService.pageView('Asset Detail');
  }, []);

  useEffect(() => {
    if (assetId) {
      getAsset(assetId);
    }

    return () => {
      dispatch({
        type: 'SET_DIGITAL_ASSET_SELECT',
        payload: { selectedAssets: [] },
      });
    };
  }, [getAsset, assetId, dispatch]);

  const onPublishChange = (id: string) => {
    if (token) {
      ChangePublishedStateRequest(
        token,
        { DigitalAssetId: id },
        () => {
          getAsset(id);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  };

  const addToFocusedContentChange = (id: string) => {
    if (token) {
      AddRequest(
        token,
        id,
        () => {
          getAsset(id);
          dispatch({
            type: 'SET_DIGITAL_ASSET_NO_FOCUSED_CONTENTS',
            payload: { noFocusedContents: noFocusedContents + 1 },
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  };

  const removeFromFocusedContentChange = (id: string) => {
    if (token) {
      DeleteRequest(
        token,
        id,
        () => {
          getAsset(id);
          dispatch({
            type: 'SET_DIGITAL_ASSET_NO_FOCUSED_CONTENTS',
            payload: { noFocusedContents: noFocusedContents - 1 },
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  };

  const handleCheck = (
    digitalAsset: DigitalAsset | EditDigitalAssetFile | undefined
  ) => {
    const isSelected =
      selectedDetailAssets &&
      selectedDetailAssets?.some(
        (asset: any) => JSON.stringify(digitalAsset) === JSON.stringify(asset)
      );

    if (selectedDetailAssets && digitalAsset) {
      if (isSelected) {
        setSelectedDetailAssets(
          selectedDetailAssets.filter(
            (asset: any) =>
              JSON.stringify(digitalAsset) !== JSON.stringify(asset)
          )
        );
        return;
      }

      setSelectedDetailAssets([...selectedDetailAssets, digitalAsset]);
    }
  };

  const downloadOne = (file = digitalAsset.DigitalAssetFile[0]) => {
    const link = document.createElement('a');
    link.href = file.FileUrl;
    link.download = file.FileName;
    link.click();

    if (token) {
      SingleFileDownloadLogRequest(token, { id: file.Id });
    }
  };

  const downloadAll = () => {
    if (!token) {
      return;
    }

    if (digitalAsset.DigitalAssetFile?.length === 1) {
      downloadOne();
    } else {
      GetAssetsFromDetailRequest(
        token,
        digitalAsset.DigitalAsset.DigitalAssetId,
        (response) => {
          const link = document.createElement('a');
          link.href = response.Result;
          const lastSegment = response.Result.split('/').pop() as string;
          link.download = lastSegment;
          link.click();
        },
        () => { }
      );
    }
  };

  const onSelectAll = () => {
    setSelectedDetailAssets(digitalAsset.DigitalAssetFile);
  };

  const onDeselectAll = () => {
    setSelectedDetailAssets([]);
  };

  const getOnClick = (asset: EditDigitalAssetFile) => {
    const idx = digitalAsset.DigitalAssetFile.findIndex(
      (e) => e.Id === asset.Id
    );
    setFullScreenModalOpen(idx);
  };

  const onDelete = (id: string) => {
    if (token) {
      DeleteDigitalAssetRequest(
        token,
        id,
        () => {
          setModalOpen('');
          const digitalAssetServiceId = isAdmin
            ? services
              .flatMap((f) => f.Services)
              .find((m) => m.ServiceName === 'Brand Assets')?.ServiceId
            : userServices
              .flatMap((f) => f.Services)
              .find((m) => m.ServiceName === 'Brand Assets')?.ServiceId;
          const serviceUrl = isAdmin ? '/admin/servicedetail/' : '/service/';
          navigate(`${serviceUrl}${digitalAssetServiceId}`, {
            state: { fromDetail: true },
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  };

  const onBack = () => {
    if (state && state.previousUrl) {
      navigate(state.previousUrl);
      return;
    }
    const digitalAssetServiceId = isAdmin
      ? services
        .flatMap((f) => f.Services)
        .find((m) => m.ServiceName === 'Brand Assets')?.ServiceId
      : userServices
        .flatMap((f) => f.Services)
        .find((m) => m.ServiceName === 'Brand Assets')?.ServiceId;
    const serviceUrl = isAdmin ? '/admin/servicedetail/' : '/service/';
    navigate(`${serviceUrl}${digitalAssetServiceId}`, {
      state: { fromDetail: true },
    });
  };

  if (loading) {
    return (
      <CircularProgress
        color='primary'
        size={50}
        style={{ zIndex: 98, position: 'absolute', top: '50vh', right: '50vw' }}
      />
    );
  }

  function renderDeleteModal() {
    if (modalOpen === 'delete') {
      return (
        <MiniDialog
          title='Delete Asset'
          open={modalOpen === 'delete'}
          close={() => setModalOpen('')}
          remove={() => {
            onDelete(digitalAsset.DigitalAsset.DigitalAssetId);
            setModalOpen('');
          }}
          titleSx={{
            mt: 2,
            py: 3,
          }}
          bodySx={{
            height: 'unset',
          }}
          childrenSx={{
            py: 3,
            px: 3,
          }}
        >
          <Box>
            <BodyOneSecondary>
              Are you sure you want to delete this Asset?
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
      );
    }
  }

  function renderCards() {
    return (
      <Grid
        container
        item
        columnSpacing={'30px'}
        rowSpacing={2}
        sx={{
          maxWidth:
            digitalAsset.DigitalAssetFile.length === 1 ? '792px' : 'none',
        }}
      >
        {digitalAsset.DigitalAssetFile.map((assetFile, index) => (
          <DigitalAssetCard
            key={index}
            source='detail'
            currentDigitalAsset={assetFile}
            onClick={() => getOnClick(assetFile)}
            oneCard={digitalAsset.DigitalAssetFile.length === 1}
            handleCheck={handleCheck}
            isSelected={
              selectedDetailAssets &&
              selectedDetailAssets?.some(
                (asset: any) =>
                  JSON.stringify(assetFile) === JSON.stringify(asset)
              )
            }
          />
        ))}
      </Grid>
    );
  }

  function renderAddtoFocusedContentButton(disabled: boolean) {
    return disabled ? (
      <ButtonTextPrimaryTooltip
        onClick={() =>
          addToFocusedContentChange(digitalAsset.DigitalAsset.DigitalAssetId)
        }
        startIcon={<AddCircleOutline />}
        data-testid='button-add-to-focused-content'
        sx={{ height: '42px' }}
        disabled={noFocusedContents >= 4}
        tooltipTitle={NO_FOCUSED_TOOLTIP_TITLE}
      >
        Add to Focused Content
      </ButtonTextPrimaryTooltip>
    ) : (
      <ButtonTextPrimary
        onClick={() =>
          addToFocusedContentChange(digitalAsset.DigitalAsset.DigitalAssetId)
        }
        startIcon={<AddCircleOutline />}
        data-testid='button-add-to-focused-content'
        sx={{ height: '42px' }}
      >
        Add to Focused Content
      </ButtonTextPrimary>
    );
  }

  function renderHeader() {
    return (
      <Toolbar
        variant='modal'
        sx={{
          width: '100%',
          position: 'sticky',
          top: 0,
          zIndex: 99,
          height: '61px',
        }}
      >
        <IconButton onClick={onBack} sx={{ ml: '20px' }}>
          <ArrowBackIcon color='primary' />
        </IconButton>

        <Stack
          display='flex'
          flex={1}
          flexDirection='row'
          alignItems='center'
          pr={3}
          justifyContent='flex-end'
        >
          <Stack display='flex' flexDirection='row' gap={1} ml={1}>
            {isAdmin && digitalAsset.DigitalAsset.FocusedPriority && (
              <ButtonTextPrimary
                onClick={() =>
                  removeFromFocusedContentChange(
                    digitalAsset.DigitalAsset.DigitalAssetId
                  )
                }
                startIcon={<RemoveCircleOutline />}
                data-testid='modal-button-detail-edit'
                sx={{ height: '42px' }}
              >
                Remove from Focused Content
              </ButtonTextPrimary>
            )}
            {isAdmin &&
              !digitalAsset.DigitalAsset.FocusedPriority &&
              renderAddtoFocusedContentButton(noFocusedContents >= 4)}
            <HeaderDownloadButtons
              key={1}
              source={DownloadButtonSource.AssetDetail}
              downloadAll={downloadAll}
              onSelectAll={onSelectAll}
              onDeselectAll={onDeselectAll}
              selectedAssets={selectedDetailAssets}
              disableDownloads={digitalAsset.DigitalAsset.DisableDownloads}
            />
          </Stack>
          {isAdmin && (
            <Stack display='flex' flexDirection='row' gap={1} ml={1}>
              <ButtonContainedPrimary
                onClick={() =>
                  navigate('/admin/edit-asset', {
                    state: { navigateBackTo: window.location.pathname },
                  })
                }
                startIcon={<Edit />}
                data-testid='modal-button-detail-edit'
                sx={{ height: '42px' }}
              >
                Edit
              </ButtonContainedPrimary>
              <ButtonContainedError
                onClick={() => setModalOpen('delete')}
                startIcon={<DeleteIcon />}
                data-testid='modal-button-detail-delete'
                sx={{ height: '42px' }}
              >
                Delete
              </ButtonContainedError>
            </Stack>
          )}
        </Stack>
      </Toolbar>
    );
  }

  function renderContent() {
    return (
      <Stack
        pt={isMdDown ? 0 : 4}
        pb={4}
        px={4}
        display='flex'
        flex={1}
        flexDirection={isMdDown ? 'column' : 'row'}
        justifyContent='space-between'
      >
        <Stack flex={1} sx={{ order: isMdDown ? 1 : 'initial' }}>
          {renderCards()}
        </Stack>
        <Stack
          ml={{ xl: '112px', lg: '112px', md: '64px', sm: 0, xs: 0 }}
          width={isMdDown ? '100%' : '236px'}
        >
          <AssetDetailSidebar
            isAdmin={Boolean(isAdmin)}
            isTMorSM={Boolean(isTMorSM)}
            isSide={!isMdDown}
            digitalAsset={digitalAsset.DigitalAsset}
            chips={digitalAsset.DigitalAssetTag}
            onPublishChange={onPublishChange}
          />
        </Stack>

        <FullScreenAssetDialog
          open={fullScreenModalOpen > -1}
          index={fullScreenModalOpen}
          assets={digitalAsset.DigitalAssetFile}
          noDownloadButton={digitalAsset.DigitalAsset.DisableDownloads}
          onClose={() => setFullScreenModalOpen(-1)}
          serviceType={FullScreenDialogServiceType.DigitalAsset}
        />

        {renderDeleteModal()}
      </Stack>
    );
  }

  return (
    <Stack flex={1}>
      {renderHeader()}
      {renderContent()}
    </Stack>
  );
}
