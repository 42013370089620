import { Fragment, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Grid from '@mui/material/Grid';
import { Alert, FormHelperText } from '@mui/material';

import { TerritoryManagerFormTypes, TERRITORY_MANAGER_FORM } from '../../forms/territoryManager';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { InviteAdminRequest, CheckTerritoryManagerEmailExists } from '../../service/adminService';

import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormControlInput } from '../../components-molecules/FormControl';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useNavigate } from 'react-router-dom';

export function AddTerritoryManager(): JSX.Element {
  const form = useForm({ mode: 'all', reValidateMode: 'onBlur' });
  const token = useTypedSelector((state) => state.userReducer.token);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const navigate = useNavigate();

  const checkEmailExistence = async (email: string) => {
    if (token && email) {
      try {
        CheckTerritoryManagerEmailExists(token, email, 
          (response) => {
            setEmailExists(response.Result);
          },
          (error) => {
            console.error('Error checking email:', error);
          }
        );
      } catch (error) {
        console.error('Error checking email:', error);
      }
    } else {
      setEmailExists(false);
    }
  };

  const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    checkEmailExistence(event.target.value);
  };

  const submit: SubmitHandler<TerritoryManagerFormTypes> = (data): void => {
    setError('');
    if (token) {
      setLoading(true);
      InviteAdminRequest(
        token,
        {
          FirstName: data.firstName,
          LastName: data.lastName,
          Email: data.email,
          Phone: data.phone,
          IsAdmin: false,
        },
        () => {
          setLoading(false);
          navigate('/admin/salesmanagers', { state: { from: 'add-territorymanager' } });
        },
        (error) => {
          setError(error.response?.data.Error || '');
          setLoading(false);
        }
      );
    }
  };

  return (
    <FormPageComponent
      hasError={!!error}
      title="Add Territory Manager"
      buttonTitle="Add Manager"
      buttonDisabled={!form.formState.isValid || loading || emailExists}
      buttonLoading={loading}
      close={() => navigate('/admin/salesmanagers', { state: { from: 'add-territorymanager' } })}
      submit={form.handleSubmit(submit)}>
      <Grid item sx={{ flexGrow: 1 }}>
        {!!error
          && <Alert severity="error">{error}</Alert>
        }
      </Grid>
      <ModalCardComponent>
        <SectionHeader title="Manager Details" />

        <Fragment>
          {TERRITORY_MANAGER_FORM.map((field, index) => {
            return (
              <Grid key={index} item sx={{ flexGrow: 1 }}>
                <FormControlInput 
                  control={form.control} 
                  field={field} 
                  error={field.name === 'email' && emailExists ? 'A territory manager with this email already exists' : undefined}
                  onBlur={field.name === 'email' ? (e) => handleEmailBlur(e) : undefined}
                />
                {field.helperText
                  && <FormHelperText sx={{ padding: '0 14px' }}>
                    {field.helperText}
                  </FormHelperText>
                }
              </Grid>
            );
          })
          }
        </Fragment>
      </ModalCardComponent>
    </FormPageComponent >
  );
}
