/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Grid, useMediaQuery } from '@mui/material';
import { DigitalAssets } from './DigitalAssets';
import { ServicePageHeader } from '../components-organisms/appbars/ServicePageHeader';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { DEFAULT_BRAND_ASSET_SORT, SORT_TYPES, getDigitalAssets, mapFilterOptions, mergeListHeaders } from '../constants/digitalAssets';
import { DigitalAssetInfoModal } from '../components-organisms/modals/DigitalAssetInfoModal';
import { TabPanel } from '@mui/lab';
import { GetDigitalAssetFiltersRequest } from '../service/serviceService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isBrandAssetFilterApplied } from '../utils/getBrandAssetFilters';
import { ListRequest } from '../service/focusedContentService';
import { LIGHT_THEME } from '../constants/theme';
import ShareRestrictedContent from '../components-organisms/modals/ShareRestrictedContent';
import { STORE } from '../redux/store';

export function DigitalAssetService({ service, showPanel = false, isAdmin = false }: { service?: any, noTitle?: boolean, showPanel?: boolean, isAdmin?: boolean }): JSX.Element {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [token, selectedAssets, assetListToShow, focusedContents, savedFilter, savedQuery, sortType, offset, _top, refresh, assetsTotal] = useTypedSelector((state) => [state.userReducer.token, state.digitalAssetReducer.selectedAssets, state.digitalAssetReducer.assetListToShow, state.digitalAssetReducer.focusedContents, state.digitalAssetReducer.filter, state.digitalAssetReducer.search, state.digitalAssetReducer.sortType, state.digitalAssetReducer.offset, state.digitalAssetReducer.top, state.digitalAssetReducer.refresh, state.digitalAssetReducer.assetsTotal]);
  const [total, setTotal] = useState<number>(0);
  const [filterOptions, setFilterOptions] = useState();
  const [showRestrictionPopup, setShowRestrictionPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [shareSubject, setShareSubject] = useState('');
  const shareToken = searchParams.get('shareToken');

  const mdDown = useMediaQuery(LIGHT_THEME.breakpoints.down('md'));
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  const getFocusedContentItemsPerScreensize = (mdDown: boolean, smallDown: boolean) => {
    if (smallDown) {
      return 2;
    } else if (mdDown) {
      return 3;
    } else return 4;
  };

  const handleScroll = async (value: number) => {
    window.scrollTo(0, value);
  };

  useEffect(() => {
    if (assetsTotal > 0) {
      setTotal(assetsTotal);
    }
  }, [assetsTotal]);

  const getAssets = useCallback((prevData = [], search = savedQuery, _savedFilter = savedFilter, _offset = 0, _sortType = sortType) => {
    const currentShouldScroll = STORE.getState().digitalAssetReducer.shouldScroll;
    console.log('currentShouldScroll', currentShouldScroll);
    if (prevData.length > 0 && currentShouldScroll) {
      handleScroll(_top);
      dispatch({ type: 'SET_DIGITAL_ASSET_TOP', payload: { top: 0, shouldScroll: false } });
      return;
    }

    if (_offset === 0) {
      dispatch({ type: 'SET_DIGITAL_ASSET_OFFSET', payload: { offset: 0 } });
    }

    if (token) {
      dispatch({ type: 'SET_DIGITAL_ASSET_LOADING', payload: { loading: true } });
      getDigitalAssets(
        token,
        {
          Query: search,
          OrderBy: _sortType || DEFAULT_BRAND_ASSET_SORT,
          Offset: _offset ? _offset : 0,
          ShareToken: shareToken,
          ..._savedFilter
        },
        (res: any) => {
          setTotal(res.Count);
          dispatch({ type: 'SET_DIGITAL_ASSET_LIST_TO_SHOW', payload: { assetListToShow: _offset === 0 ? res.DigitalAssets : mergeListHeaders(res, prevData) } });
          dispatch({ type: 'SET_DIGITAL_ASSET_TOTAL', payload: { assetsTotal: res.Count } });
          dispatch({ type: 'SET_DIGITAL_ASSET_LOADING', payload: { loading: false } });
        },
      );
    }
  }, [token, dispatch, savedFilter, shareToken]);

  const getFocusedContentAsset = useCallback((token: string, prevData: any[] = []): void => {
    if (token) {
      ListRequest(
        token,
        (response) => {
          if (response) {
            const itemsPerScreen = getFocusedContentItemsPerScreensize(mdDown, smallDown);
            const result = prevData.concat(response.Result).slice(0, itemsPerScreen);

            dispatch({
              type: 'SET_DIGITAL_ASSET_FOCUSED_CONTENTS',
              payload: { focusedContents: result },
            });
            dispatch({
              type: 'SET_DIGITAL_ASSET_NO_FOCUSED_CONTENTS',
              payload: { noFocusedContents: result.length },
            });
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, refresh, mdDown, smallDown]);

  const getFilters = useCallback((t = token, query = savedQuery) => {
    if (t) {
      GetDigitalAssetFiltersRequest(
        t,
        {
          ProductIds: savedFilter.Product,
          ColourIds: savedFilter.Colour,
          BrandIds: savedFilter.Brand,
          OrientationIds: savedFilter.Orientation,
          FileTypeIds: savedFilter['File Type'],
          DigitalAssetTypeIds: savedFilter['Asset Type'],
          Query: query || undefined,
          ShareToken: shareToken || undefined
        },
        (response) => {
          const filters = response.Result;

          if (filters.ShareTokenSubject) {
            setShareSubject(filters.ShareTokenSubject);
          }
          delete filters.ShareTokenSubject;
          const options = mapFilterOptions({}, filters);
          setFilterOptions(options);
        },
        () => { }
      );
    }
  }, [token, mapFilterOptions, savedFilter, savedQuery, shareToken, sortType]);

  const loadMore = () => {
    dispatch({ type: 'SET_DIGITAL_ASSET_OFFSET', payload: { offset: offset + 1 } });
    getAssets(assetListToShow, savedQuery, savedFilter, offset + 1, sortType);
  };

  const onSearch = (search: string) => {
    handleScroll(0);

    const hasFilter = isBrandAssetFilterApplied(savedFilter);
    const _sort = (search && search?.length > 0) || hasFilter ? 'BestMatch' : DEFAULT_BRAND_ASSET_SORT;
    dispatch({ type: 'SET_DIGITAL_ASSET_SEARCH_AND_SORT', payload: { search, sortType: _sort } });
    getAssets([], search, savedFilter, 0, _sort);
    getFilters(token, search);
  };

  const onChange = (e: any) => {
    dispatch({ type: 'SET_DIGITAL_ASSET_OFFSET', payload: { offset: 0 } });

    if (e.fetchData) {
      searchParams.delete('shareToken');
      setSearchParams(searchParams);
    }

    if (!e.target?.name) return;

    switch (e.target.name) {
      case 'OrderBy':
        dispatch({
          type: 'SET_DIGITAL_ASSET_SORT',
          payload: {
            sortType: e.target.value
          }
        });
        getAssets([], savedQuery, savedFilter, 0, e.target.value);
        break;
      default:
        dispatch({
          type: 'SET_DIGITAL_ASSET_FILTER',
          payload: {
            filter: {
              ...savedFilter,
              [e.target.name]: e.target.value
            }
          }
        });
    }
  };

  useEffect(() => {
    if (token) {
      getFilters();
      getAssets(assetListToShow);
      getFocusedContentAsset(token);
    }
  }, [getAssets, token, refresh, savedFilter, mdDown, smallDown]);

  function handleShare() {
    const hasRestricted = selectedAssets.some((a: DigitalAsset) => a.IsRestricted);
    hasRestricted ? setShowRestrictionPopup(true) : navigate('/admin/digital-asset-share');
  }

  function renderContent(showPanel = false) {
    const isSavedQueryEmptyOrNull = savedQuery === null || savedQuery === undefined || savedQuery === '';
    const showFocusedContents =
      !isAdmin &&
      focusedContents &&
      focusedContents.length > 0 &&
      Object.values(savedFilter).every((array) => array.length === 0) &&
      isSavedQueryEmptyOrNull;
    return (
      <>
        <ServicePageHeader
          hasDynamicFilter
          service={service}
          isAdmin={isAdmin}
          infoModal={() => <DigitalAssetInfoModal />}
          searchPlaceholder='Search Assets'
          onSearch={onSearch}
          onChange={onChange}
          filterOptions={filterOptions}
          selectedFilters={savedFilter}
          sortOptions={SORT_TYPES}
          selectedSort={sortType}
          shareSubject={shareSubject}
          onShare={selectedAssets.length === 0 || shareToken !== null ? null : () => handleShare()}
          alwaysShowThirdRow
        />
        <Grid container item justifyContent='center' direction='column'>
          <DigitalAssets
            loadMore={loadMore}
            total={total}
            noMargin
            noTab={!showPanel}
            isAdmin={isAdmin}
            focusedContents={focusedContents}
            showFocusedContents={showFocusedContents} />
        </Grid>
        <ShareRestrictedContent
          open={showRestrictionPopup}
          onAccept={() => navigate('/admin/digital-asset-share')}
          onClose={() => setShowRestrictionPopup(false)} />
      </>
    );
  }

  if (showPanel) {
    return (
      <TabPanel value={'content'} sx={{ padding: 0 }}>
        {renderContent(showPanel)}
      </TabPanel>
    );
  }

  return (
    <Fragment>
      {renderContent()}
    </Fragment>
  );
}
