import { useState } from 'react';
import TabPanel from '@mui/lab/TabPanel';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { EmployeesList } from './EmployeesList';
import { NoItems } from '../components-molecules/NoItems';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { LIGHT_THEME } from '../constants/theme';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

interface MerchantEmployeeTabPanelProps {
  setPageOpen: () => void;
}

export function MerchantEmployeeTabPanel({ setPageOpen }: MerchantEmployeeTabPanelProps): JSX.Element {
  const employees = useTypedSelector(state => state.merchantReducer.employees);
  const userData = useTypedSelector(state => state.userReducer.data);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const searchOnChange = (text: string) => {
    setSearchQuery(text);
  };

  return (
    <TabPanel value="employees" sx={{ padding: 0 }}>
      <PageHeader
        title=""
        subtitle={
          (employees.length > 0)
            ? `${employees.length} ${(employees.length > 1) ? 'Employees' : 'Employee'}`
            : 'No Employees Yet'
        }
        buttonTitle="Add Employee"
        activeButtons={employees.length > 0}
        modalCallback={(userData && userData.Role !== 'Store Employee') ? setPageOpen : undefined}
        pageHeaderContainerStyle={{
          marginTop: '32px'
        }}
        noSort={true}
        onClickSearch={() => setSearchOpen(!searchOpen)}
      />

      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={searchQuery}
        placeholder="Search Employees"
        onCancel={() => { setSearchOpen(false); setSearchQuery(''); }}
        onChange={searchOnChange}
      />

      {(employees.length > 0)
        ? <EmployeesList 
          employees={employees} 
          navigateBackTo={window.location.pathname}
          searchQuery={searchQuery}
        />
        : <NoItems
          icon={<PersonOffIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
          title="No employee yet"
          description="You haven't added any employees to this merchant yet. Start by adding the first employee."
        />
      }
    </TabPanel>
  );
}
