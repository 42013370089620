/* eslint-disable @typescript-eslint/naming-convention */
import { Fragment, useEffect, useState } from 'react';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { CircularProgress, Divider, Grid, List, ListItem, useMediaQuery } from '@mui/material';
import { Download } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import { BodyOnePrimary, BodyOneSecondary, BodyTwoPrimary, BodyTwoSecondary, CaptionPrimary, CaptionSecondary, HeaderFourSecondary, HeaderSix, OverlineSecondary, SubtitleOnePrimary, SubtitleTwoSecondary } from '../components-atoms/TypographyComponents';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dashboard } from '../components-organisms/Dashboard';
import { Footer } from '../components-organisms/Footer';
import { GetProductDetailRequest, UpdateRecentProductRequest, GetProductDetailResponseModel, ProductDocument } from '../service/serviceService';
import '../scss/components/_list.scss';
import '../scss/components/_thumbnail.scss';
import '../scss/components/_search-dropdown.scss';
import { IStyles, LIGHT_THEME } from '../constants/theme';
import { SupportTooltip } from '../components-molecules/SupportTooltip';
import { gaService } from '../service/gaService';
import { LogKnowledgeBaseProductView } from '../service/logService';

export function ProductDetailsPage(): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [product, setProduct] = useState<GetProductDetailResponseModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openTechnicalInformation, setOpenTechnicalInformation] = useState<boolean>(true);
  const [openDocuments, setOpenDocuments] = useState<boolean>(false);
  const [openPackshots, setOpenPackshots] = useState<boolean>(false);
  const smallUp = useMediaQuery(LIGHT_THEME.breakpoints.up('sm'));
  const mobileUp = useMediaQuery('(min-width: 368px)');
 
  useEffect(() => {
    gaService.pageView('Product Detail');
  }, []);

  useEffect(() => {
    if (token) {
      setLoading(true);
      GetProductDetailRequest(
        token,
        id as GUID,
        (response) => {
          if (response) {
            const result = response.Result;
            setProduct(result);

            // Log product view
            LogKnowledgeBaseProductView(
              token,
              {
                Id: result.Id,
                Name: result.Name
              },
              () => { return; },
              (error) => console.error('Failed to log product view:', error)
            );

            UpdateRecentProductRequest(
              token,
              {
                RemoteId: result.Id,
                Name: result.Name,
                Brand: result.Brand,
                Packshot: result.Packshots[0].L,
              },
              (response) => {
                if (response) setLoading(false); },
              (error: any) => { if (error.response) { console.error(error.response.data.Error); setLoading(false); } }
            );
          }
        },
        (error: any) => { if (error.response) { console.error(error.response.data.Error); setLoading(false); } }
      );
    }
  }, [token, id]);

  return (
    <Dashboard noDrawer noMargin noPadding fullWidth merchantPage>
      {product ? (
        <>
          <Grid container direction="column" sx={{ width: '100%' }}>
            <Grid container direction="row" justifyContent="center">
              <Grid direction="column" container item alignItems="flex-start" justifyContent="flex-start" sx={{ maxWidth: '1408px', width: '100%', padding: '0 24px' }}>
                <Grid sx={{ margin: '12px 0px 28px' }}>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate('/')}>Home</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate(`/category/${location.state.categoryId}`)}>Training</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate(`/service/${location.state.serviceId}`)}>Knowledge Base</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                  <CaptionPrimary style={{ marginBottom: '4px' }}>{product.Name}</CaptionPrimary>
                </Grid>
                <Grid container item justifyContent="flex-start" sx={{ maxWidth: '1408px', width: '100%', marginTop: '32px' }}>
                  <Grid item sm={3}>
                    <Grid
                      container
                      justifyContent="center"
                      flexWrap="nowrap"
                      item
                      style={styles.imageContainer}>
                      <img
                        style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover' }}
                        src={product.Packshots[0].L}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                    <Grid container sx={{ position: 'relative', paddingBottom: '20px' }}>
                      <OverlineSecondary style={{ maxWidth: smallUp ? '672px' : '100%', width: '100%' }}>{product.Brand}</OverlineSecondary>
                      <HeaderFourSecondary style={{ maxWidth: smallUp ? '672px' : '100%', width: '100%' }}>{product.Name}</HeaderFourSecondary>
                      <Grid container justifyContent="space-between">
                        <BodyOneSecondary style={{ maxWidth: smallUp ? '672px' : '100%', width: '100%' }}>
                          {product.LocalSlogan}
                        </BodyOneSecondary>
                        <div style={{ marginLeft: 'auto', alignSelf: 'flex-end', }}>
                          <SupportTooltip />
                        </div>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container direction="row" justifyContent="space-between" sx={{ maxWidth: { sm: '672px' }, width: '100%', paddingTop: '20px' }}>
                      <Grid container item direction="column" justifyContent="space-between" sx={{ width: 'initial' }}>
                        <Grid>
                          <BodyTwoSecondary>Product Type</BodyTwoSecondary>
                          <BodyOnePrimary>{product.ProductType ? product.ProductType : 'N/A'}</BodyOnePrimary>
                        </Grid>
                        <Grid>
                          <BodyTwoSecondary style={{ marginTop: '24px' }}>Sheen</BodyTwoSecondary>
                          <BodyOnePrimary>{product.Sheen ? product.Sheen : 'N/A'}</BodyOnePrimary>
                        </Grid>
                      </Grid>
                      <Grid container item direction="column" justifyContent="space-between" sx={{ width: 'initial' }}>
                        <Grid>
                          <BodyTwoSecondary>Location</BodyTwoSecondary>
                          <Grid container sx={{ flexWrap: 'wrap', maxWidth: '213px' }}>
                            {product.Positions ? product.Positions.map((position: string, index: number, array: string[]) => (
                              <Fragment key={index}>
                                <BodyOnePrimary key={position}>{position}</BodyOnePrimary>
                                {(index + 1) !== array.length && (
                                  <BodyOnePrimary style={{ marginRight: '4px' }}>,</BodyOnePrimary>
                                )}
                              </Fragment>
                            )) : (
                              <BodyOnePrimary>N/A</BodyOnePrimary>
                            )}
                          </Grid>
                        </Grid>
                        <Grid>
                          <BodyTwoSecondary style={{ marginTop: '24px' }}>Opacity</BodyTwoSecondary>
                          <BodyOnePrimary>{product.Opacity ? product.Opacity : 'N/A'}</BodyOnePrimary>
                        </Grid>
                      </Grid>
                      <Grid container item direction="column" justifyContent="space-between" sx={{ width: 'initial' }}>
                        <Grid>
                          <BodyTwoSecondary>Surface</BodyTwoSecondary>
                          <Grid container sx={{ flexWrap: 'wrap', maxWidth: '213px' }}>
                            {product.Surfaces ? product.Surfaces.map((surface: string, index: number, array: string[]) => (
                              <>
                                <BodyOnePrimary key={surface}>{surface}</BodyOnePrimary>
                                {(index + 1) !== array.length && (
                                  <BodyOnePrimary style={{ marginRight: '4px' }}>,</BodyOnePrimary>
                                )}
                              </>
                            )) : (
                              <BodyOnePrimary>N/A</BodyOnePrimary>
                            )}
                          </Grid>
                        </Grid>
                        <Grid>
                          <BodyTwoSecondary style={{ marginTop: '24px' }}>Composition</BodyTwoSecondary>
                          <BodyOnePrimary>{product.Composition?.[0].Name ? product.Composition?.[0].Name : 'N/A'}</BodyOnePrimary>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="flex-start" sx={{ maxWidth: '1408px', width: '100%', marginTop: '48px' }}>
                  <Grid item sm={3}>
                    <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                      {product.KeySellingPoints ? (
                        <>
                          <SubtitleOnePrimary>Key Benefits</SubtitleOnePrimary>
                          <List sx={{ '&.MuiList-root': { paddingTop: '24px', paddingBottom: '29px' } }}>
                            {product.KeySellingPoints.map((item: any) => (
                              <ListItem
                                alignItems="flex-start"
                                disablePadding
                                key={item.OrderNum}
                                className="list-item"
                              >
                                <CheckIcon sx={{ fill: LIGHT_THEME.palette.primary.main, fontSize: '1.25rem' }}/>
                                <BodyTwoPrimary style={{ width: mobileUp ? '281.5px' : '100%' }}>{item.Description}</BodyTwoPrimary>
                              </ListItem>
                            ))}
                          </List>
                        </>
                      ) : (
                        <></>
                      )}
                      {product.Features ? (
                        <>
                          <SubtitleOnePrimary>Special Features</SubtitleOnePrimary>
                          <List sx={{ '&.MuiList-root': { paddingTop: '24px', paddingBottom: '29px' } }}>
                            {product.Features.map((item: any) => (
                              <ListItem
                                alignItems="flex-start"
                                disablePadding
                                key={item.Title}
                                className="list-item"
                              >
                                <CheckIcon sx={{ fill: LIGHT_THEME.palette.primary.main, fontSize: '1.25rem' }}/>
                                <BodyTwoPrimary style={{ width: mobileUp ? '281.5px' : '100%' }}>{item.Title || item.DefaultTitle}</BodyTwoPrimary>
                              </ListItem>
                            ))}
                          </List>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                    <Grid sx={{ maxWidth: { sm: '672px' }, width: '100%', margin: 0 }}>
                      {product.MarketingDescription ? (
                        <>
                          <SubtitleOnePrimary style={{ marginBottom: '8px' }}>About the Product</SubtitleOnePrimary>
                          <BodyOneSecondary>{product.MarketingDescription}</BodyOneSecondary>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="flex-start" sx={{ maxWidth: '1408px', width: '100%', marginTop: '48px' }}>
                  <Divider sx={{
                    '&.MuiDivider-root': { marginBottom: '10px', marginLeft: 'auto' },
                    maxWidth: '1340px',
                    width: '100%'
                  }} />
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: '23px', cursor: 'pointer' }} onClick={() => setOpenTechnicalInformation(!openTechnicalInformation)}>
                    <Grid item xs={3}>
                      <Grid sx={{ width: '320px', marginLeft: 'auto' }}>
                        <HeaderSix style={{ margin: 0 }}>Technical information</HeaderSix>
                      </Grid>
                    </Grid>
                    <Grid xs={9} item>
                      <Grid container justifyContent="flex-end" sx={{ maxWidth: '976px', width: '100%', marginLeft: 'auto' }}>
                        <ArrowDropDownIcon />
                      </Grid>
                    </Grid>
                  </Grid>
                  {openTechnicalInformation ? (
                    <>
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Nr. of Coats</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.NumberOfCoats ? product.NumberOfCoats : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{
                        '&.MuiDivider-root': { margin: '13px 0 13px auto' },
                        maxWidth: '1340px',
                        width: '100%'
                      }} />
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Spreading Rate</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.SpreadingRate ? product.SpreadingRate : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{
                        '&.MuiDivider-root': { margin: '13px 0 13px auto' },
                        maxWidth: '1340px',
                        width: '100%'
                      }} />
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Coverage</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.Coverage ? product.Coverage : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{
                        '&.MuiDivider-root': { margin: '13px 0 13px auto' },
                        maxWidth: '1340px',
                        width: '100%'
                      }} />
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Usage</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.Usage ? product.Usage : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{
                        '&.MuiDivider-root': { margin: '13px 0 13px auto' },
                        maxWidth: '1340px',
                        width: '100%'
                      }} />
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Touch Dry</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.DryingTimeTouchDry ? product.DryingTimeTouchDry : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{
                        '&.MuiDivider-root': { margin: '13px 0 13px auto' },
                        maxWidth: '1340px',
                        width: '100%'
                      }} />
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Recoatable</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.DryingTimeRecoat ? product.DryingTimeRecoat : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{
                        '&.MuiDivider-root': { margin: '13px 0 13px auto' },
                        maxWidth: '1340px',
                        width: '100%'
                      }} />
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Recommended Dry Film Thickness</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.RecommendedDryFilmThickness ? product.RecommendedDryFilmThickness : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{
                        '&.MuiDivider-root': { margin: '13px 0 13px auto' },
                        maxWidth: '1340px',
                        width: '100%'
                      }} />
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Application Method</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.ApplicationDescription ? product.ApplicationDescription : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{
                        '&.MuiDivider-root': { margin: '13px 0 13px auto' },
                        maxWidth: '1340px',
                        width: '100%'
                      }} />
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Surface Preparation</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.SurfaceDescription ? product.SurfaceDescription : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{
                        '&.MuiDivider-root': { margin: '13px 0 13px auto' },
                        maxWidth: '1340px',
                        width: '100%'
                      }} />
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Cleaning</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.Cleaning ? product.Cleaning : product.CleaningDescription ? product.CleaningDescription : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{
                        '&.MuiDivider-root': { margin: '13px 0 13px auto' },
                        maxWidth: '1340px',
                        width: '100%'
                      }} />
                      <Grid container direction="row">
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>Storage</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary>{product.Storage ? product.Storage : 'N/A'}</BodyTwoSecondary>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Divider sx={{
                    '&.MuiDivider-root': { margin: '46px 0 10px auto' },
                    maxWidth: '1340px',
                    width: '100%'
                  }} />
                  {product.Documents && (
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: '23px', cursor: 'pointer' }} onClick={() => setOpenDocuments(!openDocuments)}>
                      <Grid item xs={3}>
                        <Grid sx={{ width: '320px', marginLeft: 'auto' }}>
                          <HeaderSix style={{ margin: 0 }}>Documents</HeaderSix>
                        </Grid>
                      </Grid>
                      <Grid xs={9} item>
                        <Grid container justifyContent="flex-end" sx={{ maxWidth: '976px', width: '100%', marginLeft: 'auto' }}>
                          <ArrowDropDownIcon />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {openDocuments ? product.Documents && product.Documents.map((item: ProductDocument, index: number, array: ProductDocument[]) => (
                    <>
                      <Grid container direction="row" key={item.Title}>
                        <Grid item sm={3}>
                          <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                            <SubtitleTwoSecondary>{item.Type}</SubtitleTwoSecondary>
                          </Grid>
                        </Grid>
                        <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                          <Grid container alignItems="center" sx={{ maxWidth: '976px', width: '100%' }}>
                            <BodyTwoSecondary style={{ cursor: 'pointer' }} onClick={() => window.open(`${item.Url}`)}>{item.Title}</BodyTwoSecondary>
                            <Download color="primary" sx={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => window.open(`${item.Url}`)} />
                          </Grid>
                        </Grid>
                      </Grid>
                      {(index + 1) !== array.length && (
                        <Divider sx={{
                          '&.MuiDivider-root': { margin: '14px 0 14px auto' },
                          maxWidth: '1340px',
                          width: '100%'
                        }} />
                      )}
                    </>
                  )) : (
                    <></>
                  )}
                  <Divider sx={{
                    '&.MuiDivider-root': { margin: '46px 0 10px auto' },
                    maxWidth: '1340px',
                    width: '100%'
                  }} />
                  {product.Packshots && (
                    <>
                      <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: '23px', cursor: 'pointer' }} onClick={() => setOpenPackshots(!openPackshots)}>
                        <Grid item xs={3}>
                          <Grid sx={{ width: '320px', marginLeft: 'auto' }}>
                            <HeaderSix style={{ margin: 0 }}>Packshots</HeaderSix>
                          </Grid>
                        </Grid>
                        <Grid xs={9} item>
                          <Grid container justifyContent="flex-end" sx={{ maxWidth: '976px', width: '100%', marginLeft: 'auto' }}>
                            <ArrowDropDownIcon />
                          </Grid>
                        </Grid>
                      </Grid>
                      {openPackshots ? (
                        <>
                          <Grid container direction="row" alignItems="center">
                            <Grid item sm={3}>
                              <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                                <SubtitleTwoSecondary>Thumbnail</SubtitleTwoSecondary>
                              </Grid>
                            </Grid>
                            <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                              <Grid container alignItems="center" sx={{ maxWidth: '976px', width: '100%' }}>
                                <img
                                  src={product.Packshots[0].S}
                                  className="
                                    recent-item__media
                                    thumb
                                  "
                                  style={{
                                    padding: 4,
                                    margin: '4px 0',
                                    height: '56px',
                                    width: '56px',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px',
                                    marginRight: '20px'
                                  }}
                                />
                                <BodyTwoSecondary style={{ color: '#012169', cursor: 'pointer' }} onClick={() => window.open(`${product.Packshots[0].S}`)}>{product.Name}</BodyTwoSecondary>
                                <Download color="primary" sx={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => window.open(`${product.Packshots[0].S}`)} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider sx={{ maxWidth: '1340px', width: '100%', marginLeft: 'auto' }} />
                          <Grid container direction="row" alignItems="center">
                            <Grid item sm={3}>
                              <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                                <SubtitleTwoSecondary>Medium</SubtitleTwoSecondary>
                              </Grid>
                            </Grid>
                            <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                              <Grid container alignItems="center" sx={{ maxWidth: '976px', width: '100%' }}>
                                <img
                                  src={product.Packshots[0].M}
                                  className="
                                    recent-item__media
                                    thumb
                                  "
                                  style={{
                                    padding: 4,
                                    margin: '4px 0',
                                    height: '56px',
                                    width: '56px',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px',
                                    marginRight: '20px'
                                  }}
                                />
                                <BodyTwoSecondary style={{ color: '#012169', cursor: 'pointer' }}>{product.Name}</BodyTwoSecondary>
                                <Download color="primary" sx={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => window.open(`${product.Packshots[0].M}`)} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider sx={{ maxWidth: '1340px', width: '100%', marginLeft: 'auto' }} />
                          <Grid container direction="row" alignItems="center">
                            <Grid item sm={3}>
                              <Grid sx={{ maxWidth: '320px', width: '100%', marginLeft: 'auto' }}>
                                <SubtitleTwoSecondary>Large</SubtitleTwoSecondary>
                              </Grid>
                            </Grid>
                            <Grid xs={12} sm={9} item sx={{ padding: { xs: '26px 0 0', sm: '0 0 0 64px' } }}>
                              <Grid container alignItems="center" sx={{ maxWidth: '976px', width: '100%' }}>
                                <img
                                  src={product.Packshots[0].L}
                                  className="
                                    recent-item__media
                                    thumb
                                  "
                                  style={{
                                    padding: 4,
                                    margin: '4px 0',
                                    height: '56px',
                                    width: '56px',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px',
                                    marginRight: '20px'
                                  }}
                                />
                                <BodyTwoSecondary style={{ color: '#012169', cursor: 'pointer' }}>{product.Name}</BodyTwoSecondary>
                                <Download color="primary" sx={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => window.open(`${product.Packshots[0].L}`)} />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{
            background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
              <Footer />
            </Grid>
          </Grid>
        </>
      ) : loading ? (
        <div className="progress-spinner-wrapper">
          <CircularProgress size={32} />
        </div>
      ) : (
        <></>
      )}
    </Dashboard>
  );
}

const styles: IStyles = {
  imageContainer: {
    aspectRatio: '1/1',
    maxHeight: '320px',
    maxWidth: '320px',
    width: '100%',
    objectFit: 'contain',
    marginLeft: 'auto'
  }
};
