import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';

import { SignInFormTypes, SIGNIN_FORM } from '../forms/signin';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { GetUserInfoRequest, SignInUserRequest } from '../service/userService';
import { GetAllAdminServicesRequest, GetAllServicesNoPublicRequest, GetAvailableServices, GetPendingInvitationCount, GetUserMarketing } from '../service/serviceService';
import { gaService } from '../service/gaService';

import { Footer } from '../components-organisms/Footer';
import { FormControlInput } from '../components-molecules/FormControl';
import { FormCard } from '../components-molecules/cards/FormCard';
import { BodyTwoSecondary, HeaderSix } from '../components-atoms/TypographyComponents';
import { ButtonContainedPrimary, ButtonRouteTextPrimary } from '../components-atoms/ButtonComponents';
import { ProfileSetup } from './ProfileSetup';
import { STORE } from '../redux/store';
import { isBrandAssetFilterApplied } from '../utils/getBrandAssetFilters';
import { DEFAULT_BRAND_ASSET_SORT } from '../constants/digitalAssets';

export function SignIn(): JSX.Element {
  const { control, handleSubmit, setError } = useForm({ mode: 'all', reValidateMode: 'onBlur' });
  const dispatch = useTypedDispatch();
  const location = useLocation();
  const [token, user] = useTypedSelector(state => [state.userReducer.token, state.userReducer.data]);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigateTo = (userData: UserProperties): void => {
    if (location.state?.from)
      navigate(location.state.from.pathname + location.state.from.search);
    else if (userData.IsAdmin) {
      if (userData.IsTerritoryManager || userData.IsSalesManager) navigate('/welcome', { state: { showTutorial: !userData.DontShowTutorial } });
      else navigate('/welcome');
    }
    else navigate('/', { state: { showTutorial: !userData.DontShowTutorial } });
  };
  const [toSetUp, setToSetUp] = useState(false);
  const [userDetails, setUserDetails] = useState({ userId: '' as GUID, isSetUp: false, merchantId: '' as GUID });

  useEffect(() => {
    gaService.pageView('Sign In');
  }, []);

  useEffect(() => {
    if (token) {
      if (user && user.IsAdmin && (!user.IsTerritoryManager || !user.IsSalesManager)) {
        navigate('/admin');
      } else {
        navigate('/');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  const getUserInfo = (token: string): void => {
    GetUserInfoRequest(
      token,
      (response) => {
        dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: { token: token, data: response.Result } });
        const state = STORE.getState();
        STORE.dispatch({ type: 'SET_DIGITAL_ASSET_SEARCH_AND_SORT', payload: { search: '', sortType: DEFAULT_BRAND_ASSET_SORT } });
        if (state.digitalAssetReducer.search?.length > 0 || isBrandAssetFilterApplied(state.digitalAssetReducer.filter) || state.digitalAssetReducer.top > 0) {
          STORE.dispatch({ type: 'RESET_DIGITAL_ASSET_FILTER' });
          STORE.dispatch({ type: 'SET_DIGITAL_ASSET_TOP', payload: { top: 0, shouldScroll: false } });
        }
        if (!response.Result.IsSetUp || (response.Result.IsMerchantManager && !response.Result.IsMerchantSetUp)) {
          setToSetUp(true);
          setUserDetails({ userId: response.Result.Id, isSetUp: response.Result.IsSetUp, merchantId: response.Result.MerchantId });
          setLoading(false);
          return;
        }
        if (response.Result.IsAdmin && !response.Result.IsTerritoryManager && !response.Result.IsSalesManager) {
          Promise.all([
            new Promise(res => GetAllServicesNoPublicRequest(
              token,
              (resp) => {
                res(0);
                dispatch({ type: 'DRAWER_SET_SERVICE_LIST', payload: { services: resp.Result } });
              },
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              () => {}
            )),
            new Promise(res => GetAllAdminServicesRequest(
              token,
              (resp) => {
                res(0);
                dispatch({ type: 'DRAWER_SET_ADMIN_SERVICE_LIST', payload: { adminServices: resp.Result } });
              },
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              () => {}
            ))
          ]).then(() => {
            setLoading(false);
            navigateTo(response.Result);
            return;
          });
        }
        if (!response.Result.IsAdmin) {
          GetAvailableServices(
            token,
            (res) => {
              dispatch({ type: 'USER_SET_SERVICES', payload: { services: res.Result } });
              GetUserMarketing(
                token,
                (resp) => {
                  dispatch({ type: 'USER_SET_MARKETING', payload: { marketing: resp.Result } });
                  setLoading(false);
                  navigateTo(response.Result);
                  return;
                },
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                () => {}
              );
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
          );
        }
        setLoading(false);
        navigateTo(response.Result);
      },
      (error) => {
        setLoading(false);
        if (error.response) dispatch({ type: 'USER_ANY_FAILURE', payload: { error: error.response.data.Error } });
      }
    );

    GetPendingInvitationCount(
      token,
      (res) => {
        dispatch({ type: 'DRAWER_SET_PENDING_INVITATION_COUNT', payload: { invitationCount: res.Result.Count } });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  

  };

  const signin: SubmitHandler<SignInFormTypes> = (data): void => {
    setLoading(true);
    SignInUserRequest(
      { Email: data.email, Password: data.password },
      (response) => {
        getUserInfo(response.Result);
        return { type: 'USER_SET_TOKEN', payload: { token: response.Result } };
      },
      (error) => {
        setLoading(false);
        if (error.response?.data.Error) {
          setError('email', {});
          setError('password', { message: error.response?.data.Error });
        }
        if (error.response) dispatch({ type: 'USER_ANY_FAILURE', payload: { error: error.response.data.Error } });
      },
    );
  };

  return (
    toSetUp
      ? <ProfileSetup isSetUp={userDetails.isSetUp} userId={userDetails.userId} merchantId={userDetails.merchantId} />
      : <Grid container flexDirection="column" sx={{ maxWidth: '1408px', margin: '0 auto' }}>
        <FormCard
          formId="customer-login-form"
          formName="customer-login-form"
          /* leftButton={<ButtonRouteTextPrimary data-testid="forgot-password-link" fullWidth sx={{ my: 1 }} to="/forgot">Forgot password?</ButtonRouteTextPrimary>} */
          leftButton={<ButtonRouteTextPrimary  fullWidth sx={{ my: 1 }} to="/get-invited">No account yet? Get invited.</ButtonRouteTextPrimary>}
          rightButton={
            <ButtonContainedPrimary
              type="submit"
              fullWidth
              id="send"
              name="send"
              sx={{ my: 1 }}
              disabled={loading}>
              {loading ? (
                <CircularProgress
                  color='inherit'
                  size={18}
                  sx={{ marginRight: 2 }}
                />
              ) : (
                <></>
              )}Sign In</ButtonContainedPrimary>
          }
          submit={handleSubmit(signin)}
        >
          <Grid item sx={{ flexGrow: 1 }}>
            <HeaderSix data-testid="sign-in-page">Sign In</HeaderSix>
            <BodyTwoSecondary sx={{ margin: '0 0 8px' }}>Enter your email and password below to sign in.</BodyTwoSecondary>
          </Grid>
          <Fragment>
            {SIGNIN_FORM.map((field, index) => {
              return (
                <Grid key={index} item sx={{ flexGrow: 1, width: '100%' }}>
                  <FormControlInput
                    control={control}
                    field={field}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                </Grid>
              );
            })}
            <ButtonRouteTextPrimary data-testid="forgot-password-link" fullWidth sx={{ my: 1 }} to="/forgot">Forgot password?</ButtonRouteTextPrimary>
          </Fragment>
        </FormCard>
        <Footer hideLinks/>
      </Grid> 
  );
}
