import { EMAIL_VALIDATION, PHONE_VALIDATION_V2, REQUIRED_VALIDATION, validateWhiteSpace } from './_predefinedValidations';

export type UserFormTypes = {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  role: UserRole,
  marketing: boolean,
};

type UserForm = [
  { title: 'User details', fields: [FormInputField, FormInputField, FormInputField, FormInputField]; }
];

export const ADD_USER_FORM: UserForm = [
  {
    title: 'User details',
    fields: [
      {
        label: 'First name',
        name: 'firstName',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'Last name',
        name: 'lastName',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'Email',
        name: 'email',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: EMAIL_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'Phone number',
        name: 'phone',
        inputType: 'text',
        validation: {
          pattern: PHONE_VALIDATION_V2
        }
      },
    ]
  }
];
