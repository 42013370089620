import { CircularProgress, Grid, IconButton } from '@mui/material';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { CaptionSecondary, SubtitleOnePrimary, SubtitleTwoPrimary } from '../components-atoms/TypographyComponents';
import ClearIcon from '@mui/icons-material/Clear';
import '../scss/components/_search-dropdown.scss';
import { LIGHT_THEME } from '../constants/theme';
import { AddRecentSearchRequest, SearchHomepageRequest } from '../service/serviceService';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { useNavigate } from 'react-router-dom';
import MainSearchInputWithDebounce from '../components-molecules/MainSearchInputWithDebounce';

interface IProps {
  categoryId: string;
  serviceId: string;
}

export function SearchWithSuggestion({ categoryId, serviceId }: IProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [menuLoading, setMenuLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [totalResults, setTotalResults] = useState<number>(0);
  const lastPromise = useRef<Promise<any>>();
  const [summaryResults, setSummaryResults] = useState<IResult>({
    Articles: { List: [], Total: 0 },
    Colours: { List: [], Total: 0 },
    Products: { List: [], Total: 0 },
    services: [],
    serviceContents: []
  });
  const [query, setQuery] = useState<string>('');
  const [tempQuery, setTempQuery] = useState('');
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const popupRef = createRef<any>();

  const getOffsetHeight = (element: any) => {
    if (!element?.getClientRects().length) {
      return 0;
    }

    const rect = element.getBoundingClientRect();
    const win = element.ownerDocument.defaultView;
    return rect.top + win.pageYOffset - window.scrollY;   
  };

  const height = getOffsetHeight(document?.getElementById('search-input'));

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const onClear = () => {
    setQuery('');
    setTempQuery('');
    setIsOpen(false);
    setSummaryResults({
      Articles: { List: [], Total: 0 },
      Colours: { List: [], Total: 0 },
      Products: { List: [], Total: 0 },
      services: [],
      serviceContents: []
    });
    dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: '' } });
  };

  const searchResults = useCallback((t: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (query && query.length > 2) {
        setMenuLoading(true);
        SearchHomepageRequest(
          t,
          {
            Query: query,
            Size: 5,
            Page: 0
          },
          (response) => {
            resolve(response);
          },
          (error) => {
            setMenuLoading(false);
            reject(error);
          }
        );
      } else {
        reject();
      }
    });
  }, [query]);

  const addRecentSearch = useCallback(() => {
    if (token) {
      AddRecentSearchRequest(
        token,
        {
          Query: query
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [query, token]);

  useEffect(() => {
    if (token && query) {
      const p = searchResults(token);
      lastPromise.current = p;
      p.then(response => {
        if (p === lastPromise.current) {
          setMenuLoading(false);
          if (response.Result.KnowledgeBase) {
            const results = response.Result.KnowledgeBase;
            setSummaryResults({
              ...results,
              services: response.Result.Services,
              serviceContents: response.Result.ServiceContents
            });
            setTotalResults(results.Articles.Total + results.Colours.Total + results.Products.Total + response.Result.ServiceContents.length + response.Result.Services.length);
          } else {
            setSummaryResults({
              Articles: { List: [], Total: 0 },
              Colours: { List: [], Total: 0 },
              Products: { List: [], Total: 0 },
              services: response.Result.Services,
              serviceContents: response.Result.ServiceContents
            });
            setTotalResults(response.Result.ServiceContents.length + response.Result.Services.length);
          }
          setIsOpen(true);
        }
      });
    }
  }, [token, searchResults, query]);

  const getServiceImageProps = (serivceName: string) => {
    let imageName;
    let imageWidth;

    switch (serivceName) {
      case 'Industry Insights':
        imageName = 'campaign';
        imageWidth = '25px';
        break;
      case 'Brand Assets':
        imageName = 'brandasset';
        imageWidth = '20px';
        break;
      case 'Industry Events':
        imageName = 'industryevent';
        imageWidth = '20px';
        break;
      default:
        imageName = 'science';
        imageWidth = '20px';
    }

    return { imageName, imageWidth };
  };

  return (
    <>
      <MainSearchInputWithDebounce
        placeholder="Search"
        value={tempQuery}
        onChange={text => {
          setTempQuery(text);
        }}
        onChangeWithDebounce={text => {
          setQuery(text);
          dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: text } });
        }}
      />

      <div className="search-results-container">
        {menuLoading && (
          <div className="progress-spinner-wrapper progress-spinner-wrapper--search">
            <CircularProgress size={32} />
          </div>
        )}
        {isOpen ? (
          <div
            ref={popupRef}
            className="search-dropdown"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                navigate('/searchresults', {
                  state: {
                    from: 'home',
                    categoryId,
                    serviceId
                  }
                });
                addRecentSearch();
              }
            }}
          >
            <MainSearchInputWithDebounce
              placeholder="Search"
              value={tempQuery}
              onChange={text => {
                setTempQuery(text);
              }}
              onChangeWithDebounce={text => {
                setQuery(text);
                dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: text } });
              }}
              onBlur={() => {
                setIsOpen(false);
              }}
              endAdornment={query && (
                <IconButton data-testid="search-clear-button" onClick={onClear}>
                  <ClearIcon fontSize='small' />
                </IconButton>
              )}
            />
            
            <div className="search-dropdown-subheader">
              <CaptionSecondary className="search-dropdown-subheader__title">
                {totalResults} Results Found
              </CaptionSecondary>
              {totalResults > 0 ? (
                <a
                  className="search-dropdown-subheader__action link link--small"
                  role="link"
                  onClick={() => navigate('/searchresults', {
                    state: {
                      from: 'home',
                      categoryId,
                      serviceId
                    }
                  })}
                >
                  See All
                </a>
              ) : (
                <></>
              )}
            </div>
            <div className="search-dropdown-content" style={{ height: '100%', maxHeight: `calc(100vh - (${height}px + 138px))`}}>
              {totalResults === 0 ? (
                <div
                  className="search-dropdown-no-results">
                  <SubtitleOnePrimary className="search-dropdown-no-results__title subtitle-1">
                    Not finding what you’re looking for?&nbsp;
                  </SubtitleOnePrimary>
                  <CaptionSecondary className="search-dropdown-no-results__text body-2">
                    <a role="link" style={{ color: LIGHT_THEME.palette.primary.main }}>
                      Contact us
                    </a>
                    , we’ll help you find it.
                  </CaptionSecondary>
                </div>
              ) : (
                <>
                  {summaryResults.services.length > 0 && (
                    <section
                      className="recentt-items recentt-items--compact recentt-items--benefits"
                      style={{
                        background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(247, 247, 247, 0.97) 100%)',
                        paddingTop: '12px',
                        paddingBottom: '28px'
                      }}
                    >
                      <div className="recentt-items__title-showing" style={{ padding: '16px 0 8px', marginBottom: 0 }}>
                        <SubtitleOnePrimary>Services</SubtitleOnePrimary>
                        <CaptionSecondary className="recentt-items__title-showing-number">
                          Showing {summaryResults.services.length}/{summaryResults.services.length}
                        </CaptionSecondary>
                      </div>
                      <Grid container item>
                        {summaryResults.services.map((benefit, idx) => (
                          <Grid key={idx} item xs={3} className="recentt-item__benefit">
                            <a
                              onClick={() => {
                                navigate(`/service/${benefit.ServiceId}`);
                                addRecentSearch();
                              }
                              }
                              className="recentt-item recentt-item--link recentt-item--benefit">
                              <img
                                srcSet={`
                                  ${require(`../assets/img/category/${benefit.ImageUrl}.png`)} 1x, 
                                  ${require(`../assets/img/category/${benefit.ImageUrl}@2x.png`)} 2x,
                                  ${require(`../assets/img/category/${benefit.ImageUrl}@3x.png`)} 3x
                                `}
                                src={require(`../assets/img/category/${benefit.ImageUrl}@2x.png`)}
                                alt={`${benefit.CategoryName} logo`}
                                className="recentt-item__media thumb thumb--2xsmall"
                                style={{ height: '48px', width: '48px' }}
                              />
                              <div className="recentt-item__content">
                                <CaptionSecondary className="recentt-item__caption u-text-truncate">
                                  {benefit.CategoryName}
                                </CaptionSecondary>
                                <SubtitleTwoPrimary className="recentt-item__subtitle-2 u-text-truncate">
                                  {benefit.ServiceName}
                                </SubtitleTwoPrimary>
                              </div>
                            </a>
                          </Grid>
                        ))}
                      </Grid>
                    </section>
                  )}
                  {summaryResults.Articles.List.length > 0 && (
                    <section className="recentt-items recentt-items--compact">
                      <div className="recentt-items__title-showing">
                        <SubtitleOnePrimary className="subtitle-1 subtitle-1--dark">Articles</SubtitleOnePrimary>
                        <CaptionSecondary className="recentt-items__title-showing-number">
                          Showing {summaryResults.Articles.List.length}/{summaryResults.Articles.Total}
                        </CaptionSecondary>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {summaryResults.Articles.List.map(item => (
                            <div key={item.Id} className="recentt-item__divider">
                              <a
                                className="recentt-item recentt-item--link recentt-item--compact"
                                onClick={() => {
                                  navigate(`/detail/article/${item.Id}`, {
                                    state: {
                                      categoryId,
                                      serviceId
                                    }
                                  });
                                  addRecentSearch();
                                }}
                              >
                                <img
                                  srcSet={`
                                    ${require('../assets/img/search/article.png')} 1x,
                                    ${require('../assets/img/search/article@2x.png')} 2x,
                                    ${require('../assets/img/search/article@3x.png')} 3x
                                  `}
                                  src={require('../assets/img/search/article@2x.png')}
                                  alt="article"
                                  style={{ width: '16px', height: '20px' }}
                                  className="recentt-item__media thumb thumb--2xsmall"
                                />
                                <div className="recentt-item__content content__divider">
                                  <SubtitleTwoPrimary className="recentt-item__subtitle-2 ">
                                    {item.Title}
                                  </SubtitleTwoPrimary>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section >
                  )}
                  {summaryResults.Products.List.length > 0 && (
                    <section className="recentt-items recentt-items--compact">
                      <div className="recentt-items__title-showing">
                        <SubtitleOnePrimary className="subtitle-1 subtitle-1--dark">Products</SubtitleOnePrimary>
                        <CaptionSecondary className="recentt-items__title-showing-number">
                          Showing {summaryResults.Products.List.length}/{summaryResults.Products.Total}
                        </CaptionSecondary>
                      </div>
                      <div className="row">
                        <div className="col-12" >
                          {summaryResults.Products.List.map(item => (
                            <div key={item.RemoteId} className="recentt-item__divider">
                              <a
                                className="recentt-item recentt-item--link recentt-item--compact"
                                onClick={() => {
                                  navigate(`/detail/product/${item.RemoteId}`, {
                                    state: {
                                      categoryId,
                                      serviceId
                                    }
                                  });
                                  addRecentSearch();
                                }}
                              >
                                <img
                                  srcSet={`
                                    ${require('../assets/img/search/product.png')} 1x,
                                    ${require('../assets/img/search/product@2x.png')} 2x,
                                    ${require('../assets/img/search/product@3x.png')} 3x
                                  `}
                                  src={require('../assets/img/search/product@2x.png')}
                                  style={{ width: '17px', height: '20px' }}
                                  alt="product"
                                  className="recentt-item__media thumb thumb--2xsmall"
                                />
                                <div className="recentt-item__content content__divider">
                                  <SubtitleTwoPrimary className="recentt-item__subtitle-2 ">
                                    {item.Name}
                                  </SubtitleTwoPrimary>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div >
                      </div >
                    </section >
                  )}
                  {summaryResults.Colours.List.length > 0 && (
                    <section className="recentt-items recentt-items--compact">
                      <div className="recentt-items__title-showing">
                        <SubtitleOnePrimary className="subtitle-1 subtitle-1--dark">Colours</SubtitleOnePrimary>
                        <CaptionSecondary className="recentt-items__title-showing-number">
                          Showing {summaryResults.Colours.List.length}/{summaryResults.Colours.Total}
                        </CaptionSecondary>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {summaryResults.Colours.List.map(item => (
                            <a
                              className="recentt-item recentt-item--link recentt-item--compact"
                              key={item.Id}
                              onClick={() => {
                                navigate(`/detail/colour/${item.Id}`, {
                                  state: {
                                    categoryId,
                                    serviceId
                                  }
                                });
                                addRecentSearch();
                              }}
                            >
                              <span
                                className="recentt-item__media thumb thumb--4xsmall thumb--swatch-plain"
                                style={{
                                  backgroundColor: '#' + item.Rgb
                                }}
                              ></span>
                              <div className="recentt-item__content content__divider">
                                <SubtitleTwoPrimary className="recentt-item__subtitle-2 ">
                                  {item.Name}
                                </SubtitleTwoPrimary>
                              </div>
                            </a >
                          ))}
                        </div>
                      </div>
                    </section>
                  )}
                  {summaryResults.serviceContents.length > 0 && summaryResults.serviceContents.map((serviceContent: any) => {
                    const { imageName, imageWidth } = getServiceImageProps(serviceContent.GroupName);
                    const openIndustryInsigtsDetailPage = serviceContent.GroupName === 'Industry Insights';
                    const openIndustryEventsDetailPage = serviceContent.GroupName === 'Industry Events';
                    const openDetailPage = serviceContent.GroupName === 'Digital Toolkit';
                    const openBrandAssetDetailPage = serviceContent.GroupName === 'Brand Assets';
                    const brandAssetsSeeMore = serviceContent.GroupName === 'Brand Assets';   
                    return (
                      <section
                        key={serviceContent.GroupName}
                        className="recentt-items recentt-items--compact"
                      >
                        <div className="recentt-items__title-showing">
                          <SubtitleOnePrimary className="subtitle-1 subtitle-1--dark">
                            {serviceContent.GroupName}
                          </SubtitleOnePrimary>
                          <CaptionSecondary className="recentt-items__title-showing-number">
                            Showing {serviceContent.Service.length}/
                            {serviceContent.Service.length}
                          </CaptionSecondary>
                          {brandAssetsSeeMore && (
                            <a
                              className="search-dropdown-subheader__action link link--small"
                              role="link"
                              style={{ marginLeft: 'auto' }}
                              onClick={() => navigate(`/service/${serviceContent.Service[0].ServiceId}?query=${query}`, {
                                state: {
                                  from: 'home',
                                  categoryId,
                                  serviceId
                                }
                              })}
                            >
                              See More
                            </a>
                          )}
                        </div>                        
                        <div className="row">
                          <div className="col-12">
                            {serviceContent.Service.map((service: any) => (
                              <div
                                key={service.Id}
                                className="recentt-item__divider"
                              >
                                <a
                                  className="recentt-item recentt-item--link recentt-item--compact"
                                  onClick={() => {
                                    if (openIndustryInsigtsDetailPage) {
                                      navigate(`/detail/industryinsight/${service.Id}`);
                                    } else if (openDetailPage) {
                                      navigate(`/detail/toolkit/${service.Id}`);
                                    } else if (openBrandAssetDetailPage) {
                                      navigate(`/asset-detail/${service.Id}`);
                                    } else if (openIndustryEventsDetailPage) {
                                      navigate(`/detail/industry-event/${service.Id}`);
                                    } else {
                                      navigate(`/service/${service.ServiceId}`);
                                    }
                                  }}
                                >
                                  <img
                                    srcSet={`
                                      ${require(`../assets/img/search/${imageName}.png`)} 1x, 
                                      ${require(`../assets/img/search/${imageName}@2x.png`)} 2x,
                                      ${require(`../assets/img/search/${imageName}@3x.png`)} 3x
                                    `}
                                    src={require(`../assets/img/search/${imageName}@2x.png`)}
                                    style={{
                                      height: '20px',
                                      width: `${imageWidth}`,
                                    }}
                                    alt="Service Content icon"
                                    className="recentt-item__media thumb thumb--2xsmall"
                                  />
                                  <div className="recentt-item__content content__divider">
                                    <SubtitleTwoPrimary className="recentt-item__subtitle-2 ">
                                      {service.Title}
                                    </SubtitleTwoPrimary>
                                  </div>
                                </a>
                              </div>                              
                            ))}
                          </div>
                        </div>                        
                      </section>
                    );
                  })}
                  <div className="search-dropdown-need-help">
                    <CaptionSecondary className="search-dropdown-need-help__text body-2">
                      Need help with your search?&nbsp;
                      <a href="#" role="link" style={{ color: LIGHT_THEME.palette.primary.main, textDecoration: 'none' }}>
                        Contact us
                      </a>
                      , we’ll help you find it.
                    </CaptionSecondary>
                  </div>
                </>
              )}
            </div>
          </div >
        ) : (
          <></>
        )}
      </div >
    </>
  );
}
