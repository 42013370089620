/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useMemo, useState } from 'react';

import { Grid, IconButton, CircularProgress, Box, Stack, Divider } from '@mui/material';
import Add from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SearchIcon from '@mui/icons-material/Search';

import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { MiniCard, MiniDialog } from '../../components-molecules/dialogs/MiniDialog';
import { ListDivider } from '../../components-molecules/Divider';
import { SortButton } from '../../components-molecules/SortButton';

import { ButtonContainedPrimary, ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { InfoComponent } from '../../components-atoms/InfoComponent';
import { BodyOnePrimary, CaptionSecondary } from '../../components-atoms/TypographyComponents';

import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { GetShareMerchants, ThreadMerchant, ThreadMerchantEmployee, MerchantGroup, GetShareMerchantEmployees, GetMerchantsEmployeeResponse } from '../../service/serviceService';
import { IStyles, LIGHT_THEME } from '../../constants/theme';
import { SearchbarWithDeboubce } from '../../components-molecules/SearchBarWithDebounce';
import { AZ_SORT } from '../../utils/sortTypes';
import imageIcon from '../../assets/img/merchant_placeholder.jpg';

interface IShareContentRecipientDialogProps {
  title?: string;
  onChange: (ids: string[]) => void;
}

export function ShareContentRecipientDialog({ title, onChange }: IShareContentRecipientDialogProps): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const [dialog, setDialog] = useState(false);
  const [merchants, setMerchants] = useState<MerchantGroup[]>([]);
  const [merchantEmployees, setMerchantEmployees] = useState<GetMerchantsEmployeeResponse[]>([]);
  const [selectedMerchant, setTempMerchants] = useState<ThreadMerchant | null>(null);
  const [tempEmployees, setTempEmployees] = useState<ThreadMerchantEmployee[]>([]);

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sort, setSort] = useState('asc');
  const [merchantListLoading, setMerchantListLoading] = useState(false);
  const [merchantEmployeesLoading, setMerchantEmployeesLoading] = useState(false);
  const [merchantSelected, setMerchantSelected] = useState<ThreadMerchant | null>(null);
  const [recipientsSelected, setRecipientsSelected] = useState<any[]>([]);
  const merchantIndex = useMemo(() => recipientsSelected.findIndex(r => r.MerchantId === selectedMerchant?.Id), [recipientsSelected, selectedMerchant]);

  useEffect(() => {
    if (token) {
      if (!merchantSelected) {
        getMerchantListData();
      } else {
        getMerchantEmployeesData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, searchQuery, sort]);

  const getMerchantListData = () => {
    if (token) {
      setMerchantListLoading(true);
      GetShareMerchants(token, {
        OrderBy: sort,
        Query: searchQuery,
      }, response => {
        setMerchants(response.Result);
        setMerchantListLoading(false);
      }, // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { });
    }
  };
  const getMerchantEmployeesData = () => {
    if (token) {
      setMerchantEmployeesLoading(true);
      GetShareMerchantEmployees(token, {
        OrderBy: sort,
        Query: searchQuery,
        MerchantId: selectedMerchant?.Id || '',
      }, response => {
        setMerchantEmployees(response.Result);
        setMerchantEmployeesLoading(false);
      }, () => {
        setMerchantEmployeesLoading(false);
      });
    }
  };

  const addRecipientsClicked = () => {
    setDialog(true);
    setTempEmployees(recipientsSelected);
  };

  const searchOnChange = (text: string) => {
    setSearchQuery(text);
  };

  const nextClicked = () => {
    setMerchantSelected(selectedMerchant);
    setShowSearchInput(false);
    setSort('asc');
    setSearchQuery('');
    getMerchantEmployeesData();
  };

  const closeClicked = () => {
    setDialog(false);
    setTempMerchants(null);
    setMerchantSelected(null);
  };

  const removeMerchantFromList = (id: GUID) => {
    const list = recipientsSelected.filter(r => r.MerchantId !== id);
    setRecipientsSelected(list);
    onChange(list);
  };

  const getSelectedMerchant = (merchant: ThreadMerchant) => {
    let isSelected = false;
    if (selectedMerchant?.Id === merchant.Id) {
      isSelected = true;
    }

    return isSelected;
  };

  const submitPressed = () => {
    onChange(recipientsSelected);
    setMerchantSelected(null);
    setTempMerchants(null);
    setTempEmployees([]);

    setSort('asc');
    setSearchQuery('');
    setDialog(false);
    setShowSearchInput(false);
  };

  const saveMerchant = (merchant: ThreadMerchant) => {
    setTempMerchants(selectedMerchant?.Id !== merchant.Id ? merchant : null);
  };

  const saveEmployee = (employee: ThreadMerchantEmployee) => {
    if (merchantIndex > -1) {
      // Merchant exists
      const employeeIndex = recipientsSelected[merchantIndex].Recipients.findIndex((r: any) => r.Id === employee.Id);
      let temp = JSON.parse(JSON.stringify(recipientsSelected));

      if (employeeIndex > -1) {
        temp[merchantIndex].Recipients = temp[merchantIndex].Recipients.filter((r: ThreadMerchant) => r.Id !== employee.Id);

        if(temp[merchantIndex].Recipients.length === 0) {
          temp = temp.filter((m: any) => m.MerchantId !== selectedMerchant?.Id);
        }
      } else {
        temp[merchantIndex].Recipients.push(employee);
      }

      setRecipientsSelected(temp);
    } else {
      // First create
      const item = {
        MerchantId: selectedMerchant?.Id,
        MerchantName: selectedMerchant?.Name,
        MerchantLogo: selectedMerchant?.Logo,
        Recipients: [employee]
      };
      setRecipientsSelected(r => [...r, item]);
    }
  };

  const getSelectedMerchantEmployee = (employee: ThreadMerchantEmployee) => {
    let isSelected = false;

    const merchant = recipientsSelected.find(r => r.MerchantId === selectedMerchant?.Id);
    if (merchant) {
      merchant.Recipients.forEach((x: any) => {
        if (x.Id === employee.Id) {
          isSelected = true;
        }
      });
    }

    tempEmployees?.forEach(x => {
      if (x.Id === employee.Id) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  const handleSelectionAllEmployees = (selection: boolean) => {
    let temp = JSON.parse(JSON.stringify(recipientsSelected));

    if (selection) {
      const employees: ThreadMerchantEmployee[] = [];
      merchantEmployees.forEach(group => group.Users.forEach(x => employees.push(x)));

      if (merchantIndex > -1) {
        temp[merchantIndex].Recipients = employees;
      } else {
        const item = {
          MerchantId: selectedMerchant?.Id,
          MerchantName: selectedMerchant?.Name,
          MerchantLogo: selectedMerchant?.Logo,
          Recipients: employees
        };

        temp.push(item);
      }
    } else {
      temp = temp.filter((t: any) => t.MerchantId != selectedMerchant?.Id);
    }
    setRecipientsSelected(temp);
  };

  const GetMerchantList = () => {
    if (!recipientsSelected) {
      return <></>;
    }
    return (
      <Stack flex={1}>
        {recipientsSelected.map((r, idx) =>
          <>
            <Stack
              key={r.Id}
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              mt={1.5}>
              <Stack flexDirection='row' alignItems='center' gap={1.5}>
                <img
                  width='28px'
                  height='28px'
                  style={{ borderRadius: '4px' }}
                  src={r.MerchantLogo}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = imageIcon;
                  }}
                />
                <Stack>
                  <BodyOnePrimary>{r.MerchantName}</BodyOnePrimary>
                  <CaptionSecondary>{r?.Recipients?.map((re: any) => re.Email).join(', ')}</CaptionSecondary>
                </Stack>
              </Stack>

              <IconButton onClick={() => { removeMerchantFromList(r.MerchantId); }}>
                <RemoveCircleIcon sx={{ color: '#415AA9' }} />
              </IconButton>
            </Stack>

            {recipientsSelected.length - 1 > idx
              && <Divider sx={{ mt: 2 }} />
            }
          </>
        )}
      </Stack>
    );
  };

  const noRecipientSelected = () => {
    return (
      <Box mt={2}>
        <InfoComponent
          title='Email Recipients'
          description="You haven't added any recipients to this email yet. To begin, select a merchant and choose the merchant's employees with whom you'd like to share the selected assets."
        />
      </Box>
    );
  };

  const customBottomButtons = () => {
    return <Box sx={{ height: '32px', margin: '16px', alignSelf: 'end', display: 'flex', justifyContent: 'space-between', width: '99%', paddingLeft: '16px', paddingRight: '8px' }}>
      {
        merchantSelected === null
          ? <>
            <ButtonTextPrimary onClick={closeClicked} data-testid='close-button'>Cancel</ButtonTextPrimary>
            <ButtonTextPrimary onClick={nextClicked} disabled={selectedMerchant === null} data-testid='close-button'>Next</ButtonTextPrimary>
          </>
          : <>
            <ButtonTextPrimary onClick={closeClicked} data-testid='close-button'>Cancel</ButtonTextPrimary>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <ButtonTextPrimary onClick={() => setMerchantSelected(null)} data-testid='close-button'>Back</ButtonTextPrimary>
              <ButtonTextPrimary onClick={submitPressed} data-testid='close-button'>Submit</ButtonTextPrimary>
            </div>
          </>
      }
    </Box>;
  };

  const GetRecipientList = () => {
    if (recipientsSelected.length > 0) {
      return <GetMerchantList />;
    } else {
      return noRecipientSelected();
    }
  };

  const getMerchantList = () => {
    if (merchantListLoading) {
      return <Grid container item justifyContent='center' alignItems='center'>
        <CircularProgress
          color='primary'
          size={50}
          style={{ zIndex: 999, margin: '56px 0' }}
        />
      </Grid>;
    }
    return merchants.map(group => {
      return <>
        <ListDivider
          key={group.Group}
          division={{ name: group.Group, length: group.Merchants.length }}
          caption={{ singular: 'Merchant', plural: 'Merchants' }}
          halfMargin
        >
          {
            group.Merchants.map(merchant => <MiniCard
              key={merchant.Id}
              onClick={() => saveMerchant(merchant)}
              title={`${merchant.Name}`}
              subtitle={merchant.Payercode}
              selected={getSelectedMerchant(merchant)}
              imgUrl={merchant.Logo}
            />)
          }
        </ListDivider>
      </>;
    }
    );
  };

  const getEmployeeList = () => {
    if (merchantEmployeesLoading) {
      return (
        <Grid container item justifyContent='center' alignItems='center' height='340px'>
          <CircularProgress
            color='primary'
            size={50}
            style={{ zIndex: 999, margin: '56px 0' }}
          />
        </Grid>
      );
    }
    return <>
      {
        merchantEmployees.length === 0
          ? <Grid sx={{ width: '100%', height: '340px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <BodyOnePrimary style={{ textAlign: 'center' }}>{searchQuery ? '0 results found.' : 'You have no active users for this merchant yet.'}</BodyOnePrimary>
          </Grid>
          : merchantEmployees.map(group => {
            return <>
              <ListDivider
                key={group.Group}
                division={{ name: group.Group, length: group.Users.length }}
                caption={{ singular: 'Merchant', plural: 'Merchants' }}
                halfMargin
              >
                {
                  group.Users.map(employee => <MiniCard
                    key={employee.Id}
                    onClick={() => saveEmployee(employee)}
                    title={`${employee.FirstName} ${employee.LastName}`}
                    subtitle={employee.Role}
                    secondSubtitle={employee.Email}
                    selected={getSelectedMerchantEmployee(employee)}
                  />)
                }
              </ListDivider>
            </>;
          }
          )
      }
    </>;
  };

  function renderRecipientsSection() {
    return (
      <ModalCardComponent>
        <SectionHeader
          title={title || 'Recipients'}
          button={
            <ButtonContainedPrimary
              data-testid='add-recipients'
              onClick={addRecipientsClicked}
              startIcon={<Add />}>
              Add Recipients
            </ButtonContainedPrimary>
          }
        />
        <Grid item container sx={{ flexGrow: 1 }}>
          <GetRecipientList />
        </Grid>
      </ModalCardComponent>
    );
  }

  function renderModalHeader() {
    return (
      <div style={{
        flexDirection: 'column',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: ' 8px 24px',
        }}>
          <IconButton aria-label='search' sx={styles.sortFilterButton} onClick={() => setShowSearchInput(!showSearchInput)}>
            <SearchIcon color={'primary'} />
          </IconButton>
          <SortButton
            menuItems={
              AZ_SORT.map((sortItem: { title: string; value: string; }) => ({
                title: sortItem.title,
                value: sortItem.value,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                handleSelect: () => { setSort(sortItem.value); },
              }))}
            selectedSortType={sort}
          />
          {
            merchantSelected && <>
              <ButtonTextPrimary onClick={() => handleSelectionAllEmployees(true)}>Select all</ButtonTextPrimary>
              <ButtonTextPrimary onClick={() => handleSelectionAllEmployees(false)} disabled={recipientsSelected?.[merchantIndex]?.Recipients?.length === 0}>Deselect all</ButtonTextPrimary>
            </>
          }
        </div>
        {showSearchInput && (
          <div style={{ padding: ' 0px 24px' }}>
            <SearchbarWithDeboubce
              isOpen
              query={searchQuery}
              placeholder='Search Merchants'
              onCancel={() => { setShowSearchInput(false); setSearchQuery(''); }}
              onChange={searchOnChange}
            />
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      {renderRecipientsSection()}
      <MiniDialog
        title='Select Merchant'
        titleMargin
        open={dialog}
        close={closeClicked}
        customBottomButtons={customBottomButtons}
        childrenSx={{ overflow: 'hidden' }}>
        <>
          {renderModalHeader()}
          <div style={{ width: '600px', marginLeft: '13px', maxHeight: '340px', overflow: 'auto' }}>
            {
              !merchantSelected
                ? getMerchantList()
                : getEmployeeList()
            }
          </div>
        </>
      </MiniDialog>
    </>
  );
}

const styles: IStyles = {
  sortFilterButton: {
    margin: '0 8px 0 0',
    height: '48px',
    width: '48px',
    '&:hover': {
      backgroundColor: LIGHT_THEME.palette.action.selected
    }
  },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
};


export default ShareContentRecipientDialog;
