import { AxiosError } from 'axios';
import { HttpRequestPost } from './genericService';

interface HelpVideoLogRequest {
  Id: string;
  Url: string;
}

interface KnowledgeBaseArticleLogRequest {
  Id: string;
  Title: string;
}

interface KnowledgeBaseProductLogRequest {
  Id: string;
  Name: string;
}

interface KnowledgeBaseColourLogRequest {
  Id: string;
  Name: string;
}

interface ProductUpdateLogRequest {
  Id: string;
  Title: string;
}

interface ProductUpdateDetailLogRequest {
  Id: string;
  Title: string;
}

interface IndustryEventLogRequest {
  Id: string;
  Title: string;
}

interface IndustryInsightDetailLogRequest {
  Id: string;
  Title: string;
}

interface BrandAssetDetailLogRequest {
  Id: string;
  Title: string;
}

interface BrandAssetDownloadAllLogRequest {
  Id: string;
  Title: string;
}

interface FocusOfTheMonthLogRequest {
  Id: string;
  Title: string;
}

interface NewsletterLogRequest {
  Id: string;
  Title: string;
}

interface DigitalToolkitDetailLogRequest {
  Id: string;
  Title: string;
}

interface DocumentLibraryViewLogRequest {
  Id: string;
  Title: string;
}

interface DocumentLibraryAttachmentDownloadLogRequest {
  Id: string;
  FileUrl: string;
}

export function LogHelpVideoView(
  token: string,
  body: HelpVideoLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, HelpVideoLogRequest>(
    '/log/help-video',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogKnowledgeBaseArticleView(
  token: string,
  body: KnowledgeBaseArticleLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, KnowledgeBaseArticleLogRequest>(
    '/log/knowledge-base-article',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogKnowledgeBaseProductView(
  token: string,
  body: KnowledgeBaseProductLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, KnowledgeBaseProductLogRequest>(
    '/log/knowledge-base-product',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogKnowledgeBaseColourView(
  token: string,
  body: KnowledgeBaseColourLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, KnowledgeBaseColourLogRequest>(
    '/log/knowledge-base-colour',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogProductUpdateLearnMore(
  token: string,
  body: ProductUpdateLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, ProductUpdateLogRequest>(
    '/log/product-update',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogProductUpdateDetailView(
  token: string,
  body: ProductUpdateDetailLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, ProductUpdateDetailLogRequest>(
    '/log/product-update-detail',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogIndustryEventWatch(
  token: string,
  body: IndustryEventLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, IndustryEventLogRequest>(
    '/log/industry-event',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogIndustryInsightDetailView(
  token: string,
  body: IndustryInsightDetailLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, IndustryInsightDetailLogRequest>(
    '/log/industry-insight-detail',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogBrandAssetDetailView(
  token: string,
  body: BrandAssetDetailLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, BrandAssetDetailLogRequest>(
    '/log/brand-asset-detail',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogBrandAssetDownloadAll(
  token: string,
  body: BrandAssetDownloadAllLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, BrandAssetDownloadAllLogRequest>(
    '/log/brand-asset-download-all',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogFocusOfTheMonthView(
  token: string,
  body: FocusOfTheMonthLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, FocusOfTheMonthLogRequest>(
    '/log/focus-of-the-month',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogNewsletterView(
  token: string,
  body: NewsletterLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, NewsletterLogRequest>(
    '/log/newsletter',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogDigitalToolkitDetailView(
  token: string,
  body: DigitalToolkitDetailLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, DigitalToolkitDetailLogRequest>(
    '/log/digital-toolkit',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogDocumentLibraryView(
  token: string,
  body: DocumentLibraryViewLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, DocumentLibraryViewLogRequest>(
    '/log/document-library-view',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogDocumentLibraryAttachmentDownload(
  token: string,
  body: DocumentLibraryAttachmentDownloadLogRequest,
  success: (response: ApiResponse<string>) => void,
  failure: (error: AxiosError<ErrorResponse>) => void
): void {
  HttpRequestPost<string, DocumentLibraryAttachmentDownloadLogRequest>(
    '/log/document-library-attachment-download',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}
