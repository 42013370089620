import { ChangeEvent, createRef, useCallback, useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { CircularProgress, FilledInput, IconButton, useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { CaptionSecondary, SubtitleOnePrimary, SubtitleTwoPrimary } from '../components-atoms/TypographyComponents';
import { AddRecentSearchRequest, GetKnowledgeBaseSearchRequest } from '../service/serviceService';
import { LIGHT_THEME } from '../constants/theme';
import '../scss/components/_search-dropdown.scss';

interface IProps {
  categoryId: string;
  serviceId: string;
}

export function KnowledgeBaseSearch({ categoryId, serviceId }: IProps): JSX.Element {
  const [menuLoading, setMenuLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [summaryResult, setSummaryResult] = useState<KnowledgeBaseResult>({
    Articles: { List: [], Total: 0 },
    Colours: { List: [], Total: 0 },
    Products: { List: [], Total: 0 }
  });
  const [query, setQuery] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const popupRef = createRef<any>();
  const token = useTypedSelector(state => state.userReducer.token);
  const timeoutDuration = 500;
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));

  const getOffsetHeight = (element: any) => {
    if (!element?.getClientRects().length) {
      return 0;
    }

    const rect = element.getBoundingClientRect();
    const win = element.ownerDocument.defaultView;
    return rect.top + win.pageYOffset - window.scrollY;   
  };

  const height = getOffsetHeight(document?.getElementById('search-input'));

  const onSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setQuery(event.target.value);
    debounce((e) => {
      setSearchQuery(e.target.value);
      dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: e.target.value } });
    }, timeoutDuration)(event);
  };

  const onClear = () => {
    setQuery('');
    setSearchQuery('');
    dispatch({ type: 'SET_SEARCH_QUERY', payload: { currentData: '' } });
    setIsOpen(false);
  };
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);
  const searchResults = useCallback((token: string) => {
    if (token) {
      if (searchQuery.length > 2) {
        setMenuLoading(true);
        GetKnowledgeBaseSearchRequest(
          token,
          { Size: 5, Page: 0, Query: searchQuery },
          (response) => {
            if (response) {
              console.log(response);
              setSummaryResult(response.Result);
              setTotalResults(response.Result.Articles.Total + response.Result.Colours.Total + response.Result.Products.Total);
              setIsOpen(true);
              setMenuLoading(false);
            }
          },
          (error: any) => { if (error.response) { console.error(error.response.data.Error); setMenuLoading(false); } }
        );
      }
    }
  }, [searchQuery]);
  useEffect(() => {
    if (token && searchQuery) {
      searchResults(token);
    }
  }, [token, searchQuery, searchResults]);
  const addRecentSearch = useCallback(() => {
    if (token) {
      AddRecentSearchRequest(
        token,
        {
          Query: query
        },
        () => { return; },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  }, [query, token]);
  return (
    <>
      <FilledInput
        value={query}
        size="small"
        fullWidth
        data-testid="search-input"
        id="search-input"
        placeholder="Search"
        sx={{
          pr: smallDown ? '12px' : 0,
          mt: -1,
          input: {
            pb: 1.5,
            pt: 2,
          },
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            navigate('/searchresults', {
              state: {
                categoryId,
                serviceId
              }
            });
            addRecentSearch();
          }
        }}
        onChange={e => onSearch(e)} />
      <div className="search-results-container">
        {menuLoading && (
          <div className="progress-spinner-wrapper progress-spinner-wrapper--search">
            <CircularProgress size={32} sx={{ mt: '2px'}} />
          </div>
        )}
        {isOpen ? (
          <div ref={popupRef} className="search-dropdown" >
            <FilledInput
              value={query}
              size="medium"
              fullWidth
              data-testid="search-input"
              placeholder="Search"
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  navigate('/searchresults', {
                    state: {
                      categoryId,
                      serviceId
                    }
                  });
                  addRecentSearch();
                }
              }}
              onChange={e => onSearch(e)}
              onBlur={() => setIsOpen(false)}
              endAdornment={query && (
                <IconButton data-testid="search-clear-button" onClick={onClear}>
                  <ClearIcon fontSize='small' />
                </IconButton>
              )}
            />
            <div className="search-dropdown-subheader">
              <CaptionSecondary className="search-dropdown-subheader__title">{totalResults} Results Found</CaptionSecondary>
              {totalResults > 0
                ? <a
                  className="search-dropdown-subheader__action link link--small"
                  role="link"
                  onClick={() => {
                    navigate('/searchresults', {
                      state: {
                        categoryId,
                        serviceId
                      }
                    });
                    addRecentSearch();
                  }}
                >
                  See All
                </a>
                : <></>}
            </div>
            <div className="search-dropdown-content" style={{ height: '100%', maxHeight: `calc(100vh - (${height}px + 138px))`}}>
              {totalResults === 0 ? (
                <div
                  className="search-dropdown-no-results">
                  <SubtitleOnePrimary className="search-dropdown-no-results__title subtitle-1">
                    Not finding what you’re looking for?&nbsp;
                  </SubtitleOnePrimary>
                  <CaptionSecondary className="search-dropdown-no-results__text body-2">
                    <a role="link" style={{ color: LIGHT_THEME.palette.primary.main }}>
                      Contact us
                    </a>
                    , we’ll help you find it.
                  </CaptionSecondary>
                </div>
              ) : (
                <>
                  {summaryResult.Articles.List.length > 0 && (
                    <section className="recentt-items recentt-items--compact">
                      <div className="recentt-items__title-showing">
                        <SubtitleOnePrimary className="subtitle-1 subtitle-1--dark">Articles</SubtitleOnePrimary>
                        <CaptionSecondary className="recentt-items__title-showing-number">
                          Showing {summaryResult.Articles.List.length}/{summaryResult.Articles.Total}
                        </CaptionSecondary>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {summaryResult.Articles.List.map((article: ArticleType) => (
                            <div key={article.Id} className="recentt-item__divider">
                              <a
                                className="recentt-item recentt-item--link recentt-item--compact"
                                onClick={() => {
                                  navigate(`/detail/article/${article.Id}`, {
                                    state: {
                                      categoryId,
                                      serviceId
                                    }
                                  });
                                  addRecentSearch();
                                }}
                              >
                                <img
                                  srcSet={`
                                    ${require('../assets/img/search/article.png')} 1x, 
                                    ${require('../assets/img/search/article@2x.png')} 2x,
                                    ${require('../assets/img/search/article@3x.png')} 3x
                                  `}
                                  src={require('../assets/img/search/article@2x.png')}
                                  alt="article"
                                  style={{ width: '16px', height: '20px' }}
                                  className="recentt-item__media thumb thumb--2xsmall"
                                />
                                <div className="recentt-item__content content__divider">
                                  <SubtitleTwoPrimary className="recentt-item__subtitle-2 ">
                                    {article.Title}
                                  </SubtitleTwoPrimary>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section >
                  )}
                  {summaryResult.Products.List.length > 0 && (
                    <section className="recentt-items recentt-items--compact">
                      <div className="recentt-items__title-showing">
                        <SubtitleOnePrimary className="subtitle-1 subtitle-1--dark">Products</SubtitleOnePrimary>
                        <CaptionSecondary className="recentt-items__title-showing-number">
                          Showing {summaryResult.Products.List.length}/{summaryResult.Products.Total}
                        </CaptionSecondary>
                      </div>
                      <div className="row">
                        <div className="col-12" >
                          {summaryResult.Products.List.map((product: ProductType) => (
                            <div key={product.RemoteId} className="recentt-item__divider">
                              <a
                                className="recentt-item recentt-item--link recentt-item--compact"
                                onClick={() => {
                                  navigate(`/detail/product/${product.RemoteId}`, {
                                    state: {
                                      categoryId,
                                      serviceId
                                    }
                                  });
                                  addRecentSearch();
                                }}
                              >
                                <img
                                  srcSet={`
                                    ${require('../assets/img/search/product.png')} 1x, 
                                    ${require('../assets/img/search/product@2x.png')} 2x,
                                    ${require('../assets/img/search/product@3x.png')} 3x
                                  `}
                                  src={require('../assets/img/search/product@2x.png')}
                                  style={{ width: '17px', height: '20px' }}
                                  alt="product"
                                  className="recentt-item__media thumb thumb--2xsmall"
                                />
                                <div className="recentt-item__content content__divider">
                                  <SubtitleTwoPrimary className="recentt-item__subtitle-2 ">
                                    {product.Name}
                                  </SubtitleTwoPrimary>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div >
                      </div >
                    </section >
                  )}
                  {summaryResult.Colours.List.length > 0 && (
                    <section className="recentt-items recentt-items--compact">
                      <div className="recentt-items__title-showing">
                        <SubtitleOnePrimary className="subtitle-1 subtitle-1--dark">Colours</SubtitleOnePrimary>
                        <CaptionSecondary className="recentt-items__title-showing-number">
                          Showing {summaryResult.Colours.List.length}/{summaryResult.Colours.Total}
                        </CaptionSecondary>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {summaryResult.Colours.List.map((colour: ColourType) => (
                            <a
                              key={colour.Id}
                              onClick={() => {
                                navigate(`/detail/colour/${colour.Id}`, {
                                  state: {
                                    categoryId,
                                    serviceId
                                  }
                                });
                                addRecentSearch();
                              }}
                              className="recentt-item recentt-item--link recentt-item--compact"
                            >
                              <span
                                className="recentt-item__media thumb thumb--4xsmall thumb--swatch-plain"
                                style={{
                                  backgroundColor: colour.Rgb ? '#' + colour.Rgb : ''
                                }}
                              ></span>
                              <div className="recentt-item__content content__divider">
                                <SubtitleTwoPrimary className="recentt-item__subtitle-2">
                                  {colour.Name}
                                </SubtitleTwoPrimary>
                              </div>
                            </a >
                          ))}
                        </div>
                      </div>
                    </section >
                  )}
                  <div className="search-dropdown-need-help">
                    <CaptionSecondary className="search-dropdown-need-help__text body-2">
                      Need help with your search?&nbsp;
                      <a href="#" role="link" style={{ color: LIGHT_THEME.palette.primary.main, textDecoration: 'none' }}>
                        Contact us
                      </a>
                      , we’ll help you find it.
                    </CaptionSecondary>
                  </div>
                </>
              )}
            </div>
          </div >
        ) : (
          <></>
        )}
      </div >
    </>
  );
}
