import { AxiosError } from 'axios';

import { HttpRequestGet, HttpRequestPost } from './genericService';

export interface Notification {
  Id: string;
  Title: string;
  Body: string;
  ImageUrl: string;
  DetailUrl: string;
  Created: Date;
  Read: Date;
  ServiceName: string;
  ServiceImageUrl: string;
}

export interface ListNotificationsResponseModel {
  Result: Notification[];
  Count: number;
}

interface ListNotificationsRequestModel {
  Page: number;
  Size: number;
}

interface MarkAsReadRequestModel {
  NotificationIds: string[];
}

export function ListNotificationsRequest(token: string, body: ListNotificationsRequestModel, success: (response: ApiResponse<ListNotificationsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<ListNotificationsResponseModel, ListNotificationsRequestModel>(
    '/notifications/list',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function MarkAsReadRequest(token: string, body: MarkAsReadRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, MarkAsReadRequestModel>(
    '/notifications/mark-as-read',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetUnreadCountRequest(token: string, success: (response: ApiResponse<number>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<number>('/notifications/unread-count',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}
