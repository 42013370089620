/* eslint-disable react-hooks/exhaustive-deps */
import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { useEffect, useState } from 'react';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { gaService } from '../../service/gaService';
import { GetLatestProductDetail } from '../../service/serviceService';
import DetailPageHeader from '../../components-organisms/appbars/DetailPageHeader';
import { ListingCard } from '../../components-organisms/cards/listing/ListingCard';
import { Footer } from '../../components-organisms/Footer';
import { LogProductUpdateDetailView } from '../../service/logService';

export function LatestProductDetail(): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const [token, userServices] = useTypedSelector((state) => [state.userReducer.token, state.userReducer.services]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Product | undefined>();

  const getLatestProduct = () => {
    if (token && id) {
      setLoading(true);
      GetLatestProductDetail(
        token,
        id,
        (res) => {
          setData(res.Result);
          if (res.Result?.Id && res.Result?.Title) {
            LogProductUpdateDetailView(
              token,
              {
                Id: res.Result.Id,
                Title: res.Result.Title
              },
              () => { return; },
              (error) => console.error('Failed to log product update detail view:', error)
            );
          }
          setLoading(false);
        },
      );
    }
  };

  useEffect(() => {
    if (!state?.product) {
      getLatestProduct();
    }
  }, []);

  useEffect(() => {
    gaService.pageView('Latest Product Detail');
  }, []);

  const onBack = () => {
    const digitalAssetServiceId = userServices.flatMap(f => f.Services).find(m => m.ServiceName === 'Product Updates')?.ServiceId;
    const serviceUrl = '/service/';
    if (digitalAssetServiceId) {
      navigate(`${serviceUrl}${digitalAssetServiceId}`);
      return;
    }

    navigate(-1);
  };

  if (loading || !data) {
    return (
      <CircularProgress
        color='primary'
        size={50}
        style={{ zIndex: 98, position: 'absolute', top: '50vh', right: '50vw' }}
      />
    );
  }

  function renderHeader() {
    return (
      <DetailPageHeader
        title="Product Updates"
        onBack={onBack} />
    );
  }

  function renderContent() {
    if (!data) return null;
    
    return (
      <Stack
        display='flex'
        flex={1}
        mt={4}
        px={{ xs: '20px', sm: 4, md: 4, lg: 4, xl: 4 }}>
        <ListingCard
          hideEllipsis
          fromDetail
          isFirst={true}
          isLast={true}
          title={data.Title}
          description={data.Description}
          thumbnailUrl={data.ThumbnailUrl} />
      </Stack>
    );
  }

  return (
    <Stack flex={1}>
      {renderHeader()}
      {renderContent()}

      <Grid item sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Stack>
  );
}
