import { useEffect, useState } from 'react';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { CircularProgress, Divider, Grid, useMediaQuery } from '@mui/material';
import { BodyOnePrimary, BodyTwoSecondary, CaptionPrimary, CaptionSecondary, HeaderFourSecondary } from '../components-atoms/TypographyComponents';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dashboard } from '../components-organisms/Dashboard';
import { Footer } from '../components-organisms/Footer';
import { GetColourDetailRequest, GetColourDetailResponseModel, UpdateRecentColourRequest } from '../service/serviceService';
import '../scss/components/_thumbnail.scss';
import '../scss/components/_search-dropdown.scss';
import { LIGHT_THEME } from '../constants/theme';
import { SupportTooltip } from '../components-molecules/SupportTooltip';
import { gaService } from '../service/gaService';
import { LogKnowledgeBaseColourView } from '../service/logService';
 
export function ColourDetailsPage(): JSX.Element {
  const token = useTypedSelector(state => state.userReducer.token);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [colour, setColour] = useState<GetColourDetailResponseModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const smallUp = useMediaQuery(LIGHT_THEME.breakpoints.up('sm'));

  useEffect(() => {
    gaService.pageView('Colour Detail');
  }, []);

  useEffect(() => {
    if (token) {
      setLoading(true);
      GetColourDetailRequest(
        token,
        id as GUID,
        (response) => {
          if (response) {
            const result = response.Result;
            setColour(result);

            // Log colour view
            LogKnowledgeBaseColourView(
              token,
              {
                Id: result.Id,
                Name: result.Name
              },
              () => { return; },
              (error) => console.error('Failed to log colour view:', error)
            );

            UpdateRecentColourRequest(
              token,
              {
                RemoteId: `${result.Id}`,
                Name: result.Name,
                Hex: result.Hex,
              },
              (response) => { if (response) setLoading(false); },
              (error: any) => { if (error.response) { console.error(error.response.data.Error); setLoading(false); } }
            );
          }
        },
        (error: any) => { if (error.response) { console.error(error.response.data.Error); setLoading(false); } }
      );
    }
  }, [token, id]);

  return (
    <Dashboard noDrawer noMargin noPadding fullWidth merchantPage>
      {colour ? (
        <>
          <Grid container direction="column" sx={{ width: '100%' }}>
            <Grid container direction="row" justifyContent="center">
              <Grid direction="column" container item alignItems="flex-start" justifyContent="flex-start" sx={{ maxWidth: '1408px', width: '100%', padding: '0 24px' }}>
                <Grid sx={{ margin: '12px 0px 28px' }}>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate('/')}>Home</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate(`/category/${location.state.categoryId}`)}>Training</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                  <CaptionPrimary style={{ color: 'rgba(1, 33, 105, 1)', cursor: 'pointer' }} onClick={() => navigate(`/service/${location.state.serviceId}`)}>Knowledge Base</CaptionPrimary>
                  <CaptionSecondary style={{ margin: '0 4px' }}>|</CaptionSecondary>
                  <CaptionPrimary style={{ marginBottom: '4px' }}>{colour.Name}</CaptionPrimary>
                </Grid>
                <Grid container item direction="row" alignItems="center" sx={{ margin: '32px 0', width: '100%' }}>
                  <Grid
                    item
                    md={3}
                    style={{
                      backgroundColor: colour.Hex ? '#' + colour.Hex : '',
                      borderRadius: '0px 54px',
                      boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                      maxWidth: '320px',
                      maxHeight: '320px',
                      aspectRatio: '1/1',
                      width: '100%'
                    }}>
                  </Grid>
                  <Grid item xs={12} md={9} sx={{ padding: { xs: '26px 0 0', md: '0 0 0 64px' } }}>
                    <Grid container justifyContent="space-between" sx={{ position: 'relative', paddingBottom: '26px' }}>
                      <HeaderFourSecondary style={{ maxWidth: smallUp ? '672px' : '100%', width: '100%' }}>{colour.Name}</HeaderFourSecondary>
                      <div style={{ marginLeft: 'auto', alignSelf: 'flex-end', }}>
                        <SupportTooltip />
                      </div>
                    </Grid>
                    <Divider />
                    <Grid container direction="row" sx={{ maxWidth: { sm: '672px' }, width: '100%' }}>
                      <Grid item xs={4} sx={{ paddingTop: '20px', paddingRight: '10.6px' }}>
                        <BodyTwoSecondary>Colour Number</BodyTwoSecondary>
                        <BodyOnePrimary>{colour.ColourNumber ? colour.ColourNumber : 'N/A'}</BodyOnePrimary>
                      </Grid>
                      <Grid item xs={4} sx={{ paddingTop: '20px', paddingRight: '5.3px', paddingLeft: '5.3px' }}>
                        <BodyTwoSecondary>Hue</BodyTwoSecondary>
                        <BodyOnePrimary>{colour.Hue ? colour.Hue : 'N/A'}</BodyOnePrimary>
                      </Grid>
                      <Grid item xs={4} sx={{ paddingTop: '20px', paddingLeft: '10.6px' }}>
                        <BodyTwoSecondary>Hex</BodyTwoSecondary>
                        <BodyOnePrimary>{colour.Hex ? colour.Hex : 'N/A'}</BodyOnePrimary>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{
            background: 'linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Grid item sx={{ maxWidth: '1408px', width: '100%' }}>
              <Footer />
            </Grid>
          </Grid>
        </>
      ) : loading ? (
        <div className="progress-spinner-wrapper">
          <CircularProgress size={32} />
        </div>
      ) : (
        <></>
      )}
    </Dashboard>
  );
}
