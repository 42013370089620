const INITIAL: DigitalToolkitState = {
  currentData: {
    Id: '' as GUID,
    Title: '',
    Description: '',
    FileUrl: '',
    ThumbnailUrl: '',
    Published: true,
    Brand: null,
    Product: null,
    VisibilityRestrictions: {
      AudienceUserRole: [],
      MerchantUserRole: [],
    }
  }
};

export const digitalToolkitReducer = (state: DigitalToolkitState = INITIAL, action: DigitalToolkitSetCurrentAction): DigitalToolkitState => {
  switch (action.type) {
    case 'SET_CURRENT_DIGITALTOOLKIT':
      return { ...state, currentData: action.payload.currentData };
    default:
      return state;
  }
};
