import { Stack, useMediaQuery } from '@mui/material';
import { ButtonContainedPrimary, ButtonTextPrimary } from '../../components-atoms/ButtonComponents';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { GetAssetsFromListRequest, GetSelectedAssetsFromDetailRequest } from '../../service/assetService';
import { LIGHT_THEME } from '../../constants/theme';
import { clearSelectedAssets } from '../../constants/digitalAssets';
import { DownloadButtonSource } from '../../utils/enums';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import { Fragment } from 'react';

interface IProps {
  selectedAssets: any[];
  downloadAll?: () => void;
  source?: DownloadButtonSource;
  onDeselectAll?: () => void;
  onSelectAll?: () => void;
  onShare?: () => void;
  disableDownloads?: boolean;
}

export function HeaderDownloadButtons({ downloadAll, onDeselectAll, onSelectAll, selectedAssets, source, onShare, disableDownloads }: IProps): JSX.Element {
  const dispatch = useTypedDispatch();
  const [token]: any = useTypedSelector((state) => [state.userReducer.token]);
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const mediumDown = useMediaQuery(LIGHT_THEME.breakpoints.down('md'));

  function onDownload() {
    const assetIds = selectedAssets?.map((asset: any) => asset.DigitalAssetId);
    switch (source) {
      case DownloadButtonSource.AssetDetail:
        if (selectedAssets.length === 1) {
          const a = document.createElement('a');
          a.href = selectedAssets[0].FileUrl;
          a.download = selectedAssets[0].FileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          GetSelectedAssetsFromDetailRequest(
            token,
            { 'DigitalAssetFileIds': selectedAssets.map((asset: any) => asset.Id) },
            (response) => {
              const link = document.createElement('a');
              link.href = response.Result;
              const lastSegment = response.Result.split('/').pop() as string;
              link.download = lastSegment;
              link.click();
            }
          );
        }
        break;

      default:
        if (!assetIds || !token) { return; }

        dispatch({ type: 'SET_DIGITAL_ASSET_LOADING', payload: { loading: true } });
        GetAssetsFromListRequest(
          token,
          { 'DigitalAssetIds': assetIds },
          (response) => {
            const link = document.createElement('a');
            link.href = response.Result;
            const lastSegment = response.Result.split('/').pop() as string;
            link.download = lastSegment;
            link.click();
            dispatch({ type: 'SET_DIGITAL_ASSET_LOADING', payload: { loading: false } });
          }
        );
        break;
    }
  }

  return (
    <Stack
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      gap={1}
      sx={source === DownloadButtonSource.AssetDetail
        ? {}
        : mediumDown ? {
          width: '100%',
          position: 'fixed',
          height: smallDown ? '52px' : '64px',
          left: 0,
          top: smallDown ? 'calc(100% - 52px)' : 'calc(100% - 64px)',
          backgroundColor: 'white',
          boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12)',
          pr: { xs: '20px', sm: 4, md: 4, lg: 4, xl: 4 },
        } : {
          minWidth: 400,
        }}
    >
      {disableDownloads
        ? null
        : downloadAll && selectedAssets?.length === 0
          ? <ButtonContainedPrimary
            onClick={downloadAll}
            startIcon={<DownloadIcon />}
            sx={{ height: smallDown ? '30px' : '42px' }}
            isSmall={mediumDown}>
          Download All
          </ButtonContainedPrimary>
          : null
      }

      {disableDownloads
        ? null
        : (source === DownloadButtonSource.AssetListing || selectedAssets?.length > 0)
          ? <Fragment>
            <ButtonTextPrimary
              sx={{ width: { md: 94, xs: 'unset' }, height: smallDown ? '30px' : '42px' }}
              onClick={() => {
                if (onSelectAll) onSelectAll();
              }}
              isSmall={smallDown}
            >
              Select All
            </ButtonTextPrimary>

            <ButtonTextPrimary
              sx={{ height: smallDown ? '30px' : '42px' }}
              disabled={selectedAssets?.length === 0}
              onClick={() => {
                if (onDeselectAll) {
                  onDeselectAll();
                } else {
                  clearSelectedAssets(dispatch);
                }
              }}
              isSmall={smallDown}
            >
              Deselect All
            </ButtonTextPrimary>

            <ButtonContainedPrimary
              onClick={onDownload}
              startIcon={<DownloadIcon />}
              disabled={selectedAssets?.length === 0}
              sx={{ height: smallDown ? '30px' : '42px' }}
              isSmall={smallDown}
            >
              Download | {selectedAssets?.length}
            </ButtonContainedPrimary>
          </Fragment>
          : null
      }
      {onShare
        && <ButtonContainedPrimary
          onClick={onShare}
          startIcon={<ShareIcon />}
          sx={{ height: smallDown ? '30px' : '42px', width: '118px' }}>
          Share
        </ButtonContainedPrimary>
      }
    </Stack>
  );
}
