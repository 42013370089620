import { useTypedSelector } from '../../hooks/TypedReduxHooks';
import { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { gaService } from '../../service/gaService';
import DetailPageHeader from '../../components-organisms/appbars/DetailPageHeader';
import { GetIndustryInsightDetailRequest, LogIndustryInsightViewPdf, LogIndustryInsightDownloadPdf, IndustryInsightLearnMoreRequest } from '../../service/serviceService';
import { ListingCard } from '../../components-organisms/cards/listing/ListingCard';
import { ButtonContainedPrimary, ButtonOutlinedPrimary } from '../../components-atoms/ButtonComponents';
import { FullScreenAssetDialog, FullScreenDialogServiceType } from '../../components-molecules/dialogs/FullScreenAssetDialog';
import { Footer } from '../../components-organisms/Footer';

export function IndustryInsightDetail(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [token, userServices] = useTypedSelector((state) => [state.userReducer.token, state.userReducer.services]);

  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState();
  const [fullScreenModalOpen, setFullScreenModalOpen] = useState<number>(-1);
  const assets = useMemo(() => data ? [
    {
      FileType: 'Other',
      Extension: 'pdf',
      PreviewUrl: data.PdfUrl,
      ThumbnailUrl: data.ThumbnailUrl,
    },
  ] : [], [data]);

  const getIndustryInsightDetail = () => {
    if (token && id) {
      setLoading(true);
      GetIndustryInsightDetailRequest(
        token,
        id,
        (res) => {
          setData(res.Result);
          setLoading(false);

          IndustryInsightLearnMoreRequest(
            token,
            { id: id as GUID }
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { });
    }
  };

  useEffect(() => {
    getIndustryInsightDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    gaService.pageView('Industry Insight Detail');
  }, []);

  const onBack = () => {
    const digitalAssetServiceId = userServices.flatMap(f => f.Services).find(m => m.ServiceName === 'Industry Insights')?.ServiceId;
    const serviceUrl = '/service/';

    if (digitalAssetServiceId) {
      navigate(`${serviceUrl}${digitalAssetServiceId}`);
      return;
    }

    navigate(-1);
  };


  const downloadPdf = () => {
    const fileUrl = data.PdfUrl;
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = data.PdfUrl.split('/').pop() as string;
        downloadLink.click();
        URL.revokeObjectURL(url);

        // Log the download action
        if (token && data.Id) {
          LogIndustryInsightDownloadPdf(
            token,
            data.Id,
            () => { return; },
            (error) => console.error('Error logging PDF download:', error)
          );
        }
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
      });
  };

  function onClick() {
    setFullScreenModalOpen(0);
    // Log the view action
    if (token && data.Id) {
      LogIndustryInsightViewPdf(
        token,
        data.Id,
        () => { return; },
        (error) => console.error('Error logging PDF view:', error)
      );
    }
  }

  function renderHeader() {
    return (
      <DetailPageHeader
        title="Industry Insights"
        onBack={onBack} />
    );
  }

  function renderButtons() {
    return (
      <Stack direction="row" spacing={1}>
        <ButtonOutlinedPrimary
          onClick={() => onClick()}
          sx={{ height: '36px' }}>
          View PDF
        </ButtonOutlinedPrimary>
        <ButtonContainedPrimary
          onClick={() => downloadPdf()}
          sx={{ height: '36px' }}>
          Download PDF
        </ButtonContainedPrimary>
      </Stack>
    );
  }

  function renderContent() {
    return (
      <Stack
        display='flex'
        flex={1}
        mt={4}
        mx={{ xs: '20px', sm: 4 }}>
        <ListingCard
          hideEllipsis
          fromDetail
          thumbnailFit='cover'
          isFirst={true}
          isLast={true}
          title={data.Title}
          createDate={data.Created}
          description={data.Description}
          thumbnailUrl={data.ImageUrl || data.ThumbnailUrl}
          customButtons={renderButtons}
        />
      </Stack>
    );
  }

  if (loading || !data) {
    return (
      <CircularProgress
        color='primary'
        size={50}
        style={{ zIndex: 98, position: 'absolute', top: '50vh', right: '50vw' }}
      />
    );
  }

  return (
    <Stack flex={1}>
      {renderHeader()}
      {renderContent()}

      <FullScreenAssetDialog
        open={fullScreenModalOpen > -1}
        index={fullScreenModalOpen}
        assets={assets}
        onClose={() => setFullScreenModalOpen(-1)}
        serviceType={FullScreenDialogServiceType.IndustryInsight}
      />

      <Grid item sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Stack>
  );
}
