import { useCallback, useEffect, useState } from 'react';
import { PageHeader } from '../components-molecules/titlebars/PageHeader';
import { NoItems } from '../components-molecules/NoItems';
import { TabPanel } from '@mui/lab';
import { NPDList } from '../components-organisms/NPDList';
import { useTypedDispatch, useTypedSelector } from '../hooks/TypedReduxHooks';
import { 
  ChangeProductPublishStateRequest, 
  DeleteProductRequest, 
  GetNPDBrands,
  GetNPDProducts,
  GetProductRequest, 
  GetProductsRequest, 
  ReOrderProductsRequest 
} from '../service/serviceService';
import { MiniDialog } from '../components-molecules/dialogs/MiniDialog';
import { BodyOneSecondary } from '../components-atoms/TypographyComponents';
import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import { LIGHT_THEME } from '../constants/theme';
import { useNavigate } from 'react-router-dom';
import { ExternalPageRequest } from '../service/externalPageService';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

export function NewProductDevelopment(): JSX.Element {
  const pageSize = 10;
  const token = useTypedSelector(state => state.userReducer.token);
  const [page, setPage] = useState(0);
  const [products, setProducts] = useState<NPDItem[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [total, setTotal] = useState<number>(0);
  const [brands, setBrands] = useState<any[]>([]);
  const [productOptions, setProductOptions] = useState<any[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [sortOption, setSortOption] = useState<string>('date');
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(token){
      ExternalPageRequest(
        token,
        {Url: window.location.href}
      );
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // Load brands and products
  useEffect(() => {
    if (token) {
      GetNPDBrands(
        token,
        (response) => {
          if (response && response.Result) {
            setBrands(response.Result);
          }
        },
        (error) => {
          console.error('Error fetching brands:', error);
        }
      );

      GetNPDProducts(
        token,
        (response) => {
          if (response && response.Result) {
            setProductOptions(response.Result);
          }
        },
        (error) => {
          console.error('Error fetching products:', error);
        }
      );
    }
  }, [token]);

  const loadMore = () => {
    if (token) {
      getProducts(token, page + 1, pageSize, products);
      setPage(page + 1);
    }
  };

  const getProducts = useCallback((t: string, page = 0, size = pageSize, prevData: NPDItem[] = []): void => {
    GetProductsRequest(
      t,
      { 
        Page: page, 
        Size: size, 
        Query: query,
        Brand: selectedBrand || undefined,
        Product: selectedProduct || undefined,
        SortBy: sortOption || undefined
      },
      (response) => {
        if (response) {
          setTotal(response.Result.Count);
          let temp = [...prevData];
          response.Result.Result.map(m => {
            const tempMatch = temp.find((f) => f.GroupName === m.GroupName);
            if (tempMatch) {
              temp[temp.indexOf(tempMatch)].Products = [...temp[temp.indexOf(tempMatch)].Products, ...m.Products];
            } else {
              temp = [...temp, m];
            }
          });
          setProducts(temp);
          setLoading(false);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }, [query, selectedBrand, selectedProduct, sortOption]);

  const changePublishState = (id: string) => {
    if (token) {
      setProducts([]);
      setLoading(true);
      ChangeProductPublishStateRequest(
        token,
        {
          Id: id
        },
        () => { setRefresh(!refresh); setPage(0); },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const onMove = (newData: Product[]) => {
    if (token) {
      const idList = newData.map(m => ({ Id: m.Id }));
      ReOrderProductsRequest(
        token,
        idList,
        () => { return; },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const getProduct = (id: string) => {
    if (token) {
      GetProductRequest(
        token,
        id,
        (response) => {
          if (response) {
            dispatch({ type: 'SET_CURRENT_PRODUCT', payload: { currentData: response.Result } });
            navigate('/admin/edit-product');
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  const deleteProduct = (id: string) => {
    if (token) {
      DeleteProductRequest(
        token,
        id,
        () => { setRefresh(!refresh); setPage(0); },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }
  };

  useEffect(() => {
    if (token) {
      setPage(0);
      setProducts([]);
      getProducts(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, query, refresh, selectedBrand, selectedProduct, sortOption]);

  const searchOnChange = (text:string) =>{
    setQuery(text);
  };

  return (
    <TabPanel value="content" sx={{ padding: 0 }}>
      <PageHeader
        buttonTitle={'Add Product'}
        activeButtons={true}
        modalCallback={() => navigate('/admin/add-product')}
        onClickSearch={() => setSearchOpen(!searchOpen)}
        noSort
      />
      <SearchbarWithDeboubce
        isOpen={searchOpen}
        query={query}
        placeholder="Search Products"
        onCancel={() => { setSearchOpen(false); setQuery(''); }}
        onChange={searchOnChange}
      />
      
      {/* Filters and Sorting */}
      <Grid container spacing={2} sx={{ mt: 2, mb: 3, px: 4 }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel>Brand</InputLabel>
            <Select
              value={selectedBrand}
              onChange={(e) => setSelectedBrand(e.target.value)}
            >
              <MenuItem value="">All Brands</MenuItem>
              {brands.map((brand) => (
                <MenuItem key={brand.Id} value={brand.Name}>
                  {brand.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel>Product</InputLabel>
            <Select
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
            >
              <MenuItem value="">All Products</MenuItem>
              {productOptions.map((product) => (
                <MenuItem key={product.Id} value={product.Name}>
                  {product.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <MenuItem value="date">Date (Newest First)</MenuItem>
              <MenuItem value="date_asc">Date (Oldest First)</MenuItem>
              <MenuItem value="brand_az">Brand (A-Z)</MenuItem>
              <MenuItem value="brand_za">Brand (Z-A)</MenuItem>
              <MenuItem value="product_az">Product (A-Z)</MenuItem>
              <MenuItem value="product_za">Product (Z-A)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      
      {loading
        ? <CircularProgress
          color='primary'
          size={50}
          style={{ zIndex: 999, position: 'absolute', top: '50vh', right: '40vw' }}
        />
        : ((products.length > 0)
          ? <NPDList
            onMove={onMove}
            total={total}
            products={products}
            loadMore={loadMore}
            onEdit={(id) => {
              getProduct(id);
            }}
            onDelete={(id) => {
              setSelectedId(id);
              setModalOpen(true);
            }}
            onPublishChange={(id) => changePublishState(id)}
          />
          : <NoItems
            icon={<FormatPaintIcon sx={{ fontSize: 88, fill: LIGHT_THEME.palette.primary.main }} />}
            title="No products yet"
            description="You haven't added any products yet. Start by adding the first product."
          />)
      }
      {modalOpen
        ? <MiniDialog
          title="Delete Product"
          open={modalOpen}
          disabled={!selectedId}
          close={() => {
            setModalOpen(false);
            setSelectedId(undefined);
          }}
          remove={() => {
            if (selectedId) {
              deleteProduct(selectedId);
            }
            setSelectedId(undefined);
            setModalOpen(false);
          }}>
          <Box sx={{ padding: '0 24px' }}>
            <BodyOneSecondary>
              {'Are you sure that you want to remove this Product?'}
            </BodyOneSecondary>
          </Box>
        </MiniDialog>
        : null
      }
    </TabPanel>
  );
}
