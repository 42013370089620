/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import { Button, Grid } from '@mui/material';
import VideoPreviewDialog from '../components-molecules/dialogs/VideoPreviewDialog';
import { HeaderSix } from '../components-atoms/TypographyComponents';
import { IStyles } from '../constants/theme';
import play from '../assets/icons/Play.svg';
import { LogHelpVideoView } from '../service/logService';
import { useSelector } from 'react-redux';

export function HelpVideosService(): JSX.Element {
  const [videoPreviewDialogOpen, setVideoPreviewDialogOpen] = useState(false);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState<string>('');
  const [videoThumbnailUrl, setVideoThumbnailUrl] = useState<string>('');
  const token = useSelector((state: any) => state.userReducer?.token);

  const getOnClick = (video: any) => {
    setVideoPreviewUrl(video.previewUrl);
    setVideoThumbnailUrl(video.thumbnailUrl);
    setVideoPreviewDialogOpen(true);

    // Log the video view
    if (token) {
      LogHelpVideoView(
        token,
        {
          Id: video.id,
          Url: video.previewUrl
        },
        () => { return; },
        (error) => console.error('Failed to log help video view:', error)
      );
    }
  };

  return (
    <>
      <Grid container item justifyContent="flex-start" style={styles.pageContainer}>
        <Grid style={styles.gridContainer} sx={{ gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, minmax(308px, 436px))' } }}>
          {VIDEOS.map((video, index) =>
            <Grid
              key={index}
              onClick={() => getOnClick(video)}
              sx={{
                '&:hover': {
                  '.playButton': {
                    transform: 'scale(.95)',
                    transition: 'all .25s ease-in-out'
                  }
                }
              }}
            >
              <Grid style={styles.videoContainer}>
                <img src={video.thumbnailUrl} style={styles.videoImage} />
                <Button variant="contained" color="primary" className="playButton" style={styles.playButton}>
                  <img src={play} style={styles.playIcon} />
                </Button>
              </Grid>
              <HeaderSix style={styles.title}>{video.title}</HeaderSix>
            </Grid>
          )}
        </Grid>
      </Grid>
      <VideoPreviewDialog
        onClose={() => {
          setVideoPreviewDialogOpen(false);
          setVideoThumbnailUrl('');
          setVideoPreviewUrl('');
        }}
        open={videoPreviewDialogOpen}
        thumbnail={videoThumbnailUrl}
        url={videoPreviewUrl}
        noTitle
        noDownload
      />
    </>
  );
}

const VIDEOS = [
  {
    id: '1',
    title: 'Connect for Merchants Overview Video',
    thumbnailUrl: 'https://hncdev.azureedge.net/merchantconnect-prod/help_videos/connect_for_merchants_overview.jpg',
    previewUrl: 'https://hncdev.azureedge.net/merchantconnect-dev/brand_assets_images/p_8445ef08-15ed-49f2-977e-53cf3bf3fb7a_Connect_for_Merchant_Overview.mp4'
  },
  {
    id: '2',
    title: 'Merchant Manager Training Video',
    thumbnailUrl: 'https://hncdev.azureedge.net/merchantconnect-prod/brand_assets_images/t_c70f7fce-e3d2-4258-a65a-ec11659290fe_5636_Connect_Merchant_Manager_Video_2.jpg',
    previewUrl: 'https://hncdev.azureedge.net/merchantconnect-prod/brand_assets_images/p_c70f7fce-e3d2-4258-a65a-ec11659290fe_5636_Connect_Merchant_Manager_Video_2.mp4'
  },
  {
    id: '3',
    title: 'Brand Assets Video',
    thumbnailUrl: 'https://hncdev.azureedge.net/merchantconnect-dev/brand_assets_images/t_4696951a-33f9-4967-9ae8-db41eeedcdf5_Brand_assets_video.jpg',
    previewUrl: 'https://hncdev.azureedge.net/merchantconnect-dev/brand_assets_images/p_4696951a-33f9-4967-9ae8-db41eeedcdf5_Brand_assets_video.mp4'
  }
];

const styles: IStyles = {
  pageContainer: {
    maxWidth: '1408px',
    width: '100%',
    margin: '32px 0px',
    padding: '0 24px'
  },
  gridContainer: {
    display: 'grid',
    gridGap: '32px 26px',
    width: '100%',
    cursor: 'pointer'
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    marginBottom: '16px',
    position: 'relative'
  },
  videoImage: {
    width: '100%',
    aspectRatio: '16/9',
    borderRadius: '4px'
  },
  playButton: {
    width: '64px',
    height: '64px',
    borderRadius: '50%',
    transition: 'all .25s ease-in-out',
    position: 'absolute',
    zIndex: 1
  },
  playIcon: {
    width: '26px',
    height: '24px'
  },
  title: {
    color: '#012169',
    marginBottom: '8px'
  }
};
