import { AxiosError, CancelToken } from 'axios';
import { SortType } from '../components-organisms/DocumentRepository';

import { HttpRequestDelete, HttpRequestGet, HttpRequestPost, HttpRequestPut } from './genericService';
import { Newsletters } from '../components-organisms/NewsletterRepositoryService';

export function GetServicesRequest(token: string, success: (response: ApiResponse<IService[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<IService[]>(
    '/service',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

type GetServiceResponseModel = KnowledgeBase;

export function GetServiceRequest(token: string, guid: GUID, success: (response: ApiResponse<GetServiceResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetServiceResponseModel>(
    `/service/${guid}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}
export function GetServiceForAdminRequest(token: string, guid: GUID, success: (response: ApiResponse<GetServiceResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetServiceResponseModel>(
    `/service/admin/${guid}`,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    undefined,
    true
  );
}
type AddAccessRightRequestModel = {
  Audience: string;
  Role: string;
};

export function AddServiceAccessRightRequest(token: string, guid: GUID, body: AddAccessRightRequestModel, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<unknown, AddAccessRightRequestModel>(
    `/service/access-right/${guid}`,
    body,
    () => success(),
    (error) => failure(error),
    token
  );
}

export function DeleteServiceAccessRightRequest(token: string, accessRightId: GUID, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete<string>(
    `/service/access-right/${accessRightId}`,
    () => success(),
    (error) => failure(error),
    token
  );
}

export function GetAvailableServices(token: string, success: (data: ApiResponse<IServiceByCategory[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<IServiceByCategory[]>(
    '/service/availableByCategory',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetFileTypes(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/digitalasset/getFileTypes',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetTypes(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/digitalasset/getTypes',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetOrientations(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/digitalasset/getOrientations',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetBrands(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/digitalasset/getBrands',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetProducts(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/digitalasset/getProducts',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetDigitalToolkitBrands(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/digitaltoolkit/getBrands',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetDigitalToolkitProducts(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/digitaltoolkit/getProducts',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetNPDBrands(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/npd/getBrands',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetNPDProducts(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/npd/getProducts',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}


export function GetColours(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/digitalasset/getColours',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetColourIds(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/digitalasset/getColourIds',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetTags(token: string, success: (data: ApiResponse<any[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<any[]>(
    '/digitalasset/getTags',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

type AddDigitalAssetRequestModel = {
  Name: string;
  Barcode: string;
  Fini: string;
  PublicationDate: any;
  Published: boolean;
  Brand: string;
  Product: string;
  Colour?: string;
  AkzoColourId?: string;
  Tags?: string[];
  DigitalAssetOrientationId: string;
  DigitalAssetTypeId: string;
  DigitalAssetFiles: any[];
  SendEmailNotification?: boolean;
  VisibilityRestrictions: IVisibilityRestrictions;
  DisableDownloads: boolean;
};

export function AddDigitalAssetRequest(token: string, body: AddDigitalAssetRequestModel, success: (response: ApiResponse<any>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, AddDigitalAssetRequestModel>(
    '/digitalasset',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

type GetDigitalAssetsResponseModel = {
  DigitalAssets: DigitalAssetItem[];
  Count: number;
};

interface GetDigitalAssetsRequestModel {
  Query?: string;
  OrderBy?: string;
  BrandIds?: string[];
  ProductIds?: string[];
  ColourIds?: string[];
  Size?: string;
  SizeFrom?: number;
  SizeTo?: number;
  Width?: number;
  Height?: number;
  DigitalAssetTypeIds?: string[];
  FileTypeIds?: string[];
  OrientationIds?: string[];
  Offset: number;
  PageSize: number;
  ShareToken?: string;
  IsRestricted?: string;
}

export function GetDigitalAssetsRequest(token: string, body: GetDigitalAssetsRequestModel, success: (response: ApiResponse<GetDigitalAssetsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<GetDigitalAssetsResponseModel, GetDigitalAssetsRequestModel>(
    '/digitalasset/search',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken
  );
}

interface GetDigitalAssetFiltersRequestModel {
  BrandIds?: string[];
  ProductIds?: string[];
  ColourIds?: string[];
  DigitalAssetTypeIds?: string[];
  FileTypeIds?: string[];
  OrientationIds?: string[];
  Query?: string;
  ShareToken?: string;
}

export function GetDigitalAssetFiltersRequest(token: string, body: GetDigitalAssetFiltersRequestModel, success: (response: ApiResponse<DigitalAssetFilter>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<DigitalAssetFilter, GetDigitalAssetFiltersRequestModel>(
    '/digitalasset/getFilters',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken
  );
}

export function GetDigitalAssetRequest(token: string, id: string, success: (response: ApiResponse<ICurrentDigitalAsset>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ICurrentDigitalAsset>(
    `/digitalasset/getDigitalAsset/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetDigitalAssetAppliedFilters(token: string, id: string, success: (response: ApiResponse<any>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ICurrentDigitalAsset>(
    `/digitalasset/xxx/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface EditCreateDigitalAssetFile {
  DigitalAssetFileTypeId: string;
  Width: number;
  Height: number;
  FileUrl: string;
  FileName: string;
  SizeUnit: string;
  Size: number;
  Extension: string;
}

interface EditCreateDigitalAssetRequest {
  DigitalAssetId: string;
  Name: string;
  Barcode: string;
  Fini: string;
  PublicationDate: Date;
  Published: boolean;
  Brand: string;
  Product: string;
  Colour?: string;
  AkzoColourId?: string;
  Tags?: string[];
  DigitalAssetTypeId: string;
  DigitalAssetOrientationId: string;
  DigitalAssetFiles: EditCreateDigitalAssetFile[];
  SendEmailNotification: boolean;
  VisibilityRestrictions: IVisibilityRestrictions;
  DisableDownloads: boolean;
}

export function EditDigitalAssetRequest(token: string, body: EditCreateDigitalAssetRequest, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, EditCreateDigitalAssetRequest>(
    '/digitalasset/edit',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function DeleteDigitalAssetRequest(token: string, id: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete<string>(
    `/digitalasset/delete/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ChangePublishedStateRequestModel {
  DigitalAssetId: string;
}

interface ChangePublishedStateResponseModel {
  Message: string;
  Published: boolean;
}

export function ChangePublishedStateRequest(token: string, body: ChangePublishedStateRequestModel, success: (response: ApiResponse<ChangePublishedStateResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<ChangePublishedStateResponseModel, ChangePublishedStateRequestModel>(
    '/digitalasset/changePublishState',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface CreateEditorsPickRequestModel {
  ServiceId: string;
  Order: number;
}

export function CreateEditorsPickRequest(token: string, body: CreateEditorsPickRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, CreateEditorsPickRequestModel>(
    '/editorspick',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetEditorsPickRequest(token: string, success: (data: ApiResponse<EditorsPick[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<EditorsPick[]>(
    '/editorspick/services',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetEditorsPickByUserRequest(token: string, success: (data: ApiResponse<EditorsPickByUser[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<EditorsPickByUser[]>(
    '/editorspick/editorsPickByUser',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function DeleteEditorsPickRequest(token: string, id: GUID, success: () => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete(
    `editorspick/delete/${id}`,
    () => success(),
    (error) => failure(error),
    token
  );
}

interface OrderEditorsPickRequestModel {
  ServiceId: string;
}

export function OrderEditorsPickRequest(token: string, body: OrderEditorsPickRequestModel[], success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, OrderEditorsPickRequestModel[]>(
    '/editorspick/updateOrders',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface CreateRecentBenefitRequestModel {
  ServiceId: string;
}

export function CreateRecentBenefitRequest(token: string, body: CreateRecentBenefitRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, CreateRecentBenefitRequestModel>(
    '/recentbenefits',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetRecentBenefitsRequest(token: string, success: (data: ApiResponse<RecentBenefit[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<RecentBenefit[]>(
    '/recentbenefits',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetAllServicesNoPublicRequest(token: string, success: (data: ApiResponse<IServiceByCategory[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<IServiceByCategory[]>(
    '/service/allByCategoryNoPublic',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetAllServicesRequest(token: string, success: (data: ApiResponse<IServiceByCategory[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<IServiceByCategory[]>(
    '/service/allByCategory',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetAllAdminServicesRequest(token: string, success: (data: ApiResponse<IAdminService[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<IAdminService[]>(
    '/service/admin',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetServicesOfCategoryRequest(token: string, id: GUID, success: (data: ApiResponse<ServiceOfCategory[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ServiceOfCategory[]>(
    `/service/getByCategory/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface CreateIndustryInsightRequestModel {
  Title: string;
  Description?: string;
  PdfUrl: string;
  ImageUrl: string;
  Published: boolean;
}

export function CreateIndustryInsightRequest(token: string, body: CreateIndustryInsightRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, CreateIndustryInsightRequestModel>(
    '/industryinsight',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface GetIndustryInsightRequestModel {
  Size: number;
  Page: number;
  Query?: string;
  Published?: boolean;
  Unpublished?: boolean;
}

interface GetIndustryInsightsResponseModel {
  Count: number;
  Result: IndustryInsightItem[];
}

export function GetIndustryInsightsRequest(token: string, body: GetIndustryInsightRequestModel, success: (response: ApiResponse<GetIndustryInsightsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<GetIndustryInsightsResponseModel, GetIndustryInsightRequestModel>(
    '/industryinsight/search',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function DeleteInsightRequest(token: string, id: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete<string>(
    `/industryinsight/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface UpdateIndustryInsightRequestModel {
  Id: string;
  Title: string;
  Description?: string;
  PdfUrl: string;
  ImageUrl: string;
  Published: boolean;
}

export function EditIndustryInsightRequest(token: string, body: UpdateIndustryInsightRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, UpdateIndustryInsightRequestModel>(
    '/industryinsight/update',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ChangeIndustryInsightPublishStateRequest {
  Id: string;
}

export function ChangeInsightPublishStateRequest(token: string, body: ChangeIndustryInsightPublishStateRequest, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, ChangeIndustryInsightPublishStateRequest>(
    '/industryinsight/changePublishState',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetIndustryInsightRequest(token: string, id: string, success: (response: ApiResponse<ICurrentIndustryInsight>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ICurrentIndustryInsight>(
    `/industryinsight/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ListIndustryInsightsRequestModel {
  Size: number;
  Page: number;
  Query?: string;
  ServiceId: string;
}

export interface IndustryInsightListModel {
  Id: GUID;
  Title: string;
  Description: string;
  ImageUrl: string;
  ThumbnailUrl: string;
  PdfUrl: string;
  Created: Date;
}

export interface ListIndustryInsightsResponseModel {
  Count: number;
  Result: IndustryInsightListModel[];
}

export function ListIndustryInsightsRequest(token: string, body: ListIndustryInsightsRequestModel, success: (response: ApiResponse<ListIndustryInsightsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<ListIndustryInsightsResponseModel, ListIndustryInsightsRequestModel>(
    '/industryinsight/list',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}
export interface HomepageIndustryInsights {
  Id: GUID;
  Title: string;
  Description: string;
  ImageUrl: string;
  PdfUrl: string;
  Published: Date;
  Created: Date;
}

export function HomepageIndustryInsightsRequest(token: string, size: number, serviceId: string, success: (response: ApiResponse<HomepageIndustryInsights[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<HomepageIndustryInsights[]>(
    `/industryinsight/homepage/serviceId=${serviceId}&size=${size}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface IndustryInsightLearnMore {
  id: GUID;
}

export function IndustryInsightLearnMoreRequest(token: string, body: IndustryInsightLearnMore): void {
  const { id, ...rest } = body;
  HttpRequestPost<string, Omit<IndustryInsightLearnMore, 'id'>>(
    `/industryinsight/learnMore/${id}`,
    rest,
    () => undefined,
    () => undefined,
    token
  );
}

interface GetKnowledgeBaseSearchRequestModel {
  Size: number;
  Page: number;
  Query: string;
}

export function GetKnowledgeBaseSearchRequest(token: string, body: GetKnowledgeBaseSearchRequestModel, success: (response: ApiResponse<KnowledgeBaseResult>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<KnowledgeBaseResult, GetKnowledgeBaseSearchRequestModel>(
    '/knowledgebase/search',
    body,
    (response: { data: ApiResponse<KnowledgeBaseResult> }) =>
      success(response.data),
    (error: AxiosError<ErrorResponse, any>) => failure(error),
    token
  );
}

export interface GetArticleDetailResponseModel {
  Id: string;
  Title: string;
  PublishFromDate: string;
  Body: string;
}

export function GetArticleDetailRequest(token: string, id: GUID, success: (data: ApiResponse<GetArticleDetailResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetArticleDetailResponseModel>(
    `/knowledgebase/search/article/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface GetColourDetailResponseModel {
  Id: string;
  Name: string;
  Hex: string;
  ColourNumber: string;
  Hue: string;
}

export function GetColourDetailRequest(token: string, id: GUID, success: (data: ApiResponse<GetColourDetailResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetColourDetailResponseModel>(
    `/knowledgebase/search/colour/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface Packshot {
  S: string;
  M: string;
  L: string;
}

interface Composition {
  Name: string;
}

interface KeySellingPoints {
  OrderNum: number;
  Description: string;
}

interface Features {
  Title: string;
}

export interface ProductDocument {
  Title: string;
  Type: string;
  Url: string;
}

export interface GetProductDetailResponseModel {
  Id: string;
  Name: string;
  Brand: string;
  Packshots: Packshot[];
  LocalSlogan: string;
  ProductType: string;
  Sheen: string;
  Positions: string[];
  Surfaces: string[];
  Opacity: string;
  Composition: Composition[];
  KeySellingPoints: KeySellingPoints[];
  Features: Features[];
  MarketingDescription: string;
  NumberOfCoats: number;
  SpreadingRate: string;
  DryingTimeTouchDry: string;
  DryingTimeDescription: string;
  Voc: string;
  PackSizeDescription: string;
  Coverage: string;
  Usage: string;
  DryingTimeRecoat: string;
  RecommendedDryFilmThickness: string;
  ApplicationDescription: string;
  SurfaceDescription: string;
  Cleaning: string;
  CleaningDescription: string;
  Storage: string;
  Documents: ProductDocument[];
}

export function GetProductDetailRequest(token: string, id: GUID, success: (data: ApiResponse<GetProductDetailResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetProductDetailResponseModel>(
    `/knowledgebase/search/product/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface UpdateRecentArticleRequestModel {
  RemoteId: string;
  Title: string;
  PublishedDate?: Date;
}

export function UpdateRecentArticleRequest(token: string, body: UpdateRecentArticleRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, UpdateRecentArticleRequestModel>(
    '/knowledgebase/recentarticle',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface UpdateRecentColourRequestModel {
  RemoteId: string;
  Name: string;
  Hex: string;
}

export function UpdateRecentColourRequest(token: string, body: UpdateRecentColourRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, UpdateRecentColourRequestModel>(
    '/knowledgebase/recentcolour',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface UpdateRecentProductRequestModel {
  RemoteId: string;
  Name: string;
  Brand: string;
  Packshot: string;
}

export function UpdateRecentProductRequest(token: string, body: UpdateRecentProductRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, UpdateRecentProductRequestModel>(
    '/knowledgebase/recentproduct',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface GetRecentSearchRequest {
  Page: number;
  Size: number;
}

export function GetRecentSearchRequest(token: string, body: GetRecentSearchRequest, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, GetRecentSearchRequest>(
    '/knowledgebase/recentsearch',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetRecentArticleRequest(token: string, body: GetRecentSearchRequest, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, GetRecentSearchRequest>(
    '/knowledgebase/search/recentarticle',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetRecentColourRequest(token: string, body: GetRecentSearchRequest, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, GetRecentSearchRequest>(
    '/knowledgebase/search/recentcolour',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetRecentProductRequest(token: string, body: GetRecentSearchRequest, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, GetRecentSearchRequest>(
    '/knowledgebase/search/recentproduct',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface CreateProductRequestModel {
  Title: string;
  Description: string;
  ImageUrl: string;
  Published: boolean;
  Brand: string;
  Product: string;
}

export function CreateProductRequest(token: string, body: CreateProductRequestModel, success: (data: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, CreateProductRequestModel>(
    '/npd',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface UpdateProductRequestModel {
  Id: string;
  Title: string;
  Description: string;
  ImageUrl: string;
  Published: boolean;
  Brand: string;
  Product: string;
}

export function UpdateProductRequest(token: string, body: UpdateProductRequestModel, success: (data: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, UpdateProductRequestModel>(
    '/npd/update',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface GetProductsRequestModel {
  Size: number;
  Page: number;
  Query?: string;
  Brand?: string;
  Product?: string;
  SortBy?: string;
}

type GetProductsResponseModel = {
  Count: number;
  Result: NPDItem[];
};

export function GetProductsRequest(token: string, body: GetProductsRequestModel, success: (data: ApiResponse<GetProductsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<GetProductsResponseModel, GetProductsRequestModel>(
    '/npd/search',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ReOrderProductRequestModel {
  Id: string;
}

export function ReOrderProductsRequest(token: string, body: ReOrderProductRequestModel[], success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, ReOrderProductRequestModel[]>(
    '/npd/updateOrders',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ChangeProductPublishStateRequestModel {
  Id: string;
}

export function ChangeProductPublishStateRequest(token: string, body: ChangeProductPublishStateRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, ChangeProductPublishStateRequestModel>(
    '/npd/changePublishState',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetProductRequest(token: string, id: string, success: (response: ApiResponse<Product>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<Product>(
    `/npd/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function DeleteProductRequest(token: string, id: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete<string>(
    `/npd/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ListProductsRequestModel {
  Size: number;
  Page: number;
  ServiceId: string;
  Query?: string;
  Brand?: string;
  Product?: string;
  SortBy?: string;
}

export interface ProductsListModel {
  Id: string;
  Order: number;
  Title: string;
  Description: string;
  ImageUrl: string;
  ThumbnailUrl: string;
  Brand: string;
  Product: string;
}

interface ListProductsResponseModel {
  Count: number;
  Result: ProductsListModel[];
}

export function ListProductsRequest(token: string, body: ListProductsRequestModel, success: (response: ApiResponse<ListProductsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<ListProductsResponseModel, ListProductsRequestModel>(
    '/npd/list',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface NewsletterAttachment {
  FileUrl: string;
  Size: number;
  FileName: string;
  ContentType: string;
}

export interface NewsletterRecipient {
  AudienceId: string;
  UserRoleId: string;
}

interface CreateNewsletterRequestModel {
  Subject: string;
  Body: string;
  Attachments?: NewsletterAttachment[];
  Recipients?: NewsletterRecipient[];
  MerchantId?: string;
  ManagerIds?: string[];
}

export function CreateNewsletterRequest(token: string, body: CreateNewsletterRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, CreateNewsletterRequestModel>(
    '/newsletter',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface GetNewsletterRepositoryRequestModel {
  Page: number;
  Query: string;
  StartDate?: Date;
  EndDate?: Date;
  HasAttachment?: boolean;
}

type GetNewsletterRepositoryResponseModel = {
  Count: number;
  Result: NewsletterGroup[];
};

type NewsletterGroup = {
  Group: string;
  Newsletters: NewsletterRepository[];
};

interface NewsletterRepository {
  Id: string;
  Subject: string;
  Body: string;
  SentDate: string;
  Attachments: NewsletterAttachments[];
}
interface NewsletterAttachments {
  FileName: string;
  FileUrl: string;
}

export function GetNewsletterRepository(token: string, body: GetNewsletterRepositoryRequestModel, success: (response: ApiResponse<GetNewsletterRepositoryResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<GetNewsletterRepositoryResponseModel, GetNewsletterRepositoryRequestModel>(
    '/newsletter/repository',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ListNewslettersRequestModel {
  Query?: string;
  StartDate?: Date;
  EndDate?: Date;
  AudienceIds?: string[];
  UserRoleIds?: string[];
  MerchantIds?: string[];
  MerchantManagerIds?: string[];
  Order?: string;
  Page: number;
  Size: number;
}

interface Attachment {
  FileName: string;
  FileUrl: string;
  Id: GUID;
}

interface AudienceUserRole {
  AudienceName: string;
  UserRoleName: string;
}

interface NewsletterItem {
  Body: string;
  Id: GUID;
  SentDate: Date;
  Subject: string;
}

interface RecipientMerchant {
  Name: string;
  Emails: string[];
}

interface RecipientMerchantManager {
  FirstName: string;
  LastName: string;
  Email: string;
  Merchant: string;
  MerchantEmail: string;
}

interface NewsletterData {
  Attachments: Attachment[];
  AudienceUserRoles: AudienceUserRole[];
  Newsletter: NewsletterItem;
  MerchantUsers: RecipientMerchant[];
  MerchantManagers: RecipientMerchantManager[];
}

export interface NewsletterResult {
  Count: number;
  Data: NewsletterData[];
  GroupName: string;
}

type ListNewslettersResponseModel = {
  Count: number;
  Result: NewsletterResult[];
};

export function ListNewslettersRequest(token: string, body: ListNewslettersRequestModel, success: (response: ApiResponse<ListNewslettersResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<ListNewslettersResponseModel, ListNewslettersRequestModel>(
    '/newsletter/search',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface MerchantManagersResponseModel {
  Group: string;
  Managers: Manager[];
}

interface Manager {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Merchant: string;
}

interface MerchantManagersRequestModel {
  Query: string;
  OrderBy: string;
}

export function GetMerchantManagers(token: string, body: MerchantManagersRequestModel, success: (response: ApiResponse<MerchantManagersResponseModel[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<any, MerchantManagersRequestModel>(
    '/newsletter/merchant-managers',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface RolesAudiencesResponseModel {
  Id: string;
  Name: string;
}

export function GetRolesRequest(token: string, success: (response: ApiResponse<RolesAudiencesResponseModel[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<RolesAudiencesResponseModel[]>(
    '/roles',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetAudiencesRequest(token: string, success: (response: ApiResponse<RolesAudiencesResponseModel[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<RolesAudiencesResponseModel[]>(
    '/audiences',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetMerchantOptionsRequest(token: string, success: (response: ApiResponse<RolesAudiencesResponseModel[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<RolesAudiencesResponseModel[]>(
    'newsletter/merchants',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetMerchantManagerOptionsRequest(token: string, success: (response: ApiResponse<RolesAudiencesResponseModel[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<RolesAudiencesResponseModel[]>(
    'newsletter/merchant-managers',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface SearchHomepageResponseModel {
  KnowledgeBase: KnowledgeBaseResult;
  ServiceContents: {
    GroupName: string;
    Service: {
      Id: GUID;
      Title: string;
      ImageUrl: string;
      PdfUrl: string;
      Published: Date;
    }[];
  }[];
  Services: {
    CategoryName: string;
    ServiceId: string;
    ServiceName: string;
    ImageUrl: string;
  }[];
  TotalServiceContentCount: number;
  TotalServiceCount: number;
}

export interface ArticleSection {
  Date: string;
  Link: string;
  Title: string;
}

export interface ProductSection {
  Title: string;
  Link: string;
  SubTitle: string;
  Img: string;
}

export interface GetSectionsResponseModel {
  Articles: ArticleSection[];
  Products: ProductSection[];
}

export function SearchHomepageRequest(token: string, body: GetKnowledgeBaseSearchRequestModel, success: (response: ApiResponse<SearchHomepageResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<SearchHomepageResponseModel, GetKnowledgeBaseSearchRequestModel>(
    '/home/search',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface AddRecentSearchRequestModel {
  Query: string;
}

export function AddRecentSearchRequest(token: string, body: AddRecentSearchRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, AddRecentSearchRequestModel>(
    '/home/addRecentSearch',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetHomepageRequest(token: string, success: (response: ApiResponse<GetSectionsResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<GetSectionsResponseModel>(
    '/home/sections',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetServicePermissions(serviceId: string, token: string, success: (response: ApiResponse<ServicePermission>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ServicePermission>(
    `/service/permission/${serviceId}`,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    undefined,
    true
  );
}

interface GetAdminRolesRequestModel {
  Query: string;
}

interface AdminRoleWithService {
  Role: string;
  HasContentAccess: boolean;
  HasAccessManagementAccess: boolean;
  Service: string;
  Category: string;
  Editable: boolean;
}

interface AdminRoleWithAdminService {
  Role: string;
  AdminService: string;
  Editable: boolean;
}

export interface GetAdminRolesResponseModel {
  Id: GUID;
  Role: string;
  AdminSection?: AdminRoleWithAdminService[];
  ServiceSection?: AdminRoleWithService[];
}

export interface GetAdminRolesResponse {
  GroupName: string;
  Roles: GetAdminRolesResponseModel[];
}

export function GetAdminRoles(token: string, body: GetAdminRolesRequestModel, success: (response: ApiResponse<GetAdminRolesResponse[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken: CancelToken): void {
  HttpRequestPost<GetAdminRolesResponse[], GetAdminRolesRequestModel>(
    '/admin/roles',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken
  );
}

export function ListServicesByCategory(token: string, success: (response: ApiResponse<ListService[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ListService[]>(
    '/service/list',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function ListAdminServices(token: string, success: (response: ApiResponse<ListAdminService[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ListAdminService[]>(
    '/service/listAdmin',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface CreateAdminRoleRequestService {
  AccessRights: boolean;
  Content: boolean;
  Id: GUID;
}

interface CreateAdminRoleRequestModel {
  Name: string;
  AdminServices: GUID[];
  Services: CreateAdminRoleRequestService[];
}

export function CreateAdminRole(token: string, body: CreateAdminRoleRequestModel, success: (response: ApiResponse<any>) => void, failure: (error: AxiosError<ErrorResponse | string>) => void): void {
  HttpRequestPost<any, CreateAdminRoleRequestModel>(
    '/admin/role',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
  );
}

export function DeleteAdminRole(token: string, role: string, success: (response: ApiResponse<any>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete(
    `/admin/role/${role}`,
    (response) => success(response.data),
    (error) => failure(error),
    token,
  );
}

interface EditAdminRoleRequestModel {
  Id: GUID;
  Name: string;
  AdminServices: GUID[];
  Services: CreateAdminRoleRequestService[];
}

export function EditAdminRole(token: string, body: EditAdminRoleRequestModel, success: (response: ApiResponse<any>) => void, failure: (error: AxiosError<ErrorResponse | string>) => void): void {
  HttpRequestPut<any, CreateAdminRoleRequestModel>(
    '/admin/role',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
  );
}

interface GetAdminUsersRequestModel {
  Query: string;
  GroupBy: string;
  RoleIds: GUID[];
  Page: number;
  Size: number;
  NoRole: boolean;
}

export interface GetAdminUsersResponseModel {
  Id: GUID;
  Email: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  Role: string;
  RoleId: GUID;
  Count: number;
  NoRoleCount: number;
  RegistrationPending: boolean;
}

export interface GetAdminUsersGrouped {
  Group: string;
  Users: GetAdminUsersResponseModel[];
}

export interface GetAdminUsersResponse {
  Count: number;
  Result: GetAdminUsersGrouped[];
}

export function GetAdminUsers(token: string, body: GetAdminUsersRequestModel, success: (response: ApiResponse<GetAdminUsersResponse>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<GetAdminUsersResponse, GetAdminUsersRequestModel>(
    '/admin/users',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken
  );
}

export interface ListAdminRole {
  Id: GUID;
  Name: string;
}

export function ListAvailableRoles(token: string, success: (response: ApiResponse<ListAdminRole[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ListAdminRole[]>(
    '/admin/listAvailableRoles',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function ListAllRoles(token: string, success: (response: ApiResponse<ListAdminRole[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ListAdminRole[]>(
    '/admin/listAllRoles',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface CreateAdminUserRequestModel {
  Email: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  RoleId: GUID;
}

export function CreateAdminUser(token: string, body: CreateAdminUserRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse | string>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<string, CreateAdminUserRequestModel>(
    '/admin/adminUser',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken
  );
}

interface EditAdminUserRequestModel {
  Id: GUID;
  FirstName: string;
  LastName: string;
  Phone: string;
  RoleId: GUID;
}

export function EditAdminUser(token: string, body: EditAdminUserRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse | string>) => void, cancelToken?: CancelToken): void {
  HttpRequestPut<string, EditAdminUserRequestModel>(
    '/admin/adminUser',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken
  );
}

export function DeleteAdminUser(token: string, id: GUID, success: (response: ApiResponse<any>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete(
    `/admin/adminUser/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token,
  );
}

interface UserSetupRequestModel {
  FirstName: string;
  LastName: string;
  Email: string;
  Phone?: string;
}

export function UserSetup(body: UserSetupRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse | string>) => void): void {
  HttpRequestPost<string, UserSetupRequestModel>(
    '/user-setup',
    body,
    (response) => success(response.data),
    (error) => failure(error)
  );
}

export function GetMarketingOption(resetToken: string, success: (response: ApiResponse<boolean>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<boolean>(
    `/getMarketingOption/${resetToken}`,
    (response) => success(response.data),
    (error) => failure(error)
  );
}

export interface UserSetupDetailResponseModel {
  Id: GUID;
  Email: string;
  MerchantId: GUID;
  IsManager: boolean;
}

export function UserSetupDetail(userId: GUID, success: (response: ApiResponse<UserSetupDetailResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<UserSetupDetailResponseModel>(
    `/user-setup-detail/${userId}`,
    (response) => success(response.data),
    (error) => failure(error),
  );
}

interface MerchantSetupRequestModel {
  Id: GUID;
  Logo: string;
  Name: string;
  AddressLine1: string;
  AddressLine2?: string;
  City: string;
  Postcode: string;
  County: string;
  Phone: string;
  Email: string;
}

export function MerchantSetup(body: MerchantSetupRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, MerchantSetupRequestModel>(
    '/merchant/setup',
    body,
    (response) => success(response.data),
    (error) => failure(error),
  );
}

export interface MerchantSetupDetailResponseModel {
  Name: string,
  PayerCode: string,
  Logo?: string;
  AddressLine1?: string;
  AddressLine2?: string;
  City?: string;
  Postcode?: string;
  County?: string;
  Phone?: string;
  Email?: string;
}

export function MerchantSetupDetail(merchantId: GUID, success: (response: ApiResponse<MerchantSetupDetailResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<MerchantSetupDetailResponseModel>(
    `/merchant/setup-detail/${merchantId}`,
    (response) => success(response.data),
    (error) => failure(error),
  );
}

export function GetUserMarketing(token: string, success: (response: ApiResponse<boolean>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<boolean>(
    '/userMarketing',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface SetUserMarketingRequestModel {
  Marketing: boolean;
}

export function SetUserMarketing(token: string, body: SetUserMarketingRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, SetUserMarketingRequestModel>(
    '/userMarketing',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface AddArticleModel {
  Title: string;
  Description: string;
  ImageUrl: string;
  Published: boolean;
  Attachments: Attachments[];
}

interface Attachments {
  Name: string;
  Url: string;
  Size: number;
}

export function AddNewArticle(token: string, body: AddArticleModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, AddArticleModel>(
    '/article',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface EditArticleModel {
  Id: string;
  Title: string;
  Description: string;
  ImageUrl: string;
  Published: boolean;
  Attachments: Attachments[];
}

export function EditFocusOfTheMonthArticleRequest(token: string, body: EditArticleModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPut<string, EditArticleModel>(
    '/article',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface GetArticlesRequestModel {
  Size: number;
  Page: number;
  Query: string;
  OrderBy: string;
  StartDate?: Date;
  EndDate?: Date;
  IsPublished?: boolean;
  HasAttachment?: boolean;
}

type GetArticlesResponseModel = {
  Count: number;
  Result: GroupedArticles[];
};

export function GetArticlesRequest(token: string, body: GetArticlesRequestModel, success: (data: ApiResponse<GetArticlesResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<GetArticlesResponseModel, GetArticlesRequestModel>(
    '/article/search',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ReOrderArticlesRequestModel {
  Id: string;
}

export function ReOrderArticlesRequest(token: string, body: ReOrderArticlesRequestModel[], success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, ReOrderArticlesRequestModel[]>(
    '/article/updateOrders',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ChangeArticlePublishStateRequestModel {
  Id: string;
}

export function ChangeArticlePublishStateRequest(token: string, body: ChangeArticlePublishStateRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, ChangeArticlePublishStateRequestModel>(
    '/article/changePublishState',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function DeleteArticleRequest(token: string, articleId: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestDelete<string>(
    `/article/${articleId}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface ListArticlesResponseModel {
  Id: GUID;
  Title: string;
  Description: string;
  ImageUrl: string;
  ThumbnailUrl: string;
  Published: Date;
  Attachments: ListArticlesAttachment[];
}

interface ListArticlesAttachment {
  AttachmentUrl: string;
  AttachmentName: string;
}

export function ListArticlesRequest(token: string, success: (response: ApiResponse<ListArticlesResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ListArticlesResponseModel>(
    '/article/list',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface CreateThreadAttachment {
  FileUrl: string;
  FileName: string;
  FileType: string;
}

interface CreateThreadRequestModel {
  Id: GUID;
  Subject: string;
  Body: string;
  Attachments?: CreateThreadAttachment[];
  Recipients: GUID[];
}

export function CreateThreadRequest(token: string, body: CreateThreadRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, CreateThreadRequestModel>(
    '/thread',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ThreadResponseModel {
  Count: number;
  Result: GroupedThread[];
}

export interface GroupedThread {
  Group: string;
  Threads: Thread[];
}

export interface Thread {
  Id: string;
  Subject: string;
  Body: string;
  FirstName: string;
  LastName: string;
  Recipients: ThreadRecipient[];
  Archived: boolean;
  Created: string;
  AttachmentCount: number;
  HasReplies: boolean;
  IsViewed: boolean;
}

export interface ThreadRecipient {
  FirstName: string;
  LastName: string;
  Merchant: string;
}

interface ThreadRequestModel {
  Query?: string;
  Size: number;
  Page: number;
  DateFrom?: Date;
  DateTo?: Date;
  HasAttachment?: boolean;
  IsArchived?: boolean;
  OrderBy: SortType;
  MerchantIds?: Array<string>;
  ManagerIds?: Array<string>;
}

export function GetTerritoryManagerThreads(token: string, body: ThreadRequestModel, success: (response: ApiResponse<ThreadResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<ThreadResponseModel, ThreadRequestModel>(
    '/thread/search/territory-manager',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken
  );
}

export function GetMerchantThreads(token: string, body: ThreadRequestModel, success: (response: ApiResponse<ThreadResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void, cancelToken?: CancelToken): void {
  HttpRequestPost<ThreadResponseModel, ThreadRequestModel>(
    '/thread/search/merchant',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken
  );
}

interface GetMerchantThreadDetailsRequestModel {
  ThreadId: string;
  Page: number;
  Size: number;
}

export interface MerchantThreadDetailResponseModel {
  Count: number;
  Result: GroupedThreadDetail;
}

export type GroupedThreadDetail = {
  Id: string;
  Subject: string;
  Comments: CommentDetail[];
  Archived: boolean;
  CommentCount: number;
};

export type CommentDetail = {
  Id: string;
  Body: string;
  Created: string;
  CreatedBy: GroupedRecipient;
  Attachments: ThreadAttachmentDetail[];
  Recipients: GroupedRecipient[];
};

export type GroupedRecipient = {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Merchant: string;
  Logo: string;
};

export type ThreadAttachmentDetail = {
  FileName: string;
  FileUrl: string;
  ThumbnailUrl: string;
  PreviewUrl: string;
  FileType: string;
};

export function GetThreadDetails(token: string, body: GetMerchantThreadDetailsRequestModel, success: (response: ApiResponse<GroupedThreadDetail>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<GroupedThreadDetail, GetMerchantThreadDetailsRequestModel>(
    '/thread/detail',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

type ReplyThreadRequestModel = {
  ThreadId: string;
  Body: string;
  Attachments: CreateThreadAttachment[] | null;
};

export function AddThreadComment(token: string, body: ReplyThreadRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, ReplyThreadRequestModel>(
    '/thread/reply',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface GetMerchantsRequest {
  Query?: string;
  OrderBy: string;
}


export function GetThreadMerchants(token: string, body: GetMerchantsRequest, success: (response: ApiResponse<MerchantGroup[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<MerchantGroup[], GetMerchantsRequest>(
    '/thread/merchants',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetShareMerchants(token: string, body: GetMerchantsRequest, success: (response: ApiResponse<MerchantGroup[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<MerchantGroup[], GetMerchantsRequest>(
    '/digitalasset/share/merchants',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetShareMerchantEmployees(token: string, body: GetMerchantsEmployeeRequest, success: (response: ApiResponse<GetMerchantsEmployeeResponse[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<GetMerchantsEmployeeResponse[], GetMerchantsEmployeeRequest>(
    '/digitalasset/share/merchant-users',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}


export interface ThreadMerchantEmployee {
  Id: GUID;
  FirstName: string;
  LastName: string;
  Email: string;
  Role: string;
}
export interface MerchantGroup {
  Group: string;
  Merchants: ThreadMerchant[];
}
export interface ThreadMerchant {
  Id: GUID;
  Name: string;
  Payercode: string;
  Logo: string;
}

export interface GetMerchantsEmployeeRequest {
  Query?: string;
  OrderBy: string;
  MerchantId: string;
}
export interface GetMerchantsEmployeeResponse {
  Group: string;
  Users: ThreadMerchantEmployee[];
}

export function GetThreadMerchantEmployees(token: string, body: GetMerchantsEmployeeRequest, success: (response: ApiResponse<GetMerchantsEmployeeResponse[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<GetMerchantsEmployeeResponse[], GetMerchantsEmployeeRequest>(
    '/thread/merchant-users',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}


export function ArchiveThread(token: string, threadId: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<string>(
    `thread/archive/${threadId}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface ThreadTerritoryManagersRequest {
  Query?: string;
  OrderBy: string;
  RoleIds: string[];
}

export interface ThreadTerritoryManagersResponse {
  Group: string;
  TerritoryManagers: ThreadTerritoryManagers[];
}

export interface ThreadTerritoryManagers {
  Id: GUID;
  FirstName: string;
  LastName: string;
  Email: string;
  RoleName: string;
  Merchants: string[];
}

export function GetThreadTerritoryManagers(token: string, body: ThreadTerritoryManagersRequest, success: (response: ApiResponse<ThreadTerritoryManagersResponse[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<ThreadTerritoryManagersResponse[], ThreadTerritoryManagersRequest>(
    '/portal-managers',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetAvailableTms(token: string, success: (response: ApiResponse<any>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<string>(
    '/thread/availabe-tms',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

/* Get Invited */

export interface MerchantRegisterRequest {
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  MerchantName: string;
  MerchantSAPCode?: string;
  StoreName?: string;
  StorePostCode?: string;
  StoreTerritoryManager?: string;
  RoleName: string;
}

export function MerchantGetInvited(body: MerchantRegisterRequest, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, MerchantRegisterRequest>(
    '/merchantregister',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    undefined
  );
}
export interface InvitationRequest {
  Query: string;
  Size: number;
  Page: number;
  OrderBy: string;
}

export type InvitationResponseModel = {
  Count: number;
  Result: InvitationGroup[];
};

export type InvitationGroup = {
  Group: string;
  MerchantRegistrations: PendingInvitation[];
};
export type PendingInvitation = {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  MerchantName: string;
  MerchantSAPCode: string;
  StoreName: string;
  StorePostCode: string;
  StoreTerritoryManager: string;
  RoleId: string;
  RoleName: string;
  Created: string;
  ProcessedById: string;
  ProcessedByName: string;
  Completed: string;
  Rejected: string;
  RejectionReason: string;
  RejectionInternalNotes: string;
};

export function GetInvitationsPending(token: string, body: InvitationRequest, success: (response: ApiResponse<InvitationResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<InvitationResponseModel, InvitationRequest>(
    '/merchantregister/search/pending',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetInvitationsCompleted(token: string, body: InvitationRequest, success: (response: ApiResponse<InvitationResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<InvitationResponseModel, InvitationRequest>(
    '/merchantregister/search/completed',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetInvitationsRejected(token: string, body: InvitationRequest, success: (response: ApiResponse<InvitationResponseModel>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<InvitationResponseModel, InvitationRequest>(
    '/merchantregister/search/rejected',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface PendingInvitationCountResponse {
  Count: number;
}

export function GetPendingInvitationCount(token: string, success: (response: ApiResponse<PendingInvitationCountResponse>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<PendingInvitationCountResponse>(
    '/merchantregister/pendingCount',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface InvitationDetailResponse {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  MerchantName: string;
  MerchantSAPCode: string;
  StoreName: string;
  StorePostCode: string;
  StoreTerritoryManager: string;
  RoleId: number;
  RoleName: string;
  Created: string;
  ProcessedById: string | null;
  ProcessedByName: string | null;
  Completed: string | null;
  Rejected: string | null;
  RejectionReason: string | null;
  RejectionInternalNotes: string | null;
  StoreCounty: string | null;
  StoreCity: string | null;
}

export function GetInvitationDetail(token: string, invitationId: string, success: (response: ApiResponse<InvitationDetailResponse>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<InvitationDetailResponse>(
    `/merchantregister/${invitationId}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function InvitationMarkAsComplete(token: string, invitationId: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse | string>) => void, cancelToken?: CancelToken): void {
  HttpRequestPut<string, undefined>(
    '/merchantregister/complete/' + invitationId,
    undefined,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken
  );
}

export interface InvitationSubmitRejectRequest {
  Id: string;
  Reason: string;
  InternalNotes: string | null;
}

export interface InvitationSubmitRejectResponse {
  Rejected: string;
}

export function InvitationSubmitReject(token: string, body: InvitationSubmitRejectRequest, success: (response: ApiResponse<InvitationSubmitRejectResponse>) => void, failure: (error: AxiosError<ErrorResponse | string>) => void, cancelToken?: CancelToken): void {
  HttpRequestPut<InvitationSubmitRejectResponse, InvitationSubmitRejectRequest>(
    '/merchantregister/reject',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token,
    cancelToken
  );
}

// Service Detail Page Requests

export function GetEmailNewsletterDetail(token: string, id: string, success: (response: ApiResponse<Newsletters>) => void): void {
  HttpRequestGet<Newsletters>(
    `/newsletter/${id}`,
    (response) => success(response.data),
    () => undefined,
    token
  );
}

export function GetFocusOfTheMonthDetail(token: string, id: string, success: (response: ApiResponse<Newsletters>) => void): void {
  HttpRequestGet<Newsletters>(
    `/article/${id}`,
    (response) => success(response.data),
    () => undefined,
    token
  );
}

export function GetLatestProductDetail(token: string, id: string, success: (response: ApiResponse<Product>) => void): void {
  HttpRequestGet<Product>(
    `/npd/${id}`,
    (response) => success(response.data),
    () => undefined,
    token
  );
}

export function GetIndustryInsightDetailRequest(token: string, id: string, success: (response: ApiResponse<ICurrentIndustryInsight>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<ICurrentIndustryInsight>(
    `/industryinsight/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

// Service Detail Page Requests

interface ShareDigitalToolkitRequestModel {
  Subject: string;
  Body: string;
  DigitalToolkitId: string;
  MerchantRecipientIds?: string[];
  PortalManagerRecipientIds?: string[];
}

export function ShareDigitalToolkitRequest(token: string, body: ShareDigitalToolkitRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, ShareDigitalToolkitRequestModel>(
    '/digitaltoolkit/share',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface ShareDigitalAssetRequestModel {
  Subject: string;
  Body: string;
  DigitalAssetIds: string[] | any;
  MerchantRecipientIds: string[];
  PortalManagerRecipientIds: string[];
}

export function ShareDigitalAssetRequest(token: string, body: ShareDigitalAssetRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, ShareDigitalAssetRequestModel>(
    '/digitalasset/share',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface IndustryEventsListRequestModel {
  Query?: string | null;
  Size: number;
  Page: number;
  OrderBy?: string;
  Filter?: any;
}

export interface IndustryEventsListResponseModel {
  Count: number;
  Result: IndustryEvent[];
}

export function GetIndustryEventsList(token: string, body: IndustryEventsListRequestModel, success: (response: ApiResponse<IndustryEventsListResponseModel>) => void, failure: (error: AxiosError<ErrorResponse | string>) => void): void {
  HttpRequestPost<IndustryEventsListResponseModel, IndustryEventsListRequestModel>(
    '/industry-events/list',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface IIndustryEventRegistrantsResponse {
  IndustryEventId: string;
  UserId: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Role: string;
  MerchantName: string;
}

export function IndustryEventRegistrants(token: string, id: string, success: (response: ApiResponse<IIndustryEventRegistrantsResponse[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<IIndustryEventRegistrantsResponse[]>(
    `/industry-events/${id}/registrants`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function IndustryEventTogglePublishState(token: string, id: string, success: (response: ApiResponse<any>) => void, failure: (error: AxiosError<ErrorResponse | string>) => void): void {
  HttpRequestPut<any, any>(
    `/industry-events/${id}/change-publish-state`,
    {},
    (response) => success(response.data),
    (error) => failure(error),
    token,
  );
}

export function RegisterIndustryEvent(token: string, id: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, unknown>(
    `/industry-events/${id}/register`,
    {},
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function GetIndustryEventDetails(token: string, id: string, success: (response: ApiResponse<IndustryEvent>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<IndustryEvent>(
    `/industry-events/${id}`,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export interface IIIndustryEventTargetsResponse {
  Id: number;
  Key: string;
  Name: string;
}

export function IndustryEventTargets(token: string, success: (response: ApiResponse<IIIndustryEventTargetsResponse[]>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestGet<IIIndustryEventTargetsResponse[]>(
    '/industry-events/targets',
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

interface EditIndustryEventRequestModel {
  Id: GUID | string;
  ImageUrl: string | undefined;
  IsPublished: boolean;
  Target: string;
}

export function EditIndustryEventRequest(token: string, body: EditIndustryEventRequestModel, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPut<string, EditIndustryEventRequestModel>(
    '/industry-events/edit',
    body,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function WatchIndustryEventRequest(token: string, id: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, unknown>(
    `/industry-events/${id}/watch`,
    {},
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function JoinIndustryEventRequest(token: string, id: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, unknown>(
    `/industry-events/${id}/join`,
    {},
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogIndustryInsightViewPdf(token: string, id: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, null>(
    `/industryinsight/logViewPdf/${id}`,
    null,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}

export function LogIndustryInsightDownloadPdf(token: string, id: string, success: (response: ApiResponse<string>) => void, failure: (error: AxiosError<ErrorResponse>) => void): void {
  HttpRequestPost<string, null>(
    `/industryinsight/logDownloadPdf/${id}`,
    null,
    (response) => success(response.data),
    (error) => failure(error),
    token
  );
}
