/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Alert, Container, useMediaQuery, CircularProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ButtonContainedPrimary } from '../components-atoms/ButtonComponents';
import { FormPageComponent } from '../components-molecules/FormPageComponent';
import AddIcon from '@mui/icons-material/Add';
import { IStyles, LIGHT_THEME } from '../constants/theme';
import { InfoComponentWithButton } from '../components-atoms/InfoComponentWithButton';
import ImagePreviewDialog from '../components-molecules/dialogs/ImagePreviewDialog';
import VideoPreviewDialog from '../components-molecules/dialogs/VideoPreviewDialog';
import { PdfPreviewModal } from './modals/PdfPreviewModal';
import { CommentDetail, GetThreadDetails, GroupedThreadDetail } from '../service/serviceService';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import ThreadItem from '../components-molecules/ThreadItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LogDocumentLibraryAttachmentDownload, LogDocumentLibraryView } from '../service/logService';

export default function ThreadDetails(): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const token = useTypedSelector(state => state.userReducer.token);
  const userData = useTypedSelector((state) => state.userReducer.data);
  const superData = useTypedSelector((state) => state.userReducer.super);
  const [errorMessage, ] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const size = 3;
  const [threadDetailData, setThreadDetailData] = useState<GroupedThreadDetail|null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const [imagePreviewDialogOpen, setImagePreviewDialogOpen] = useState(false);
  const [videoPreviewDialogOpen, setVideoPreviewDialogOpen] = useState(false);
  const [pdfPreviewModalVisible, setPdfPreviewModalVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string|null>(null);
  const [fileUrl, setFileUrl] = useState<string|null>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string|null>(null);
  const [fileName, setFileName] = useState<string|null>(null);
  useEffect(() => {
    if (token && id) {
      setLoading(true);
      GetThreadDetails(token, {
        ThreadId: id,
        Page: page,
        Size: size
      },
      response => {
        setThreadDetailData(response.Result);
        setLoading(false);
        setTotalCount(response.Result.CommentCount);

        LogDocumentLibraryView(token, {
          Id: response.Result.Id,
          Title: response.Result.Subject
        },
        () => { return; },
        () => { return; }
        );
      },
      error => {
        setLoading(false);
        if(error.response?.data.Error==='Not allowed'){
          navigate('/unauthorized');
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCommentClicked = () => {
    navigate(userData && userData.IsAdmin && !superData
      ? '/admin/thread-new-comment' : '/thread-new-comment',
    { state: { 
      navigateBackTo: window.location.pathname, 
      thread: threadDetailData,
      prevNavigateBackTo: state?.navigateBackTo
        ? state.navigateBackTo : userData && userData.IsAdmin && !superData
          ? userData.IsTerritoryManager || userData.IsSalesManager
            ? '/admin/document-repository'
            : '/admin/merchants'
          : '/',
      currentTab: state?.currentTab ? state.currentTab : 'content'
    } });
  };

  const sendEmailClicked = () => {
    window.location.href = `mailto:${threadDetailData?.Comments[0].CreatedBy.Email}?subject=${''}&body=${window.location.href}`;
  };

  const attachmentClicked = (type: string, preview: string, file: string, thumbnail: string, fileName: string) => {
    if(preview === null){
      return;
    }
    if(token && id && file) {
      LogDocumentLibraryAttachmentDownload(token, {
        Id: id,
        FileUrl: file
      },
      () => { return; },
      () => { return; }
      );
    }
    setPreviewUrl(preview);
    setFileUrl(file);
    setThumbnailUrl(thumbnail);
    setFileName(fileName);
    switch (type) {
      case 'video':
        setVideoPreviewDialogOpen(true);
        break;
      case 'image':
        setImagePreviewDialogOpen(true);
        break;
      case 'pdf':
        navigate('/document-preview',{ state: { 
          navigateBackTo: window.location.pathname, 
          url: preview,
          thread: threadDetailData,
          prevNavigateBackTo: state?.navigateBackTo
            ? state.navigateBackTo : userData && userData.IsAdmin && !superData
              ? userData.IsTerritoryManager || userData.IsSalesManager
                ? '/admin/document-repository'
                : '/admin/merchants'
              : '/',
          currentTab: state?.currentTab ? state.currentTab : 'content'
        } });
        break;
      default:
        break;
    }
  };

  const threadDetail = () => {
    if (threadDetailData) {
      return threadDetailData?.Comments.map(item => <ThreadItem key={item.Id} data={item} attachmentClicked={attachmentClicked} subject={threadDetailData.Subject} />);
    }
    return <></>;
  };

  const loadMore = () => {
    setPage(prev => prev + 1);
    if (token && id) {
      GetThreadDetails(token, {
        ThreadId: id,
        Page: page+1,
        Size: size
      },
      response => {
        let oldComments: CommentDetail[] = [];
        if (threadDetailData) {
          oldComments = threadDetailData.Comments;
        }
        const comments: CommentDetail[] = [...oldComments, ...response.Result.Comments];
        const result: GroupedThreadDetail = {...response.Result, Comments:comments};
        setThreadDetailData(result);
        setLoading(false);
        setTotalCount(response.Result.CommentCount);
      },
      () => {
        setLoading(false);
      });
    }
  };

  const downloadClicked = () => {
    if (fileUrl) {
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileName ? fileName : '';
      document.body.appendChild(a); 
      a.click();    
      a.remove();
    }
  };

  const userEmail = userData?.Email;
  const canReply
    = threadDetailData?.Comments.some(comment => comment.Recipients.some(recipient => recipient.Email === userEmail))
    || threadDetailData?.Comments.some(comment => comment.CreatedBy.Email === userEmail);

  return (
    <>
      <FormPageComponent
        subPageHeaderStyle={{
          marginLeft: '-4px',
          marginRight: '-8px'
        }}
        hasError={!!errorMessage}
        title={threadDetailData && threadDetailData.Subject || ''}
        buttonLoading={loading}
        close={() => navigate(state?.navigateBackTo
          ? state.navigateBackTo : userData && userData.IsAdmin && !superData
            ? userData.IsTerritoryManager || userData.IsSalesManager
              ? '/admin/document-repository'
              : '/admin/merchants'
            : '/',
        { state: { currentTab: state?.currentTab || 'content' } })}
        
        submitButton={false}
        buttons={[
          (userData && userData.IsAdmin && !userData.IsTerritoryManager && !userData.IsSalesManager) || (userData && userData.IsAdmin && !!superData) || (userData && userData.IsSalesManager && !canReply)
            ? <></>
            : <ButtonContainedPrimary 
              key={new Date().toString()}
              onClick={addCommentClicked}
              disabled={threadDetailData && threadDetailData.Archived || false}
              startIcon={<AddIcon />}
              sx={{ height: '42px', display: threadDetailData ? undefined : 'none' }}
            >
              Add Comment
            </ButtonContainedPrimary>,
        ]}
      >
        <Fragment>
          <Grid item sx={{ flexGrow: 1 }}>
            {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
            }
          </Grid>
          {threadDetailData && threadDetailData.Archived && <Grid style={styles.infoComponentContainer}>
            <InfoComponentWithButton
              title='Thread Archived'
              description='This thread has been archived and you are no longer able 
              to post any new comments or upload further files. If you require more
              information about this topic please send us an email.'
              buttonText='Send Email'
              onClick={sendEmailClicked}
            />
          </Grid>}
          <Grid style={styles.container} sx={{ '& > div:first-child': { width: '100%' } }}> 
            <InfiniteScroll
              style={{ overflowY: 'hidden' }}
              dataLength={threadDetailData?.Comments.length || 0} //This is important field to render the next data
              next={() => loadMore()}
              scrollThreshold={smallDown ? '550px' : '50px'}
              hasMore={(threadDetailData?.Comments.length || 0) < totalCount}
              loader={
                <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
                  <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
                </Container>
              }
            >
              {loading 
                ? <Grid container item width={'100%'} justifyContent="center" alignItems="center">
                  <CircularProgress
                    color="primary"
                    size={50}
                    style={{ zIndex: 999, margin: '56px 0' }}
                  />
                </Grid>
                : threadDetail()
              }
            </InfiniteScroll>
          </Grid>
        </Fragment>
      </FormPageComponent>
      <ImagePreviewDialog
        downloadClicked={downloadClicked}
        onClose={() => {
          setImagePreviewDialogOpen(false);
          setPreviewUrl(null);
          setFileUrl(null);
        }}
        open={imagePreviewDialogOpen}
        url={previewUrl ? previewUrl : ''}
        errorUrl={thumbnailUrl ? thumbnailUrl : ''}
      />
      <VideoPreviewDialog
        downloadClicked={downloadClicked} 
        onClose={() => {
          setVideoPreviewDialogOpen(false);
          setPreviewUrl(null);
          setFileUrl(null);
        }}
        open={videoPreviewDialogOpen}
        thumbnail={thumbnailUrl ? thumbnailUrl : ''}
        url={previewUrl ? previewUrl : ''}
      />
      <PdfPreviewModal
        visible={pdfPreviewModalVisible} 
        close={() => {
          setPreviewUrl(null);
          setFileUrl(null);
          setPdfPreviewModalVisible(false);
        }}
        url={previewUrl ? previewUrl : ''}
      />
    </>
  );
}

const styles: IStyles = {
  infoComponentContainer: {
    maxWidth: '840px',
    width: '100%',
    marginBottom:'16px',
    margin: '0 auto',
    padding: '40px 16px 0'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '840px',
    width: '100%',
    margin: '0 auto',
    padding: '40px 16px 0'
  }
};
