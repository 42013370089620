/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Alert, LinearProgress, Autocomplete, TextField, Chip, createFilterOptions } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormControlImage, FormControlInput } from '../../components-molecules/FormControl';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { FILE_VALIDATION_WITH_TYPE, REQUIRED_VALIDATION } from '../../forms/_predefinedValidations';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { environment } from '../../environment';
import { IStyles } from '../../constants/theme';
import { GetRequest, UpdateRequest } from '../../service/digitalToolkitService';
import RestrictionUsers, { IRestrictionUserAction, IRestrictionUserSource } from './RestrictionUsers';
import SaveChanges from './SaveChanges';
import { GetBrands, GetProducts } from '../../service/serviceService';

interface EditDigitalToolkitModel {
  title: string;
  description: string;
  packshot: File | string;
  published: boolean;
  AudienceUserRole: IVisibilityRestrictionAudienceRole[];
  MerchantUserRole: IVisibilityRestrictionMerchantRole[];
  brand: string | undefined | null;
  product: string | undefined | null;
}

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
}

export function EditDigitalToolkit(): JSX.Element {
  const buttonGroupOptions: IOption[] = [
    { title: 'Published', color: 'success', value: true },
    { title: 'Unpublished', color: 'error', value: false }
  ];
  const token = useTypedSelector(state => state.userReducer.token);
  const digitalToolkit = useTypedSelector(state => state.digitalToolkitReducer.currentData);
  const [defaultDigitalToolkit] = useState<EditDigitalToolkitModel>({
    title: digitalToolkit.Title,
    description: digitalToolkit.Description,
    packshot: digitalToolkit.FileUrl,
    published: digitalToolkit.Published,
    brand: digitalToolkit.Brand || undefined,
    product: digitalToolkit.Product || undefined,
    AudienceUserRole: digitalToolkit.VisibilityRestrictions?.AudienceUserRole || [],
    MerchantUserRole: digitalToolkit.VisibilityRestrictions?.MerchantUserRole || []
  });

  const [errorMessage, setErrorMessage] = useState<string>('');
  const { control, handleSubmit, formState: { isValid, isDirty }, setValue, getValues } = useForm<any>({ mode: 'all', reValidateMode: 'onChange', defaultValues: defaultDigitalToolkit });
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [brands, setBrands] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { id } = useParams();
  const filter = createFilterOptions<any>();

  const [selectedAudiences, setSelectedAudiences] = useState(digitalToolkit.VisibilityRestrictions?.AudienceUserRole || []);
  const [selectedMerchants, setSelectedMerchants] = useState(digitalToolkit.VisibilityRestrictions?.MerchantUserRole || []);

  useEffect(() => {
    if (token && id) {
      GetRequest(
        token,
        id,
        (response) => {
          if (response) {
            dispatch({
              type: 'SET_CURRENT_DIGITALTOOLKIT',
              payload: { currentData: response.Result },
            });
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  }, [token, id, dispatch]);

  // Load brands and products
  useEffect(() => {
    if (token) {
      GetBrands(
        token,
        (response) => {
          if (response && response.Result) {
            setBrands(response.Result.map((brand: any) => ({
              name: brand.Name,
              value: brand.Id
            })));
            if (digitalToolkit.Brand) {
              console.log(digitalToolkit.Brand);
              setValue('brand', digitalToolkit.Brand, { shouldDirty: false, shouldValidate: true });
            }
          }
        },
        (error) => {
          console.error('Error fetching brands:', error);
        }
      );

      GetProducts(
        token,
        (response) => {
          if (response && response.Result) {
            setProducts(response.Result.map((product: any) => ({
              name: product.Name,
              value: product.Id
            })));
            if (digitalToolkit.Product) {
              console.log(digitalToolkit.Product);
              setValue('product', digitalToolkit.Product, { shouldDirty: false, shouldValidate: true });
            }
          }
        },
        (error) => {
          console.error('Error fetching products:', error);
        }
      );
    }
  }, [token]);

  useEffect(() => {
    if (digitalToolkit) {
      setValue('title', digitalToolkit.Title, { shouldDirty: false, shouldValidate: true });
      setValue('description', digitalToolkit.Description, { shouldDirty: false, shouldValidate: true });
      setValue('published', digitalToolkit.Published, { shouldDirty: false, shouldValidate: true });
      setValue('brand', digitalToolkit.Brand || undefined, { shouldDirty: false, shouldValidate: true });
      setValue('product', digitalToolkit.Product || undefined, { shouldDirty: false, shouldValidate: true });
      setValue('packshot', digitalToolkit.FileUrl || undefined, { shouldDirty: false, shouldValidate: true });
    }
  }, [digitalToolkit, setValue]);

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);

  const handleRestrictionChange = ({ selectedAudiences, selectedMerchants }: any) => {
    setValue('AudienceUserRole', selectedAudiences, { shouldDirty: true });
    setValue('MerchantUserRole', selectedMerchants, { shouldDirty: true });
    setSelectedAudiences(selectedAudiences);
    setSelectedMerchants(selectedMerchants);
  };

  const submit: SubmitHandler<EditDigitalToolkitModel> = async (data) => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);

      let fileName;
      let fileUrl;

      if (data.packshot instanceof File) {
        fileName = await UploadToAzure({
          data: [data.packshot],
          onProgress: onProgress,
          folderName: environment.digitalToolkitFolder
        });
        fileUrl = fileName[0].Url;
      } else {
        const url: string = data?.packshot;
        const urlArray = url.split('/');
        fileUrl = urlArray[urlArray.length - 1];
      }

      UpdateRequest(
        token,
        {
          Id: digitalToolkit.Id,
          Title: data.title,
          Description: data.description,
          FileUrl: fileUrl,
          Published: data.published,
          Brand: data.brand || undefined,
          Product: data.product || undefined,
          VisibilityRestrictions: {
            AudienceUserRole: data?.AudienceUserRole || [],
            MerchantUserRole: data?.MerchantUserRole || []
          }
        },
        () => {
          setLoading(false);
          navigate(-1);
        },
        (error) => {
          setErrorMessage(error.response?.data.Error || '');
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses = tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return { ...x, percentage: data.percentage };
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;

    loadingProgress.forEach(x => {
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };

  const handleClose = () => {
    if (isDirty) {
      setShowSaveModal(true);
      return;
    }

    navigate(-1);
  };

  const onSaveChangesSubmit = () => {
    setShowSaveModal(false);
    navigate(-1);
  };

  return (
    <FormPageComponent
      hasError={!!errorMessage}
      title="Edit Toolkit"
      buttonTitle="Submit"
      buttonDisabled={!isValid}
      close={handleClose}
      submit={handleSubmit(submit)}
      scrollToTop={scrollToTop}
    >
      {loading
        ? <LinearProgress variant="determinate" color="secondary" value={percentage} sx={{ zIndex: 2 }} />
        : <></>
      }
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>

        <ModalCardComponent>
          <SectionHeader title="Toolkit details" />
          <Fragment>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlImage
                  accept=".pdf"
                  data-testid="add-toolkit-packshot"
                  control={control}
                  initialValue={digitalToolkit.ThumbnailUrl || digitalToolkit.FileUrl}
                  initialFileType='image'
                  field={{
                    label: 'Toolkit PDF',
                    name: 'packshot',
                    inputType: 'logo',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: FILE_VALIDATION_WITH_TYPE(50, '.pdf')
                    }
                  }}
                  title="Toolkit PDF"
                  subtitle="Upload the Toolkit PDF."
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <FormControlInput
                  data-testid="add-toolkit-title"
                  control={control}
                  field={{
                    label: 'Title',
                    name: 'title',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <FormControlInput
                  data-testid="add-toolkit-description"
                  control={control}
                  multiline
                  field={{
                    label: 'Description',
                    name: 'description',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <Autocomplete
                  data-testid="edit-toolkit-brand"
                  {...control.register('brand')}
                  value={getValues('brand')}
                  onChange={(e, newValue) => {
                    if (typeof newValue === 'string') {
                      setValue('brand', newValue, { shouldDirty: true, shouldValidate: true });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue('brand', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                    } else {
                      setValue('brand', newValue, { shouldDirty: true, shouldValidate: true });
                    }
                  }}
                  id="brands-filled"
                  options={brands.map((option) => option.name)}
                  freeSolo
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="brand"
                      variant="filled"
                      label="Brand"
                      placeholder="Brand"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <Autocomplete
                  data-testid="edit-toolkit-product"
                  {...control.register('product')}
                  value={getValues('product')}
                  onChange={(e, newValue) => {
                    if (typeof newValue === 'string') {
                      setValue('product', newValue, { shouldDirty: true, shouldValidate: true });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue('product', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                    } else {
                      setValue('product', newValue, { shouldDirty: true, shouldValidate: true });
                    }
                  }}
                  id="products-filled"
                  options={products.map((option) => option.name)}
                  freeSolo
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="product"
                      variant="filled"
                      label="Product"
                      placeholder="Product"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                <AssetStatusButtonGroup
                  subtitle="Published toolkits are visible to customers and unpublished toolkits are hidden."
                  title="Toolkit Status"
                  value={getValues('published')}
                  data-testid="add-toolkit-published"
                  onChange={(value: boolean) => setValue('published', value, { shouldDirty: true, shouldValidate: true })}
                  options={buttonGroupOptions}
                />
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>

        <RestrictionUsers
          service={IRestrictionUserSource.DigitalToolkits}
          action={IRestrictionUserAction.Update}
          selectedAudiences={selectedAudiences}
          selectedMerchants={selectedMerchants}
          onChange={handleRestrictionChange} />

        <SaveChanges
          visible={showSaveModal}
          onCancel={() => setShowSaveModal(false)}
          onSubmit={onSaveChangesSubmit}
        />;

        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    </FormPageComponent>
  );
}

const styles: IStyles = {
  fg1: { flexGrow: 1 },
  mb16: { marginBottom: '16px' },
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
