import { useMediaQuery, Container, CircularProgress, Grid, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { debounce } from 'lodash';
import { useState, useEffect, useRef, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LIGHT_THEME } from '../constants/theme';
import { ProductsListModel, GetNPDBrands, GetNPDProducts } from '../service/serviceService';
import { ListingCard } from './cards/listing/ListingCard';
import { useTypedSelector } from '../hooks/TypedReduxHooks';
import { SearchbarWithDeboubce } from '../components-molecules/SearchBarWithDebounce';

interface IProps {
  products: ProductsListModel[];
  loadMore: () => void;
  total: number;
  onFilterChange?: (brand: string, product: string, sortOption: string, query: string) => void;
}

export function NPDServiceList({ products, loadMore, total, onFilterChange }: IProps): JSX.Element {
  const timeoutDuration = 300;
  const smallDown = useMediaQuery(LIGHT_THEME.breakpoints.down('sm'));
  const token = useTypedSelector((state) => state.userReducer.token);
  const [brands, setBrands] = useState<any[]>([]);
  const [productOptions, setProductOptions] = useState<any[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [sortOption, setSortOption] = useState<string>('date');
  const [query, setQuery] = useState<string>('');
  
  // Use refs to track previous values
  const prevBrandRef = useRef('');
  const prevProductRef = useRef('');
  const prevSortRef = useRef('date');
  const prevQueryRef = useRef('');
  const initialRenderRef = useRef(true);

  // Load brands and products
  useEffect(() => {
    if (token) {
      GetNPDBrands(
        token,
        (response) => {
          if (response && response.Result) {
            setBrands(response.Result);
          }
        },
        (error) => {
          console.error('Error fetching brands:', error);
        }
      );

      GetNPDProducts(
        token,
        (response) => {
          if (response && response.Result) {
            setProductOptions(response.Result);
          }
        },
        (error) => {
          console.error('Error fetching products:', error);
        }
      );
    }
  }, [token]);

  // Notify parent component of filter changes
  const notifyFilterChange = useCallback(() => {
    if (onFilterChange) {
      onFilterChange(selectedBrand, selectedProduct, sortOption, query);
    }
  }, [onFilterChange, selectedBrand, selectedProduct, sortOption, query]);

  // Handle filter changes
  useEffect(() => {
    // Skip the first render to prevent initial double-fetching
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      
      // Update refs with initial values
      prevBrandRef.current = selectedBrand;
      prevProductRef.current = selectedProduct;
      prevSortRef.current = sortOption;
      prevQueryRef.current = query;
      return;
    }
    
    // Only call onFilterChange if any value has actually changed
    const hasChanged 
      = prevBrandRef.current !== selectedBrand 
      || prevProductRef.current !== selectedProduct 
      || prevSortRef.current !== sortOption 
      || prevQueryRef.current !== query;
    
    if (hasChanged) {
      // Update refs with current values
      prevBrandRef.current = selectedBrand;
      prevProductRef.current = selectedProduct;
      prevSortRef.current = sortOption;
      prevQueryRef.current = query;
      
      // Call the callback with current values
      notifyFilterChange();
    }
  }, [selectedBrand, selectedProduct, sortOption, query, notifyFilterChange]);

  const searchOnChange = useCallback((text: string) => {
    setQuery(text);
  }, []);

  const handleBrandChange = useCallback((value: string) => {
    setSelectedBrand(value);
  }, []);

  const handleProductChange = useCallback((value: string) => {
    setSelectedProduct(value);
  }, []);

  const handleSortChange = useCallback((value: string) => {
    setSortOption(value);
  }, []);

  const handleSearchCancel = useCallback(() => {
    setQuery('');
  }, []);

  const debouncedLoadMore = useCallback(() => {
    debounce(() => loadMore(), timeoutDuration)();
  }, [loadMore, timeoutDuration]);

  return (
    <Grid sx={{ width: '100%' }} px={{ xs: '20px', sm: 4 }}>
      {/* Search Bar */}
      <Box sx={{ mb: 2 }}>
        <SearchbarWithDeboubce
          isOpen={true}
          query={query}
          placeholder="Search Products"
          onCancel={handleSearchCancel}
          onChange={searchOnChange}
        />
      </Box>
      
      {/* Filters and Sorting */}
      <Grid container spacing={2} sx={{ mt: 2, mb: 3 }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel>Brand</InputLabel>
            <Select
              value={selectedBrand}
              onChange={(e) => handleBrandChange(e.target.value)}
            >
              <MenuItem value="">All Brands</MenuItem>
              {brands.map((brand) => (
                <MenuItem key={brand.Id} value={brand.Name}>
                  {brand.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel>Product</InputLabel>
            <Select
              value={selectedProduct}
              onChange={(e) => handleProductChange(e.target.value)}
            >
              <MenuItem value="">All Products</MenuItem>
              {productOptions.map((product) => (
                <MenuItem key={product.Id} value={product.Name}>
                  {product.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortOption}
              onChange={(e) => handleSortChange(e.target.value)}
            >
              <MenuItem value="date">Date (Newest First)</MenuItem>
              <MenuItem value="date_asc">Date (Oldest First)</MenuItem>
              <MenuItem value="brand_az">Brand (A-Z)</MenuItem>
              <MenuItem value="brand_za">Brand (Z-A)</MenuItem>
              <MenuItem value="product_az">Product (A-Z)</MenuItem>
              <MenuItem value="product_za">Product (Z-A)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <InfiniteScroll
        style={{ overflowY: 'hidden' }}
        dataLength={products.length} //This is important field to render the next data
        next={debouncedLoadMore}
        scrollThreshold={smallDown ? '550px' : '50px'}
        hasMore={products.length !== total}
        loader={
          <Container maxWidth="sm" sx={{ position: 'relative', marginTop: '50px', paddingBottom: '80px' }}>
            <CircularProgress sx={{ position: 'absolute', top: '30%', left: '50%' }} size={30} />
          </Container>
        }
      >
        {products.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <p>No products found matching your criteria.</p>
          </Box>
        ) : (
          products.map((item, index) => (
            <ListingCard
              key={item.Id}
              title={item.Title}
              description={item.Description}
              thumbnailUrl={item.ThumbnailUrl}
              url={'/detail/npd/' + item.Id}
              isFirst={index === 0}
              isLast={index === products.length - 1}
              caption1={{
                title: 'Brand',
                content: item.Brand,
                color: 'primary'
              }}
              caption2={{
                title: 'Product',
                content: item.Product,
                color: 'primary'
              }} />
          ))
        )}
      </InfiniteScroll>
    </Grid>);
}
