import { useEffect, useState } from 'react';

import { UserDivider } from '../utils/arrayDividers';

import { ListDivider } from '../components-molecules/Divider';
import { EmployeeCard } from '../components-organisms/cards/EmployeeCard';
import { Box } from '@mui/material';

export function EmployeesList({ employees, navigateBackTo, searchQuery = '' }: { employees: MerchantUser[]; navigateBackTo: string; searchQuery?: string }): JSX.Element {
  const [sectionedlist, setSectionedList] = useState<DividedList<MerchantUser>>({});

  useEffect(() => {
    if (employees.length > 0) {
      const filtered = searchQuery 
        ? employees.filter(employee => 
          employee.FirstName.toLowerCase().includes(searchQuery.toLowerCase()) 
          || employee.LastName.toLowerCase().includes(searchQuery.toLowerCase())
          || employee.Email.toLowerCase().includes(searchQuery.toLowerCase()))
        : employees;
      
      setSectionedList({ ...UserDivider<MerchantUser>(filtered) });
    }
  }, [employees, searchQuery]);

  return (
    <div style={{ paddingBottom: 72 }}>
      {Object.keys(sectionedlist).length > 0 ? (
        Object.keys(sectionedlist).sort().map((section, sectionIndex) => {
          return (
            <ListDivider
              key={sectionIndex}
              division={{ name: section, length: sectionedlist[section].length }}
              caption={{ singular: 'Store User', plural: 'Store Users' }}
            >
              {sectionedlist[section].map((item, itemIndex) => <EmployeeCard key={itemIndex} employee={item} navigateBackTo={navigateBackTo} />)}
            </ListDivider>
          );
        })
      ) : (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          No employees match your search criteria.
        </Box>
      )}
    </div>
  );
}
