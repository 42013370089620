/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, Alert, LinearProgress, Autocomplete, TextField, Chip, createFilterOptions } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormControlImage, FormControlInput } from '../../components-molecules/FormControl';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { REQUIRED_VALIDATION, FILE_VALIDATION_WITH_TYPE } from '../../forms/_predefinedValidations';
import { useTypedDispatch, useTypedSelector } from '../../hooks/TypedReduxHooks';
import { GetProductRequest, UpdateProductRequest } from '../../service/serviceService';
import { AssetStatusButtonGroup } from '../AssetStatusButtonGroup';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { IStyles } from '../../constants/theme';
import { UploadToAzure } from '../../hooks/uploadToAzure';
import { environment } from '../../environment';
import SaveChanges from './SaveChanges';
import { GetBrands, GetProducts } from '../../service/serviceService';
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte';

interface EditProductModel {
  title: string;
  body: string;
  packshot: File | string;
  published: boolean;
  brand: string;
  product: string;
}

interface IOption {
  title: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  value: boolean;
}

interface onProgressResponse {
  fileName: string;
  percentage: number;
}

const TOOLBAR_CONFIG: ToolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS',],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Paragraph', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-five' },
    { label: 'Heading Small', style: 'header-six' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Bullet List', style: 'unordered-list-item' },
    { label: 'Numbered List', style: 'ordered-list-item' }
  ]
};

export function EditProduct(): JSX.Element {
  const buttonGroupOptions: IOption[] = [
    { title: 'Published', color: 'success', value: true },
    { title: 'Unpublished', color: 'error', value: false }
  ];
  const token = useTypedSelector(state => state.userReducer.token);
  const product = useTypedSelector(state => state.npdReducer.currentData);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { control, handleSubmit, formState: { isValid, isDirty }, setValue, getValues } = useForm({ mode: 'all', reValidateMode: 'onChange' });
  const [loading, setLoading] = useState(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<onProgressResponse[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [brands, setBrands] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [html, setHtml] = useState<EditorValue>(RichTextEditor.createValueFromString(product?.Description || '', 'html'));
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { id } = useParams();
  const filter = createFilterOptions<any>();

  useEffect(() => {
    if (token && id) {
      GetProductRequest(
        token,
        id,
        (response: any) => {
          if (response) {
            dispatch({
              type: 'SET_CURRENT_PRODUCT',
              payload: { currentData: response.Result },
            });
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  }, [token, id, dispatch]);

  // Load brands and products
  useEffect(() => {
    if (token) {
      GetBrands(
        token,
        (response: any) => {
          if (response && response.Result) {
            setBrands(response.Result.map((brand: any) => ({
              name: brand.Name,
              value: brand.Id
            })));
            if (product.Brand) {
              setValue('brand', product.Brand, { shouldDirty: false, shouldValidate: true });
            } else {
              setValue('brand', '', { shouldDirty: false, shouldValidate: true });
            }
          }
        },
        (error) => {
          console.error('Error fetching brands:', error);
        }
      );

      GetProducts(
        token,
        (response: any) => {
          if (response && response.Result) {
            setProducts(response.Result.map((product: any) => ({
              name: product.Name,
              value: product.Id
            })));
            if (product.Product) {
              setValue('product', product.Product, { shouldDirty: false, shouldValidate: true });
            } else {
              setValue('product', '', { shouldDirty: false, shouldValidate: true });
            }
          }
        },
        (error) => {
          console.error('Error fetching products:', error);
        }
      );
    }
  }, [token, product, setValue]);

  useEffect(() => {
    if (product) {
      setValue('title', product.Title || '', { shouldDirty: false, shouldValidate: true });
      setValue('body', product.Description || '', { shouldDirty: false, shouldValidate: true });
      setValue('published', product.Published, { shouldDirty: false, shouldValidate: true });
      setValue('brand', product.Brand || '', { shouldDirty: false, shouldValidate: true });
      setValue('product', product.Product || '', { shouldDirty: false, shouldValidate: true });
      setValue('packshot', product.ThumbnailUrl || product.ImageUrl, { shouldDirty: false, shouldValidate: true });
      setHtml(RichTextEditor.createValueFromString(product.Description || '', 'html'));
    }
  }, [product, setValue]);

  useEffect(() => {
    calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProgress]);

  const submit: SubmitHandler<EditProductModel> = async (data) => {
    if (token) {
      setLoading(true);
      setScrollToTop(true);

      let fileName;
      let fileUrl;

      if (typeof data.packshot === 'object') {
        fileName = await UploadToAzure({
          data: [data.packshot],
          onProgress: onProgress,
          folderName: environment.productFolder
        });
        fileUrl = fileName[0].Url;
      } else {
        const url: string = data.packshot;
        const urlArray = url.split('/');
        fileUrl = urlArray[urlArray.length - 1];
      }
      
      UpdateProductRequest(
        token,
        {
          Id: product.Id,
          Title: data.title,
          Description: data.body,
          ImageUrl: fileUrl,
          Published: data.published,
          Brand: data.brand,
          Product: data.product,
        },
        () => {
          setLoading(false);
          navigate(-1);
        },
        (error: any) => {
          setErrorMessage(error.response?.data.Error || '');
          setLoading(false);
          setScrollToTop(false);
        }
      );
    }
  };

  const onProgress = (data: onProgressResponse) => {
    const result = loadingProgress?.find(x => data.fileName === x.fileName);
    let tempLoadingProcesses = loadingProgress;

    if (result !== undefined) {
      tempLoadingProcesses = tempLoadingProcesses?.map(x => {
        if (x.fileName === data.fileName) {
          return { ...x, percentage: data.percentage };
        } else {
          return x;
        }
      });
    } else {
      tempLoadingProcesses?.push(data);
    }
    setLoadingProgress(tempLoadingProcesses);
  };

  const calculatePercentage = () => {
    let total = 0;
    loadingProgress.forEach(x => {
      total += x.percentage / loadingProgress.length;
    });
    setPercentage(Math.round(total));
  };

  const handleClose = () => {
    if (isDirty) {
      setShowSaveModal(true);
      return;
    }

    navigate(-1);
  };

  const onSaveChangesSubmit = () => {
    setShowSaveModal(false);
    navigate(-1);
  };

  return (
    <FormPageComponent
      hasError={!!errorMessage}
      title="Edit Product"
      buttonTitle="Submit"
      buttonDisabled={!isValid || loading}
      buttonLoading={loading}
      close={handleClose}
      submit={handleSubmit(submit)}
      scrollToTop={scrollToTop}
    >
      {loading
        ? <LinearProgress variant="determinate" color="secondary" value={percentage} sx={{ zIndex: 2 }} />
        : <></>
      }
      <Fragment>
        <Grid item sx={{ flexGrow: 1 }}>
          {!!errorMessage
            && <Alert severity="error">{errorMessage}</Alert>
          }
        </Grid>

        <ModalCardComponent>
          <SectionHeader title="Product details" />
          <Fragment>
            <Grid item container columnSpacing={4} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <FormControlImage
                  accept=".jpg,.jpeg,.png"
                  data-testid="add-product-packshot"
                  control={control}
                  initialValue={product.ThumbnailUrl || product.ImageUrl}
                  initialFileType='image'
                  field={{
                    label: 'Packshot',
                    name: 'packshot',
                    inputType: 'logo',
                    validation: {
                      required: REQUIRED_VALIDATION,
                      validate: FILE_VALIDATION_WITH_TYPE(50, '.jpg,.jpeg,.png')
                    }
                  }}
                  title="Packshot"
                  subtitle="Upload the product packshot."
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <FormControlInput
                  data-testid="add-product-title"
                  control={control}
                  field={{
                    label: 'Title',
                    name: 'title',
                    inputType: 'text',
                    validation: {
                      required: REQUIRED_VALIDATION,
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <Autocomplete
                  data-testid="edit-product-brand"
                  {...control.register('brand')}
                  value={getValues('brand') || ''}
                  onChange={(e, newValue) => {
                    if (typeof newValue === 'string') {
                      setValue('brand', newValue, { shouldDirty: true, shouldValidate: true });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue('brand', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                    } else {
                      setValue('brand', newValue || '', { shouldDirty: true, shouldValidate: true });
                    }
                  }}
                  id="brands-filled"
                  options={brands.map((option) => option.name)}
                  freeSolo
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title || '';
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="brand"
                      variant="filled"
                      label="Brand"
                      placeholder="Brand"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <Autocomplete
                  data-testid="edit-product-product"
                  {...control.register('product')}
                  value={getValues('product') || ''}
                  onChange={(e, newValue) => {
                    if (typeof newValue === 'string') {
                      setValue('product', newValue, { shouldDirty: true, shouldValidate: true });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue('product', newValue.inputValue, { shouldDirty: true, shouldValidate: true });
                    } else {
                      setValue('product', newValue || '', { shouldDirty: true, shouldValidate: true });
                    }
                  }}
                  id="products-filled"
                  options={products.map((option) => option.name)}
                  freeSolo
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title || '';
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="product"
                      variant="filled"
                      label="Product"
                      placeholder="Product"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <RichTextEditor
                  {...control.register('body', { required: REQUIRED_VALIDATION })}
                  value={html}
                  toolbarConfig={TOOLBAR_CONFIG}
                  placeholder="Body"
                  onChange={(val: EditorValue) => {
                    setHtml(val);
                    setValue('body', val.toString('html'), { shouldDirty: true, shouldValidate: true });
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ margin: '16px 0 0' }}>
                <AssetStatusButtonGroup
                  subtitle="Published products are visible to customers and unpublished products are hidden."
                  title="Product Status"
                  value={getValues('published')}
                  data-testid="add-product-published"
                  onChange={(value: boolean) => setValue('published', value, { shouldDirty: true, shouldValidate: true })}
                  options={buttonGroupOptions}
                />
              </Grid>
            </Grid>
          </Fragment>
        </ModalCardComponent>
        {loading ? (
          <div style={styles.overlay}>
          </div>
        ) : (
          <></>
        )}
      </Fragment>

      <SaveChanges
        visible={showSaveModal}
        onCancel={() => setShowSaveModal(false)}
        onSubmit={onSaveChangesSubmit} />
    </FormPageComponent>
  );
}

const styles: IStyles = {
  overlay: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  }
};
